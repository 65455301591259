import { ConfirmLoginOtpCodeResponse } from '../types/ConfirmLoginOtpCodeResponse'
import { SuccessfullySignedInResponse } from '../types/SuccessfullySignedInResponse'
import { VerifyOtpCodeResponse } from '../types/VerifyOtpCodeResponse'
import ApiService from './apiService'
import { AxiosResponse } from 'axios'

export interface WebAppAuthenticationApiInterface {
    sendOtpCode(phoneNumber: string): Promise<AxiosResponse<void>>
    verifyOtpCode(
        phoneNumber: string,
        otpCode: string,
        userID?: string,
    ): Promise<AxiosResponse<VerifyOtpCodeResponse>>
    signup(
        smsCode: string,
        phoneNumber: string,
        firstName?: string,
        lastName?: string,
        email?: string,
        username?: string,
        inviteCode?: string,
    ): Promise<AxiosResponse<SuccessfullySignedInResponse>>
    login(
        phoneNumber: string,
        otpCode: string,
        userID: string,
    ): Promise<AxiosResponse<ConfirmLoginOtpCodeResponse>>
}

export const WebAppAuthenticationApi: WebAppAuthenticationApiInterface = Object.freeze({
    sendOtpCode(phoneNumber: string): Promise<AxiosResponse<void>> {
        return ApiService.post('/authentication/web-app/send-otp-code', {
            phone_number: phoneNumber,
        })
    },
    verifyOtpCode(
        phoneNumber: string,
        otpCode: string,
        userID: string,
    ): Promise<AxiosResponse<VerifyOtpCodeResponse>> {
        return ApiService.post('/authentication/web-app/verify-otp-code', {
            phone_number: phoneNumber,
            otp_code: otpCode,
            user_id: userID,
        })
    },
    signup(
        smsCode: string,
        phoneNumber: string,
        firstName?: string,
        lastName?: string,
        email?: string,
        username?: string,
        inviteCode?: string,
    ): Promise<AxiosResponse<SuccessfullySignedInResponse>> {
        return ApiService.post('/authentication/web-app/signup', {
            sms_code: smsCode,
            phone_number: phoneNumber,
            first_name: firstName,
            last_name: lastName,
            email: email,
            username: username,
            invite_code: inviteCode,
        })
    },
    login(
        phoneNumber: string,
        otpCode: string,
        userID: string,
    ): Promise<AxiosResponse<ConfirmLoginOtpCodeResponse>> {
        return ApiService.post('/authentication/web-app/login', {
            otp_code: otpCode,
            phone_number: phoneNumber,
            user_id: userID,
        })
    },
})
