import { User } from '../../types/User'
import { UserAction, SET_USER, SET_LOADING } from './types'
import { Dispatch } from 'react'

export const setUserAction = (user: User | null, dispatch: Dispatch<UserAction>): void => {
    dispatch({
        type: SET_USER,
        payload: { user },
    })
}

export const setLoadingAction = (loading: boolean, dispatch: Dispatch<UserAction>): void => {
    dispatch({
        type: SET_LOADING,
        payload: { loading },
    })
}
