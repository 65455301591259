interface RevertConfirmModalProps {
    open: boolean
    onToggle: () => void
    onRevert: () => void
}

const RevertConfirmModal = ({ open, onToggle, onRevert }: RevertConfirmModalProps) => {
    return (
        <div
            className={`fixed top-0 left-0 w-full h-full bg-[#000000c7] flex justify-center items-center z-20 ${
                open ? 'fixed' : 'hidden'
            }`}
        >
            <div
                className="relative mx-4 w-full sm:w-[400px] bg-white p-6 rounded-2xl flex flex-col"
                style={{ boxShadow: '0px 4px 4px 0px #00000040' }}
            >
                <h2 className="text-[#101828] text-lg font-semibold">
                    Are you sure you want to revert audio?
                </h2>
                <p className="text-[#475467] text-sm my-3">
                    This will discard the editing you've done so far and revert the original audio.
                </p>
                <div className="flex items-center justify-center gap-3 mt-5">
                    <button className="flex-1 py-2 text-[#101828] font-semibold" onClick={onToggle}>
                        Cancel
                    </button>
                    <button
                        className="flex-1 py-2 bg-[#D92D20] rounded-xl text-white font-semibold"
                        onClick={onRevert}
                    >
                        Yes, revert
                    </button>
                </div>
            </div>
        </div>
    )
}

export default RevertConfirmModal
