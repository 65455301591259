import React from 'react'

const WaitingOnResponse: React.FC = () => {
    return (
        <div className="flex flex-row gap-4 w-full justify-start">
            <img className="w-9 h-9 rounded-full" src="/toneai-logo.png" alt="tone.ai" />
            <div className="animate-spin rounded-full h-9 w-9 border-t-4 border-b-4 border-blue-500" />
        </div>
    )
}

export default WaitingOnResponse
