import QRCode from 'qrcode.react'

interface ShareStoryboardQrModalProps {
    url: string
    onClose: () => void
}

const ShareStoryboardQrModal = ({ onClose, url }: ShareStoryboardQrModalProps): JSX.Element => {
    return (
        <div className="fixed z-10 top-0 left-0 w-full h-full bg-[#00000080] flex flex-col justify-center items-center">
            <div className="bg-white w-full max-w-[90%] sm:max-w-[80%] md:max-w-[620px] rounded-[30px] flex flex-col items-center">
                <button className="flex justify-end pt-2 pr-2 w-full" onClick={onClose}>
                    <img src="/asset/close-modal-icon.svg" alt="Close" />
                </button>
                <div className=" px-[110px] mt-[50px] mb-[75px] flex flex-col items-center">
                    <QRCode
                        value={url}
                        size={300}
                        style={{
                            padding: '40px',
                        }}
                    />
                    <div className="flex flex-col">
                        <button
                            className="text-[#007AFF] text-base mt-3 text-center"
                            onClick={() => {
                                const canvas = document.querySelector('canvas')
                                const pngUrl = canvas
                                    ?.toDataURL('image/png')
                                    .replace('image/png', 'image/octet-stream')
                                let downloadLink = document.createElement('a')
                                downloadLink.href = pngUrl ?? ''
                                downloadLink.download = 'Storyboard-QR.png'
                                document.body.appendChild(downloadLink)
                                downloadLink.click()
                                document.body.removeChild(downloadLink)
                            }}
                        >
                            Download QR code
                        </button>
                        <a
                            className="text-[#007AFF] text-base text-center"
                            target="_blank"
                            href={url}
                        >
                            Open link
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShareStoryboardQrModal
