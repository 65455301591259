import { ExportVideoBroadcastingModeData } from '../types/ExportVideoBroadcastingModeData'
import { ExportVideoData } from '../types/ExportVideoData'
import ApiService from './apiService'
import { AxiosResponse } from 'axios'

export interface Reels {
    exportAudio(clipIds: string[]): Promise<AxiosResponse<{ url: string }>>
    exportVideo(data: ExportVideoData): Promise<AxiosResponse<{ url: string }>>
    exportVideoBroadcastingMode(
        data: ExportVideoBroadcastingModeData,
    ): Promise<AxiosResponse<{ url: string }>>
}

export const ReelsApi: Reels = Object.freeze({
    exportAudio(clipIds: string[]): Promise<AxiosResponse<{ url: string }>> {
        return ApiService.post('/reels/export-audio', {
            clip_ids: clipIds,
        })
    },
    exportVideo(data: ExportVideoData): Promise<AxiosResponse<{ url: string }>> {
        let mode = 'background_color'
        let file = undefined
        if (data.backgroundImage) {
            mode = 'background_image'
            file = data.backgroundImage
        } else if (data.backgroundVideo) {
            mode = 'background_video'
            file = data.backgroundVideo
        }
        return ApiService.post(
            '/reels/export-video',
            {
                clip_ids: data.clipIds,
                text_color: data.textColor,
                text_background_color: data.textBackgroundColor,
                file,
                mode,
            },
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
            2000000,
        )
    },
    exportVideoBroadcastingMode(
        data: ExportVideoBroadcastingModeData,
    ): Promise<AxiosResponse<{ url: string }>> {
        return ApiService.post('/reels/export-video-broadcasting-mode', {
            clip_ids: data.clipIds,
            text_color: data.textColor,
            background_color: data.backgroundColor,
            qr_code_url: data.qrCodeUrl,
        })
    },
})
