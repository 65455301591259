import { CampaignDetails } from '../types/Campaign'
import {
    ListOfCampaignDataSourcesResponse,
    SelectedCampaignDataSources,
    UpdatedCampaignDataSourcesRequestPayload,
} from '../types/CampaignDataSources'
import { CampaignToneMapData } from '../types/CampaignToneMapData'
import { CreatedCampaignsResponse } from '../types/CreatedCampaignsResponse'
import ApiService from './apiService'
import { AxiosResponse } from 'axios'

export interface CampaignApi {
    getCreatedCampaigns(): Promise<AxiosResponse<CreatedCampaignsResponse>>
    getCampaign(id: string): Promise<AxiosResponse<CampaignDetails>>
    getAvailableCampaignDataSourcesForCampaignId(
        id: string,
        page: number,
        perPage: number,
    ): Promise<AxiosResponse<ListOfCampaignDataSourcesResponse>>
    getAllCampaignDataSourcesForCampaignIdAndCampaignType(
        id: string,
        type: string,
        page: number,
        perPage: number,
    ): Promise<AxiosResponse<ListOfCampaignDataSourcesResponse>>
    getSelectedCampaignDataSources(id: string): Promise<AxiosResponse<SelectedCampaignDataSources>>
    saveCampaignTitle(id: string, title: string): Promise<AxiosResponse<CampaignDetails>>
    saveCampaign(title: string): Promise<AxiosResponse<CampaignDetails>>
    addDataSourcesToCampaign(
        id: string,
        payload: UpdatedCampaignDataSourcesRequestPayload,
    ): Promise<AxiosResponse<void>>
    updateDataSourcesForCampaign(
        id: string,
        payload: UpdatedCampaignDataSourcesRequestPayload,
    ): Promise<AxiosResponse<void>>
    getToneMapDataById(id: string): Promise<AxiosResponse<CampaignToneMapData[]>>
    deleteCampaignDataSourcesByType(id: string, type: string): Promise<AxiosResponse<void>>
}

export const CampaignApi: CampaignApi = Object.freeze({
    getCreatedCampaigns(page: number = 1): Promise<AxiosResponse<CreatedCampaignsResponse>> {
        return ApiService.get(`/campaigns`, null, { page, per_page: 10 })
    },
    getCampaign(id: string): Promise<AxiosResponse<CampaignDetails>> {
        return ApiService.get(`/campaigns/${id}`)
    },
    getAvailableCampaignDataSourcesForCampaignId(
        id: string,
        page: number,
        perPage: number,
    ): Promise<AxiosResponse<ListOfCampaignDataSourcesResponse>> {
        return ApiService.get(`/campaigns/${id}/data-sources?page=${page}&per_page=${perPage}`)
        //return ApiService.get(`/campaigns/data-sources?page=${page}&per_page=${perPage}`)
    },
    getAllCampaignDataSourcesForCampaignIdAndCampaignType(
        id: string,
        type: string,
        page: number,
        perPage: number,
    ): Promise<AxiosResponse<ListOfCampaignDataSourcesResponse>> {
        return ApiService.get(
            `/campaigns/${id}/${type}/data-sources?page=${page}&per_page=${perPage}`,
        )
    },
    getSelectedCampaignDataSources(
        id: string,
    ): Promise<AxiosResponse<SelectedCampaignDataSources>> {
        return ApiService.get(`/campaigns/${id}/selected-data-sources`)
    },
    saveCampaignTitle(id: string, title: string): Promise<AxiosResponse<CampaignDetails>> {
        return ApiService.patch(`/campaigns/${id}`, { title: title })
    },
    saveCampaign(title: string): Promise<AxiosResponse<CampaignDetails>> {
        return ApiService.post(`/campaigns`, { title: title })
    },
    addDataSourcesToCampaign(
        id: string,
        payload: UpdatedCampaignDataSourcesRequestPayload,
    ): Promise<AxiosResponse<void>> {
        return ApiService.post(`/campaigns/add-campaign-data-sources/${id}`, payload)
    },
    updateDataSourcesForCampaign(
        id: string,
        payload: UpdatedCampaignDataSourcesRequestPayload,
    ): Promise<AxiosResponse<void>> {
        return ApiService.post(`/campaigns/update-campaign-data-sources/${id}`, payload)
    },
    getToneMapDataById(id: string): Promise<AxiosResponse<CampaignToneMapData[]>> {
        return ApiService.get(`/campaigns/${id}/tone-map`)
    },
    deleteCampaignDataSourcesByType(id: string, type: string): Promise<AxiosResponse<void>> {
        return ApiService.delete(`/campaigns/data-sources/${id}/${type}`)
    },
})
