import { InterviewApi } from '../api/interviews'
import { Response } from '../pages/InterviewResponder'
import { ConversationMember } from '../types/ConversationMember'
import { Interview } from '../types/Interview'
import Player from './Player'
import axios from 'axios'
import { Modal } from 'flowbite-react'
import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'

interface InterviewResponseUploadModalProps {
    show: boolean
    interview: Interview | null
    onClose: () => void
    onFileUpload: () => void
}

const InterviewResponseUploadModal = ({
    show,
    interview,
    onClose,
    onFileUpload,
}: InterviewResponseUploadModalProps): JSX.Element => {
    const [files, setFiles] = useState<File[] | null>(null)
    const [response, setResponse] = useState<Response | null>()
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [conversationTitle, setConversationTitle] = useState<string>('')
    const [conversationMembers, setConversationMembers] = useState<ConversationMember[]>([
        { firstName: '', lastName: '', email: '' },
    ])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                setFiles(acceptedFiles)
                const formData = new FormData()
                formData.append('file', acceptedFiles[0])
                setResponse({
                    formData,
                    fileSize: 0,
                    fileType: 'audio/mp3',
                } as any)
            }
        },
        multiple: false,
        accept: {
            'audio/*': [],
            'video/*': [],
        },
    })

    const removeConversationMemberHandler = (index: number) => {
        const updatedConversationMembers = conversationMembers.filter((_, i) => i !== index)
        if (updatedConversationMembers.length === 0) {
            setConversationMembers([
                {
                    firstName: '',
                    lastName: '',
                    email: '',
                },
            ])
        } else {
            setConversationMembers(updatedConversationMembers)
        }
    }

    const addConversationMemberHandler = () => {
        setConversationMembers([
            ...conversationMembers,
            {
                firstName: '',
                lastName: '',
                email: '',
            },
        ])
    }

    const uploadFile = async (file: File, signedUrl: string): Promise<void> => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader()

                reader.onloadend = async () => {
                    try {
                        const buffer = reader.result
                        await axios.put(signedUrl, buffer, {
                            headers: {},
                        })
                        resolve() // Resolve the promise here
                    } catch (error) {
                        reject(error) // Reject the promise in case of an error
                    }
                }

                reader.onerror = (e) => {
                    reject(e) // Reject the promise in case of an error
                }

                reader.readAsArrayBuffer(file)
            } catch (e) {
                reject(e) // Reject the promise in case of an error
            }
        })
    }

    const onSubmit = async () => {
        try {
            setIsSubmitting(true)
            const generatePreSignedUrlRequest = {
                conversation_title: conversationTitle,
                speakers: conversationMembers.map((conversationMember) => ({
                    first_name: conversationMember.firstName,
                    last_name: conversationMember.lastName,
                    email: conversationMember.email,
                })),
            }
            const preSignedUrlResponse = await InterviewApi.generatePreSignedUrl(
                interview?.slug ?? '',
                generatePreSignedUrlRequest,
            )
            const url = preSignedUrlResponse.data.url
            const file: File = response?.formData?.get('file') as File
            await uploadFile(file, url)
            onFileUpload()
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <Modal show={show} size="5xl" id="modal" className="h-full">
            {isSubmitting && (
                <div className="absolute left-1/2 top-6">
                    <svg
                        aria-hidden="true"
                        className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                        />
                        <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                        />
                    </svg>
                </div>
            )}
            <button
                disabled={isSubmitting}
                className="flex justify-end pt-2 pr-2 w-full"
                onClick={() => {
                    setFiles(null)
                    setResponse(null)
                    setConversationMembers([{ firstName: '', lastName: '', email: '' }])
                    setConversationTitle('')
                    onClose()
                }}
            >
                <img src="/asset/close-modal-icon.svg" alt="Close" />
            </button>
            {!files && (
                <div className="p-6 text-center">
                    {/* Dropzone for uploading files */}
                    <div
                        {...getRootProps()}
                        className="dropzone text-center p-6 border-2 border-dashed border-gray-300 bg-gray-50 cursor-pointer bg-[#b6d6fa] h-[100px] sm:h-[300px] flex items-center justify-center"
                    >
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <p>Drop the files here ...</p>
                        ) : (
                            <p>Drag and drop an audio or video file or click to select one.</p>
                        )}
                    </div>
                </div>
            )}
            {files && files.length > 0 && (
                <div className="mt-10 px-10 w-full">
                    <Player
                        url={URL.createObjectURL(response?.formData?.get('file') as Blob)}
                        autoPlay={false}
                    />
                </div>
            )}
            {/* Additional information */}
            {files && files.length > 0 && (
                <div className="mx-10">
                    <div>
                        <h5 className="text-[#001F40] text-sm font-lato mb-4 mt-20">
                            Conversation Title
                        </h5>
                        <input
                            type="text"
                            placeholder="Conversation Title"
                            value={conversationTitle}
                            onChange={(e) => setConversationTitle(e.target.value)}
                        />
                    </div>
                    <div>
                        <h5 className="text-[#001F40] text-sm font-lato mb-4 mt-10">
                            Conversation Members
                        </h5>
                        <div className="flex flex-col gap-4">
                            {conversationMembers.map((member, index) => {
                                return (
                                    <div className="flex flex-row gap-4" key={index}>
                                        <input
                                            type="text"
                                            placeholder="First Name"
                                            className="bg-[#D9D9D940] text-[#000000] text-sm font-lato h-7 w-full border-none leading-[17px] px-2 rounded-lg"
                                            value={member.firstName}
                                            onChange={(e) => {
                                                setConversationMembers(
                                                    conversationMembers.map((s, i) => {
                                                        if (i === index) {
                                                            return {
                                                                ...s,
                                                                firstName: e.target.value,
                                                            }
                                                        }
                                                        return s
                                                    }),
                                                )
                                            }}
                                        />
                                        <input
                                            type="text"
                                            placeholder="Last Name"
                                            className="bg-[#D9D9D940] text-[#000000] text-sm font-lato h-7 w-full border-none leading-[17px] px-2 rounded-lg"
                                            value={member.lastName}
                                            onChange={(e) => {
                                                setConversationMembers(
                                                    conversationMembers.map((s, i) => {
                                                        if (i === index) {
                                                            return {
                                                                ...s,
                                                                lastName: e.target.value,
                                                            }
                                                        }
                                                        return s
                                                    }),
                                                )
                                            }}
                                        />
                                        <input
                                            type="text"
                                            placeholder="Email"
                                            className="bg-[#D9D9D940] text-[#000000] text-sm font-lato h-7 w-full border-none leading-[17px] px-2 rounded-lg"
                                            value={member.email}
                                            onChange={(e) => {
                                                setConversationMembers(
                                                    conversationMembers.map((s, i) => {
                                                        if (i === index) {
                                                            return {
                                                                ...s,
                                                                email: e.target.value,
                                                            }
                                                        }
                                                        return s
                                                    }),
                                                )
                                            }}
                                        />
                                        <button
                                            className="p-1"
                                            onClick={() => removeConversationMemberHandler(index)}
                                        >
                                            <div className="flex flex-row gap-1.5 w-6 h-6">
                                                <img src="/asset/trash_bin.svg" alt="trash_icon" />
                                            </div>
                                        </button>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="mt-4 flex items-center flex-row gap-4">
                            <button className="p-1" onClick={addConversationMemberHandler}>
                                <svg
                                    width="25"
                                    height="25"
                                    viewBox="0 0 25 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M12.5 2.34375C6.8999 2.34375 2.34375 6.8999 2.34375 12.5C2.34375 18.1001 6.8999 22.6563 12.5 22.6563C18.1001 22.6563 22.6563 18.1001 22.6563 12.5C22.6563 6.8999 18.1001 2.34375 12.5 2.34375ZM16.4063 13.2813H13.2813V16.4063C13.2813 16.6135 13.1989 16.8122 13.0524 16.9587C12.9059 17.1052 12.7072 17.1875 12.5 17.1875C12.2928 17.1875 12.0941 17.1052 11.9476 16.9587C11.8011 16.8122 11.7188 16.6135 11.7188 16.4063V13.2813H8.59375C8.38655 13.2813 8.18784 13.1989 8.04132 13.0524C7.89481 12.9059 7.8125 12.7072 7.8125 12.5C7.8125 12.2928 7.89481 12.0941 8.04132 11.9476C8.18784 11.8011 8.38655 11.7188 8.59375 11.7188H11.7188V8.59375C11.7188 8.38655 11.8011 8.18784 11.9476 8.04132C12.0941 7.89481 12.2928 7.8125 12.5 7.8125C12.7072 7.8125 12.9059 7.89481 13.0524 8.04132C13.1989 8.18784 13.2813 8.38655 13.2813 8.59375V11.7188H16.4063C16.6135 11.7188 16.8122 11.8011 16.9587 11.9476C17.1052 12.0941 17.1875 12.2928 17.1875 12.5C17.1875 12.7072 17.1052 12.9059 16.9587 13.0524C16.8122 13.1989 16.6135 13.2813 16.4063 13.2813Z"
                                        fill="#99A4B1"
                                    />
                                </svg>
                            </button>
                            <p>Add conversation member</p>
                        </div>
                        <div className="my-8 flex justify-end">
                            <button
                                disabled={!files || !files.length || isSubmitting}
                                onClick={onSubmit}
                                className={`text-[#BFDEFF] ${
                                    isSubmitting ? 'bg-[#add4ff] text-[#AFAFAF]' : 'bg-[#007AFF]'
                                } px-6 py-1 rounded-[10px]`}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    )
}

export default InterviewResponseUploadModal
