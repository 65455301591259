import { NodeioApi } from '../../api/nodeio'
import { useUserStore } from '../../context/user/store'
import { Page } from '../../enums/Page'
import { Nodeio } from '../../types/Nodeio'
import { reloadAudio } from '../../utils/audio'
import {
    getFormattedTranscriptionText,
    getNoTranscriptionText,
} from '../../utils/formatTranscriptionText'
import { getLanguageName } from '../../utils/getLanguageName'
import IconSpinner from '../IconSpinner'
import EditMVPCardModal from './Modals/EditMVPCardModal'
import saveAs from 'file-saver'
import { Dropdown, Tooltip } from 'flowbite-react'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'
import { generatePath, useNavigate } from 'react-router-dom'

interface ResponseMVPCardProps {
    mvp: Nodeio
    editable?: boolean
    onDelete?: (id: string) => void
    mvpsByAI?: string
    slug?: string
}

const ResponseMVPCard = ({
    mvp,
    editable = false,
    onDelete,
    mvpsByAI,
    slug,
}: ResponseMVPCardProps) => {
    const {
        state: { user },
    } = useUserStore()
    const [nodeio, setNodeio] = useState<Nodeio>(mvp)
    const [formattedTranscriptionText, setFormattedTranscriptionText] = useState<string | null>('')
    const [noTranscriptionText, setNoTranscriptionText] = useState<string | null>('')
    const [originalLanguage, setOriginalLanguage] = useState<string | null>('Responded in English')
    const player = useRef<any>()
    const navigate = useNavigate()

    const [loading, setLoading] = useState<boolean>(false)

    const [more, setMore] = useState<boolean>(false)
    const toggleMore = () => setMore((_) => !_)

    const [openEdit, setOpenEdit] = useState<boolean>(false)
    const toggleOpenEdit = () => setOpenEdit((_) => !_)

    const updateMVPCardTranscriptionAndLanguage = (mvp: Nodeio) => {
        if (mvp?.media?.transcription_json) {
            setFormattedTranscriptionText(
                getFormattedTranscriptionText(mvp.media.transcription_json),
            )
        } else if (mvp?.settings?.translated_title || mvp?.title) {
            setFormattedTranscriptionText(mvp.settings?.translated_title || mvp?.title)
        }

        if (mvp?.created_at) {
            setNoTranscriptionText(getNoTranscriptionText(mvp.created_at))
        }

        if (mvp?.media?.transcription_language) {
            setOriginalLanguage('Responded in ' + getLanguageName(mvp.media.transcription_language))
        } else if (mvp?.settings?.original_language) {
            setOriginalLanguage('Responded in ' + getLanguageName(mvp.settings.original_language))
        }
    }

    useEffect(() => {
        setNodeio(mvp)
    }, [mvp])

    useEffect(() => {
        updateMVPCardTranscriptionAndLanguage(nodeio)
    }, [nodeio])

    const fullName = useMemo(() => {
        let temp = nodeio?.author?.first_name + '-' + nodeio?.author?.last_name
        if (temp === '-') temp = 'Guest'
        return temp
    }, [nodeio?.author])

    const downloadAudio = async () => {
        try {
            var au = document.createElement('audio')
            au.src = nodeio.media?.url as string
            au.addEventListener(
                'loadedmetadata',
                async function () {
                    setLoading(true)
                    const res = await NodeioApi.downloadAudio(nodeio.id as string, true)
                    const mimeType = res.headers['content-type']
                    if (res.status === 200) {
                        var blob = new Blob([res.data])
                        saveAs(
                            blob,
                            `${fullName}-${moment(nodeio.media?.created_at).format('MMDDYYYY')}.${
                                mimeType?.split('/')[1]
                            }`,
                        )
                    }
                    setLoading(false)
                },
                false,
            )
        } finally {
            setLoading(false)
        }
    }

    if (!nodeio) return <></>

    return (
        <div className="flex h-min">
            {/* <div className='m-4 flex flex-col p-4 rounded-lg bg-[#1F2C3B] max-w-[640px] w-full'> */}
            <div className="m-4 flex flex-col p-4 rounded-lg bg-[#1F2C3B] w-full">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-1.5">
                        <p className="text-white text-xs">
                            {!nodeio?.author?.first_name && !nodeio?.author?.last_name
                                ? 'Anonymous'
                                : `${nodeio?.author?.first_name} ${nodeio?.author?.last_name}`}
                        </p>
                        {user?.allow_editing_responder_info && editable && (
                            <button onClick={toggleOpenEdit}>
                                <img src="/asset/pencil.svg" className="w-[20px] h-[20px]" />
                            </button>
                        )}
                    </div>
                    {editable ? (
                        <div className="relative">
                            <Dropdown
                                theme={{
                                    floating: {
                                        base: '!w-[130px] !left-[unset] !right-[30px] !transform-none z-10 rounded-[10px] profile-top-right',
                                    },
                                }}
                                label=""
                                placement="bottom"
                                renderTrigger={() => (
                                    <button type="button">
                                        <img src="/asset/three-dot.svg" />
                                    </button>
                                )}
                            >
                                <Dropdown.Item
                                    theme={{
                                        base: 'flex items-center justify-start py-2 px-4 text-sm text-gray-700 cursor-pointer w-full hover:bg-transparent dark:text-gray-200 focus:outline-none dark:hover:text-white focus:bg-transparent dark:focus:text-white',
                                    }}
                                    onClick={() => onDelete && onDelete(nodeio.id)}
                                >
                                    <span className="block text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
                                        Delete
                                    </span>
                                </Dropdown.Item>
                                <Dropdown.Item
                                    theme={{
                                        base: 'flex items-center justify-start py-2 px-4 text-sm text-gray-700 cursor-pointer w-full hover:bg-transparent dark:text-gray-200 focus:outline-none dark:hover:text-white focus:bg-transparent dark:focus:text-white',
                                    }}
                                    onClick={() =>
                                        navigate(
                                            generatePath(Page.EDIT_AUDIO, {
                                                id: nodeio.id,
                                                username: nodeio?.author?.username ?? 'anonymous',
                                                slug: slug ?? 'anonymous',
                                            }),
                                        )
                                    }
                                >
                                    <span className="block text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
                                        Edit audio
                                    </span>
                                </Dropdown.Item>
                            </Dropdown>
                        </div>
                    ) : nodeio.media?.url ? (
                        <img src="/asset/microphone.svg" />
                    ) : (
                        <img src="/asset/message-text-circle.svg" />
                    )}
                </div>
                {editable && _.values(JSON.parse(mvpsByAI || '{}')).includes(mvp.id) && (
                    <div className="my-1 flex items-center gap-1 p-0.5 px-2 rounded-full bg-[rgba(191,_222,_255,_0.50)] w-fit">
                        ✨<p className="text-white text-xs font-bold">AI picked this Soundbite</p>
                    </div>
                )}
                {nodeio.impact_statement &&
                    nodeio.impact_statement.trim() !== '' &&
                    !nodeio.impact_statement.includes('Impact statement not available') && (
                        <div className="flex items-start gap-1 my-3">
                            <img src="/asset/quote.svg" />
                            <p className="text-base text-white">
                                {nodeio?.impact_statement?.replaceAll('"', '')}
                            </p>
                        </div>
                    )}
                {nodeio.media?.url && (
                    <div className="mt-3 mb-5 z-0">
                        <AudioPlayer
                            ref={player}
                            src={nodeio.media?.trimmed_url ?? nodeio.media?.url}
                            customProgressBarSection={[
                                RHAP_UI.MAIN_CONTROLS,
                                RHAP_UI.PROGRESS_BAR,
                                RHAP_UI.CURRENT_TIME,
                                <div>/</div>,
                                RHAP_UI.DURATION,
                                <button className="ml-3" onClick={downloadAudio} disabled={loading}>
                                    {loading ? (
                                        <div className="w-6 h-6">
                                            <IconSpinner />
                                        </div>
                                    ) : (
                                        <img src="/asset/download-icon.svg" />
                                    )}
                                </button>,
                            ]}
                            showJumpControls={false}
                            customVolumeControls={[]}
                            customAdditionalControls={[]}
                            customControlsSection={[]}
                            timeFormat="mm:ss"
                            className="customized-audio-player rounded-lg bg-[#EAECF0]"
                            loop={false}
                            autoPlay={false}
                            autoPlayAfterSrcChange={false}
                            onPlayError={() => {
                                if (nodeio.media?.url) {
                                    reloadAudio(nodeio, (data) => {
                                        setNodeio(data)
                                        player.current?.playAudioPromise()
                                    })
                                }
                            }}
                        />
                    </div>
                )}
                <div className="flex flex-row justify-between">
                    <button className="flex items-center gap-2 font-semibold" onClick={toggleMore}>
                        <p className="text-sm text-[#007AFF]">Read response</p>
                        <img src="/asset/up.svg" className={`${!more && 'rotate-180'}`} />
                    </button>
                    {((nodeio.media?.transcription_language &&
                        nodeio.media?.transcription_language.toLocaleLowerCase() !== 'en' &&
                        nodeio.media?.transcription_language.toLocaleLowerCase() !== 'english') ||
                        (nodeio.settings?.original_language &&
                            nodeio.settings?.original_language.toLocaleLowerCase() !== 'en' &&
                            nodeio.settings?.original_language.toLocaleLowerCase() !==
                                'english')) && (
                        <Tooltip content={originalLanguage} style="light">
                            <button
                                className="text-sm text-white bg-[#007AFF55] rounded-xl px-3 self-center "
                                disabled
                            >
                                {nodeio.media?.transcription_language ||
                                    nodeio.settings?.original_language}
                            </button>
                        </Tooltip>
                    )}
                </div>
                {more && (
                    <div className="max-h-96 overflow-y-auto">
                        <p className="whitespace-pre-line text-xs text-white mt-2">
                            {formattedTranscriptionText ??
                                nodeio.media?.translated_transcription_text ??
                                nodeio.media?.transcription_text ??
                                noTranscriptionText}
                        </p>
                    </div>
                )}
            </div>

            {openEdit && (
                <EditMVPCardModal
                    mvp={nodeio}
                    setNodio={setNodeio}
                    open={openEdit}
                    onToggle={toggleOpenEdit}
                    disableTranscriptionEdit={nodeio.media?.transcription_text ? false : true}
                />
            )}
        </div>
    )
}

export default ResponseMVPCard
