import { CampaignData } from '../types/Campaign'
import { pluralizationUtil } from '../utils/pluralizationUtil'
import moment from 'moment'
import { useEffect, useState } from 'react'

interface SurveyCardProps {
    surveyData: CampaignData
}

const SurveyCard = ({ surveyData }: SurveyCardProps): JSX.Element => {
    const [isCopied, setIsCopied] = useState<boolean>(false)
    useEffect(() => {
        if (isCopied) {
            const timeout = setTimeout(() => {
                setIsCopied(false)
            }, 2000)
            return () => clearTimeout(timeout)
        }
    }, [isCopied])

    // eslint-disable-next-line no-console
    //console.log('Survey Data: ', surveyData)

    return (
        <div className="p-2 rounded-lg flex flex-row justify-between items-center hover:bg-[#D9D9D930] gap-10">
            <div className="flex w-[80%] justify-start">
                <p className="text-[#001F40] text-base text-black ellipsis-1 break-all">
                    {surveyData?.title}
                </p>
            </div>
            <div className="flex flex-row w-[20%] gap-10 justify-end">
                <p className="text-[#8092A5] text-sm">
                    {surveyData?.number_of_responses}{' '}
                    {pluralizationUtil(
                        Number(surveyData?.number_of_responses),
                        'response',
                        'responses',
                    )}
                </p>
                <p className="text-[#8092A5] text-sm">
                    {moment(new Date()).diff(surveyData.created_at, 'hours') > 72
                        ? moment(surveyData.created_at).format('LL')
                        : moment(surveyData.created_at).fromNow()}
                </p>
                {/* <p className="text-[#8092A5] text-sm">Last Response: 1 day ago</p> */}
            </div>
        </div>
    )
}

export default SurveyCard
