import { useUserStore } from '../context/user/store'
import { RagQueryResponseSingleResult } from '../types/RagQueryResponse'
import Player from './Player'
import moment from 'moment'
import React, { useState } from 'react'

interface ContentItemProps {
    title: string
    author: string
    duration: string
    description: string
    timestamp: string
    url?: string
    campaignId?: string
    surveySlug?: string
    interviewSlug?: string
}

const ContentItem: React.FC<ContentItemProps> = ({
    title,
    author,
    // duration,
    description,
    timestamp,
    url,
    campaignId,
    surveySlug,
    interviewSlug,
}) => {
    const {
        state: { user },
    } = useUserStore()
    const [isExpanded, setIsExpanded] = useState(false)

    const toggleDescription = () => setIsExpanded(!isExpanded)

    const truncateDescription = (text: string, maxLength: number) =>
        text.length <= maxLength ? text : `${text.slice(0, maxLength)}...`

    return (
        <div className="bg-white rounded-lg shadow-md p-4 mb-4">
            <div className="flex justify-between items-center mb-2">
                <div className="flex items-center">
                    <h2 className="mr-2 text-[#001F40] font-['Lato'] text-base font-bold leading-normal">
                        {title}
                    </h2>
                    <span className="text-[#8092A5] font-['Lato'] text-sm font-bold leading-normal mr-2">
                        {timestamp}
                    </span>
                    <span className="text-[#8092A5] font-['Lato'] text-sm font-bold leading-normal mr-2">
                        {author}
                    </span>
                </div>
                {campaignId && (
                    <a
                        className="text-[#007AFF] font-['Lato'] text-sm font-bold leading-normal cursor-pointer"
                        href={`${window.location.origin}/${user.username}/campaign-landing/${campaignId}`}
                    >
                        View in Campaign &gt;
                    </a>
                )}
                {surveySlug && (
                    <a
                        className="text-[#007AFF] font-['Lato'] text-sm font-bold leading-normal cursor-pointer"
                        href={`${window.location.origin}/${user.username}/${surveySlug}/landing`}
                    >
                        View Survey &gt;
                    </a>
                )}
                {interviewSlug && (
                    <a
                        className="text-[#007AFF] font-['Lato'] text-sm font-bold leading-normal cursor-pointer"
                        href={`${window.location.origin}/${user.username}/${interviewSlug}/live-landing`}
                    >
                        View Conversation &gt;
                    </a>
                )}
            </div>
            <div className="flex items-center mb-2">
                {url && <Player key={url} autoplay={false} url={url} />}
            </div>
            <p className="text-sm text-gray-700">
                {isExpanded ? description : truncateDescription(description, 200)}
                {description.length > 200 && (
                    <span
                        className="text-[#007AFF] font-['Lato'] text-sm font-bold leading-normal cursor-pointer ml-1"
                        onClick={toggleDescription}
                    >
                        {isExpanded ? 'View less' : 'View more'}
                    </span>
                )}
            </p>
        </div>
    )
}

interface CategorySectionProps {
    title: string
    items: ContentItemProps[]
}

const CategorySection: React.FC<CategorySectionProps> = ({ title, items }) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const displayedItems = isExpanded ? items : items.slice(0, 2)

    return (
        <div className="mb-4 p-4">
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-[#8092A5] font-['Lato'] text-sm font-bold leading-normal uppercase">
                    {title === 'CLIP'
                        ? 'Clips'
                        : title === 'SURVEY_RESPONSE'
                        ? 'SURVEY RESPONSES'
                        : title === 'INTERVIEW_RESPONSE'
                        ? 'CONVERSATIONS'
                        : title === 'UPLOAD'
                        ? 'UPLOADS'
                        : 'UNCATEGORIZED'}{' '}
                    ({items.length})
                </h1>
                {items.length > 2 && (
                    <button
                        onClick={() => setIsExpanded(!isExpanded)}
                        className="text-[#007AFF] font-['Lato'] text-sm font-bold leading-normal cursor-pointer"
                    >
                        {isExpanded ? 'Show Less' : 'Show More'}
                    </button>
                )}
            </div>
            {displayedItems.map((item, index) => (
                <ContentItem key={index} {...item} />
            ))}
        </div>
    )
}

export interface SearchResultCardProps {
    results: RagQueryResponseSingleResult[]
}

const SearchResultCard: React.FC<SearchResultCardProps> = ({ results }) => {
    if (!results || results.length === 0) {
        return (
            <div className="max-w-2xl mx-auto text-center py-4">
                <p>No results found. Please try a different search query.</p>
            </div>
        )
    }

    const mapResultToContentItem = (result: RagQueryResponseSingleResult): ContentItemProps => {
        const title = result.metadata?.title || 'Untitled'
        const timestamp =
            moment(new Date()).diff(result.metadata?.created_at, 'hours') > 72
                ? `on ${moment(result.metadata?.created_at).format('LL')}`
                : moment(result.metadata?.created_at).fromNow()

        // eslint-disable-next-line no-console
        //console.log(result.metadata)

        return {
            title,
            author:
                result.metadata?.author?.length > 0 ? result.metadata?.author : 'Unknown Author',
            duration: 'N/A', // You might want to extract this from the result if available
            description: result.content || 'No description available',
            timestamp,
            url: result.metadata?.url,
            campaignId: result.metadata?.campaign_id,
            surveySlug: result.metadata?.survey_question?.slug,
            interviewSlug: result.metadata?.interview?.slug,
        }
    }

    const categories = results.reduce((acc, result) => {
        const category = result.metadata?.data_source?.toLowerCase() || 'uncategorized'

        if (!acc[category]) {
            acc[category] = []
        }
        acc[category].push(mapResultToContentItem(result))
        return acc
    }, {} as Record<string, ContentItemProps[]>)

    // Define the order of categories
    const categoryOrder = [
        'upload',
        'clip',
        'interview_response',
        'survey_response',
        'uncategorized',
    ]

    return (
        <div className="max-w-4xl mx-auto">
            {categoryOrder.map((category) => {
                const items = categories[category]
                if (items && items.length > 0) {
                    return (
                        <CategorySection
                            key={category}
                            title={category.toUpperCase()}
                            items={items}
                        />
                    )
                }
                return null
            })}
        </div>
    )
}

export default SearchResultCard
