import { User } from '../../types/User'

export const SET_USER = 'SET_USER'
export const SET_LOADING = 'SET_LOADING'

export type UserAction = SetUserAction | SetLoadingAction

export interface SetUserAction {
    type: typeof SET_USER
    payload: SetUserActionPayload
}

export interface SetLoadingAction {
    type: typeof SET_LOADING
    payload: {
        loading: boolean
    }
}

export interface SetUserActionPayload {
    user: User | null
}
