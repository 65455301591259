import { Response, ResponseNote } from '../pages/QuestionResponder'
import { Question } from '../types/Question'
import { QuestionTypeSettings } from '../types/QuestionType'
import { useTranslation } from 'react-i18next'
import TextareaAutosize from 'react-textarea-autosize'

interface FreeTextResponderProps {
    setResponses?: (value: Response[] | ((prevVar: Response[]) => Response[])) => void
    setRequiredToAllResponds?: (v: boolean | ((prevVar: boolean) => boolean)) => void
    setQuestionIndex?: (index: number | ((prevVar: number) => number)) => void
    setIsRecordingSupported?: (v: boolean | ((prevVar: boolean) => boolean)) => void
    setResponseNotes?: (
        responseNotes: ResponseNote[] | ((prevVar: ResponseNote[]) => ResponseNote[]),
    ) => void
    selectedQuestion?: Question | null
    questionIndex?: number
    responseNoteForSelectedQuestion?: ResponseNote | undefined
    questions?: Question[]
    disabled?: boolean
    showRecordInstead?: boolean
    isDarkModeEnabled?: boolean
}

const FreeTextResponder = ({
    setResponseNotes,
    selectedQuestion,
    responseNoteForSelectedQuestion,
    disabled,
    showRecordInstead = true,
    isDarkModeEnabled = false,
}: FreeTextResponderProps) => {
    const { t } = useTranslation()
    return (
        <div className="w-full mt-9">
            <TextareaAutosize
                key={selectedQuestion?.id}
                className="flex-1 rounded-lg disabled:bg-white text-[#001F40] focus:outline-none focus:border-[var(--gray-300,_#D0D5DD)] placeholder-[#8E8D93] text-md resize-none mb-2 leading-[1.2] w-full border-[var(--gray-300,_#D0D5DD)] border-[1px] shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] p-3 min-h-[200px]"
                placeholder={t('question_responder_page.type_your_answer_here')}
                value={responseNoteForSelectedQuestion?.title}
                disabled={disabled}
                onChange={(e) => {
                    setResponseNotes &&
                        setResponseNotes((responseNotes) => {
                            let resNotes = [...responseNotes]
                            resNotes.splice(
                                responseNotes.findIndex(
                                    (responseNote) =>
                                        responseNote.questionId === selectedQuestion?.id,
                                ),
                                1,
                                {
                                    ...(responseNoteForSelectedQuestion as ResponseNote),
                                    title: e.target.value,
                                },
                            )
                            return resNotes
                        })
                }}
            />
            {selectedQuestion?.question_settings.type ===
                QuestionTypeSettings.VIDEO_OR_AUDIO_OR_TEXT && (
                <button
                    className="flex items-center gap-2 mt-1.5"
                    onClick={() =>
                        setResponseNotes &&
                        setResponseNotes((responseNotes) => {
                            let resNotes = [...responseNotes]
                            resNotes.splice(
                                responseNotes.findIndex(
                                    (responseNote) =>
                                        responseNote.questionId === selectedQuestion?.id,
                                ),
                                1,
                                {
                                    ...(responseNoteForSelectedQuestion as ResponseNote),
                                    selectedQuestionType: undefined,
                                    title: '',
                                },
                            )
                            return resNotes
                        })
                    }
                >
                    <div className="flex flex-row ml-4 mt-4 items-center gap-2">
                        <div className="p-[9px] bg-[#D1E9FF] rounded-full">
                            <div className="p-[3px] bg-[#007AFF] rounded-full">
                                <img
                                    src="/asset/message-text-circle.svg"
                                    className="w-[20px] h-[20px]"
                                />
                            </div>
                        </div>
                        <p
                            className={`${
                                isDarkModeEnabled ? 'text-[#FFFFFF]' : 'text-[#001F40]'
                            } text-xs`}
                        >
                            {t('question_responder_page.select_how_you_want_to_answer')}
                        </p>
                    </div>
                </button>
            )}
            {selectedQuestion?.question_settings.type === QuestionTypeSettings.VOICE_OR_TEXT && (
                <button
                    className="flex items-center gap-2"
                    onClick={() =>
                        setResponseNotes &&
                        setResponseNotes((responseNotes) => {
                            let resNotes = [...responseNotes]
                            resNotes.splice(
                                responseNotes.findIndex(
                                    (responseNote) =>
                                        responseNote.questionId === selectedQuestion?.id,
                                ),
                                1,
                                {
                                    ...(responseNoteForSelectedQuestion as ResponseNote),
                                    selectedQuestionType: QuestionTypeSettings.VOICE_ONLY,
                                    title: undefined,
                                },
                            )
                            return resNotes
                        })
                    }
                >
                    {showRecordInstead && (
                        <div className="">
                            <div className="p-[9px] bg-[#D1E9FF] rounded-full">
                                <div className="p-[3px] bg-[#007AFF] rounded-full">
                                    <img
                                        src="/asset/microphone.svg"
                                        className="w-[20px] h-[20px]"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {showRecordInstead && (
                        <p
                            className={`${
                                isDarkModeEnabled ? 'text-[#FFFFFF]' : 'text-[#001F40]'
                            } text-xs`}
                        >
                            {t('question_responder_page.record_instead')}
                        </p>
                    )}
                </button>
            )}
        </div>
    )
}

export default FreeTextResponder
