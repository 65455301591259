import { StoryboardApi } from '../api/storyboardApi'
import MVPCardGrid from '../components/MVPCard/MVPCardGrid'
import Spinner from '../components/Spinner'
import { useUserStore } from '../context/user/store'
import { Nodeio } from '../types/Nodeio'
import { Storyboard } from '../types/Storyboard'
import { defaultBrandBgColor } from '../utils/constant'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const StoryboardLandingPage = (): JSX.Element => {
    const { id } = useParams<{ id: string }>()

    const {
        state: { loading },
        setLoading,
    } = useUserStore()

    useEffect(() => {
        document.body.style.overflowY = 'scroll'
        return () => {
            document.body.style.overflowY = 'hidden'
        }
    })

    const [storyboard, setStoryboard] = useState<Storyboard | null>(null)
    const fetchStoryBoard = async () => {
        setLoading(true)
        try {
            if (id) {
                const [storyboardResponse] = await Promise.all([StoryboardApi.getByQuestionId(id)])
                setStoryboard(storyboardResponse.data)
                setLoading(false)
            }
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (id) {
            void fetchStoryBoard()
        }
    }, [id])
    return (
        <div className="flex flex-col min-h-screen">
            <div
                className="relative h-[287px] sm:h-[287px]"
                style={{ background: storyboard?.background_color || defaultBrandBgColor }}
            >
                {storyboard && storyboard.background_image_url && (
                    <img
                        className="absolute top-0 left-0 w-full h-full object-cover hidden sm:block"
                        src={storyboard.background_image_url}
                        alt="background"
                    />
                )}
                {storyboard && storyboard.background_mobile_image_url && (
                    <img
                        className="absolute top-0 left-0 w-full h-full object-cover block sm:hidden"
                        src={storyboard.background_mobile_image_url}
                        alt="background"
                    />
                )}
            </div>
            <div className="flex-1 flex justify-center bg-[#0C1B2C] pb-4">
                <div className="mx-2 lg:mx-12 xl:mx-22 flex flex-col flex-1 pt-8">
                    <p className="text-[#BFDEFF] text-2xl text-center font-bold font-lato">
                        {storyboard?.storyboard_title || storyboard?.question?.question}
                    </p>
                    {storyboard && (
                        <div className="mt-12 h-10 flex flex-row mb-6 mx-4">
                            <p className="text-white flex flex-row justify-center items-center gap-3">
                                <span>Soundbites</span>
                                <span className="text-sm px-3 py-1 border-[1.5px] border-white rounded-2xl text-center flex items-center justify-center">
                                    {storyboard?.mvps.length}
                                </span>
                            </p>
                        </div>
                    )}
                    {!_.isEmpty(storyboard?.mvps) ? (
                        <MVPCardGrid mvps={storyboard?.mvps as Nodeio[]} type="storybook-public" />
                    ) : (
                        <p className="mt-10 text-center text-sm text-white">No MVPs at this time</p>
                    )}
                </div>
            </div>
            {loading && <Spinner />}
        </div>
    )
}

export default StoryboardLandingPage
