export enum CampaignType {
    ASYNC = 'async',
    LIVE = 'live',
}

export enum CampaignStatus {
    ACTIVE = 'active',
    ARCHIVED = 'archived',
    DELETED = 'deleted',
}
