import { useEffect, useState } from 'react'
import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton,
} from 'react-share'

interface MVPShareModalProps {
    open: boolean
    url: string
    onToggle: () => void
    onShowQrCode: () => void
}

const MVPShareModal = ({ open, url, onToggle, onShowQrCode }: MVPShareModalProps) => {
    const [copied, setCopied] = useState<boolean>(false)

    useEffect(() => {
        if (copied) {
            const timeout = setTimeout(() => {
                setCopied(false)
            }, 3000)

            return () => clearTimeout(timeout)
        }
    }, [copied])

    const copyToClipboard = (): void => {
        navigator.clipboard.writeText(url)
        setCopied(true)
    }

    return (
        <div
            className={`fixed top-0 left-0 w-full h-full bg-[#000000c7] flex justify-center items-center z-50 ${
                open ? 'fixed' : 'hidden'
            }`}
        >
            <div
                className="relative mx-4 w-full sm:w-[750px] bg-white p-6 rounded-2xl flex flex-col z-30"
                style={{ boxShadow: '0px 4px 4px 0px #00000040' }}
            >
                <div className="flex-1">
                    <div className="flex justify-between items-center">
                        <h2 className="text-[#101828] text-2xl font-semibold">
                            Share (MVPs) Board
                        </h2>
                        <button onClick={onToggle} className="p-2.5">
                            <img src="/asset/close.svg" />
                        </button>
                    </div>
                    <div className="mt-7 flex flex-col">
                        <div className="flex justify-between items-center rounded-lg bg-[#E9F3FF] my-3 h-[52px]">
                            <p className="text-[#007AFF] ellipsis-1 flex-1 text-xl break-all px-5">
                                {url}
                            </p>
                            <div className="flex flex-row gap-2 self-end sm:self-start items-center h-full">
                                <button className="p-1" title="Scan QR code" onClick={onShowQrCode}>
                                    <img
                                        src="/asset/qr_code_scaner.svg"
                                        className="w-[27px] h-[27px]"
                                        alt="qr_code"
                                    />
                                </button>

                                <button className="p-1 block sm:hidden mr-3" title="Copy">
                                    <img
                                        src="/asset/copy.svg"
                                        className="w-[27px] h-[27px]"
                                        alt="qr_code"
                                    />
                                </button>
                                <button
                                    className="ml-1 text-base text-white bg-[#007AFF] font-bold px-8 h-full rounded-2xl hidden sm:block"
                                    onClick={copyToClipboard}
                                    disabled={copied}
                                >
                                    {copied ? 'Copied' : 'Copy'}
                                </button>
                            </div>
                        </div>
                        {/* <div className="bg-[#F9FAFB] border-[#D0D5DD] border rounded-lg flex items-center gap-2 text-[#101828] text-xl font-normal p-3 mb-9">
                            <img src="/asset/code.svg" />
                            Embed board
                        </div> */}

                        <div className="w-full h-[1px] bg-[#D0D5DD]"></div>
                        <div className="flex justify-center items-center gap-3 py-3.5">
                            <LinkedinShareButton url={url}>
                                <LinkedinIcon size="40" />
                            </LinkedinShareButton>
                            <FacebookShareButton url={url} quote="Share (MVPs) Board">
                                <FacebookIcon size="40" />
                            </FacebookShareButton>
                            <TwitterShareButton url={url}>
                                <TwitterIcon size="40" />
                            </TwitterShareButton>
                        </div>

                        <p className="self-center text-base text-[#101828] font-normal mt-4 text-center">
                            This shares a view-only of the MVP board
                        </p>
                    </div>
                </div>
            </div>

            <div className="absolute w-full h-full bg-transparent z-0" onClick={onToggle} />
        </div>
    )
}

export default MVPShareModal
