import { InterviewApi } from '../api/interviews'
import InterviewResponsePreview from '../components/InterviewResponsePreview'
import RecorderButton from '../components/RecorderButton'
import SelectLanguage from '../components/SelectLanguage'
import Spinner from '../components/Spinner'
import TextWithLinks from '../components/TextWithLinks'
import { useQuestionStore } from '../context/question/store'
import { Page } from '../enums/Page'
import { ConversationMember } from '../types/ConversationMember'
import { Interview } from '../types/Interview'
import { defaultBrandBgColor } from '../utils/constant'
import { upperCaseLeading } from '../utils/upperCaseLeading'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import { clarity } from 'clarity-js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

export interface Response {
    questionId?: string
    length?: number
    formData?: FormData
    title?: string
}

const QuestionResponder = (): JSX.Element => {
    const { slug } = useParams<{ slug: string }>()
    const {
        state: { isQuestionResponderLanded, accepted, openFooter },
        setAccepted,
        setOpenFooter,
        setQuestionResponderLanded,
    } = useQuestionStore()

    const CLARITY_PROJECT_ID = 'j8qkp85nmj'
    const [interview, setInterview] = useState<Interview | null>(null)
    const [response, setResponse] = useState<Response | null>()
    const [conversationTitle, setConversationTitle] = useState<string>('')
    const [speakers, setSpeakers] = useState<ConversationMember[]>([
        { firstName: '', lastName: '', email: '' },
    ])
    const [showError, setShowError] = useState<boolean>(false)
    const [isRecordingSupported, setIsRecordingSupported] = useState<boolean>(true)
    const [showSpinner, setShowSpinner] = useState<boolean>(false)
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
    const [errorSubmitting, setErrorSubmitting] = useState<boolean>(false)
    const [shouldBlockNavigation, setShouldBlockNavigation] = useState<boolean>(true)
    const navigate = useNavigate()

    const FINAL_THIRD_FOUNDATION_ID = '3b19372a-3d69-4d49-bb75-b18d40f1e82b'

    const { t, i18n } = useTranslation()

    const translatedTitle =
        interview?.settings?.translations?.find(
            (translation) => translation.language === i18n.language,
        )?.title ?? interview?.title

    useEffect(() => {
        if (shouldBlockNavigation) {
            window.onbeforeunload = () => true
        } else {
            window.onbeforeunload = null
        }
    }, [shouldBlockNavigation])

    const fetchInterview = async () => {
        if (slug) {
            const response = await InterviewApi.getBySlug(slug)
            setInterview(response.data)
        }
    }

    useEffect(() => {
        void fetchInterview()
    }, [slug])

    useEffect(() => {
        if (process.env.REACT_APP_ENV === 'production') {
            clarity.consent()
            clarity.start({
                projectId: CLARITY_PROJECT_ID,
                upload: 'https://m.clarity.ms/collect',
                track: true,
                content: true,
            })

            return () => {
                clarity.stop()
            }
        }
    }, [])

    const uploadFile = async (file: File, signedUrl: string): Promise<void> => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader()

                reader.onloadend = async () => {
                    try {
                        const buffer = reader.result
                        await axios.put(signedUrl, buffer, {
                            headers: {},
                        })
                        resolve() // Resolve the promise here
                    } catch (error) {
                        reject(error) // Reject the promise in case of an error
                    }
                }

                reader.onerror = (e) => {
                    reject(e) // Reject the promise in case of an error
                }

                reader.readAsArrayBuffer(file)
            } catch (e) {
                reject(e) // Reject the promise in case of an error
            }
        })
    }

    const handleSubmit = async (): Promise<void> => {
        if (slug) {
            setIsSubmitted(true)
            setErrorSubmitting(false)
            setShouldBlockNavigation(false)
            try {
                setShowSpinner(true)
                const generatePreSignedUrlRequest = {
                    conversation_title: conversationTitle,
                    speakers: speakers.map((speaker) => ({
                        first_name: speaker.firstName,
                        last_name: speaker.lastName,
                        email: speaker.email,
                    })),
                }
                const preSignedUrlResponse = await InterviewApi.generatePreSignedUrl(
                    slug,
                    generatePreSignedUrlRequest,
                )
                const url = preSignedUrlResponse.data.url
                const file: File = response?.formData?.get('file') as File

                await uploadFile(file, url)
                navigate(Page.SUCCESSFULLY_SAVED_RESPONSES)
            } catch (e: any) {
                Sentry.captureException(e)
                setErrorSubmitting(true)
            } finally {
                setIsSubmitted(false)
                setShowSpinner(false)
            }
        }
    }

    const addSpeakerHandler = () => {
        setSpeakers([
            ...speakers,
            {
                firstName: '',
                lastName: '',
                email: '',
            },
        ])
    }

    const removeSpeakerHandler = (index: number) => {
        const updatedSpeakers = speakers.filter((_, i) => i !== index)
        if (updatedSpeakers.length === 0) {
            setSpeakers([
                {
                    firstName: '',
                    lastName: '',
                    email: '',
                },
            ])
        } else {
            setSpeakers(updatedSpeakers)
        }
    }

    return (
        <div className="w-full h-screen">
            <div className="w-full h-[95%] relative flex flex-col items-center web-responder">
                <SelectLanguage />
                <div
                    className="relative w-full h-[330px] xs:h-[290px] sm:h-[250px] flex justify-center"
                    style={{
                        background:
                            interview?.author?.brand?.background_color || defaultBrandBgColor,
                    }}
                >
                    {interview?.author?.brand?.background_image_url && (
                        <img
                            src={interview?.author?.brand?.background_image_url}
                            className="w-full h-full absolute object-cover hidden sm:block"
                        />
                    )}
                    {interview?.author?.brand?.background_mobile_image_url && (
                        <img
                            src={interview?.author?.brand?.background_mobile_image_url}
                            className="w-full h-full absolute object-cover block sm:hidden"
                        />
                    )}
                </div>
                <div className="flex flex-1 bg-white"></div>
                <div className="top-[140px] absolute flex flex-col items-center w-screen md:w-fit bg-transparent px-[25px] md:px-[0px]">
                    <div>{showSpinner && <Spinner />}</div>
                    <div
                        className="w-full md:w-[720px] px-[45px] pt-10 pb-4 rounded-lg min-h-[244px]"
                        style={{
                            background:
                                'var(--card, linear-gradient(313deg, rgba(107, 178, 255, 0.19) 7.36%, rgba(0, 122, 255, 0.13) 33.85%, rgba(107, 178, 255, 0.13) 68.75%, rgba(0, 122, 255, 0.19) 93.75%), #0D0D0D)',
                        }}
                    >
                        <React.Fragment>
                            <div className="flex flex-row items-center gap-4">
                                <img
                                    src={
                                        interview?.author.profile_photo?.url ??
                                        'https://thetrustapp.s3.us-east-2.amazonaws.com/profile-icon-96x96.png'
                                    }
                                    width={40}
                                    height={40}
                                    className="z-10 w-10 h-10 rounded-full object-cover"
                                    alt="profile_photo"
                                />
                                <div className="flex flex-col justify-center">
                                    <p className="text-base text-[#BFDEFF]">
                                        {interview?.author?.first_name}{' '}
                                        {interview?.author?.last_name}
                                    </p>
                                    <p className="text-[13px] text-[#BFDEFF] font-lato">
                                        {interview?.author?.bio}
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-col gap-2 mt-[26px] mb-4">
                                <p className="text-[#BFDEFF] font-bold mb-1.5">
                                    {t('recorder_button.you_are_capturing_conversations_for')}
                                </p>
                                <TextWithLinks
                                    className="text-2xl text-white font-lato whitespace-pre-wrap overflow-x-auto"
                                    linkClassName="text-[#007AFF]"
                                    text={translatedTitle ?? ''}
                                ></TextWithLinks>
                            </div>
                        </React.Fragment>
                    </div>

                    <div className="w-full md:w-[720px] flex flex-col justify-center pb-[200px] sm:mb-10">
                        <div>
                            {!response && (
                                <div className="flex flex-col mt-[60px] px-2 items-center md:items-start w-full justify-center">
                                    <div className="flex flex-col items-center mx-2 justify-center w-full">
                                        <RecorderButton
                                            onStop={(formData, audioLength) => {
                                                setResponse({
                                                    length: audioLength,
                                                    formData,
                                                })
                                            }}
                                            onRecorderNotSupported={() =>
                                                setIsRecordingSupported &&
                                                setIsRecordingSupported(false)
                                            }
                                            isQuestionResponder={true}
                                            isLiveMode={true}
                                            disabled={!accepted}
                                        />
                                    </div>
                                </div>
                            )}
                            {!!response && (
                                <div>
                                    <div className="mt-10">
                                        <InterviewResponsePreview
                                            response={response as any}
                                            onReRecord={() => {
                                                setResponse(null)
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <h5 className="text-[#001F40] text-sm font-lato mb-4 mt-20">
                                            {t('recorder_button.conversation_title')}
                                        </h5>
                                        <input
                                            type="text"
                                            placeholder={`${t(
                                                'add_more_info.enter_a_title',
                                            )} ( ${upperCaseLeading(t('common.optional'))})`}
                                            className="bg-[#D9D9D940] text-[#000000] text-sm font-lato h-7 w-[360px] border-none leading-[17px] px-2 rounded-lg"
                                            value={conversationTitle}
                                            onChange={(e) => setConversationTitle(e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <h5 className="text-[#001F40] text-sm font-lato mb-4 mt-10">
                                            {t('recorder_button.conversation_members')}
                                        </h5>
                                        <div className="flex flex-col gap-4">
                                            {speakers.map((speaker, index) => {
                                                return (
                                                    <div
                                                        className="flex flex-row gap-4"
                                                        key={index}
                                                    >
                                                        <input
                                                            type="text"
                                                            placeholder={`${t(
                                                                'add_more_info.first_name',
                                                            )} ( ${upperCaseLeading(
                                                                t('common.optional'),
                                                            )})`}
                                                            className="bg-[#D9D9D940] text-[#000000] text-sm font-lato h-7 w-full border-none leading-[17px] px-2 rounded-lg"
                                                            value={speaker.firstName}
                                                            onChange={(e) => {
                                                                setSpeakers(
                                                                    speakers.map((s, i) => {
                                                                        if (i === index) {
                                                                            return {
                                                                                ...s,
                                                                                firstName:
                                                                                    e.target.value,
                                                                            }
                                                                        }
                                                                        return s
                                                                    }),
                                                                )
                                                            }}
                                                        />
                                                        <input
                                                            type="text"
                                                            placeholder={`${t(
                                                                'add_more_info.last_name',
                                                            )} ( ${upperCaseLeading(
                                                                t('common.optional'),
                                                            )})`}
                                                            className="bg-[#D9D9D940] text-[#000000] text-sm font-lato h-7 w-full border-none leading-[17px] px-2 rounded-lg"
                                                            value={speaker.lastName}
                                                            onChange={(e) => {
                                                                setSpeakers(
                                                                    speakers.map((s, i) => {
                                                                        if (i === index) {
                                                                            return {
                                                                                ...s,
                                                                                lastName:
                                                                                    e.target.value,
                                                                            }
                                                                        }
                                                                        return s
                                                                    }),
                                                                )
                                                            }}
                                                        />
                                                        <input
                                                            type="text"
                                                            placeholder={`${t(
                                                                'add_more_info.email',
                                                            )} ( ${upperCaseLeading(
                                                                t('common.optional'),
                                                            )})`}
                                                            className="bg-[#D9D9D940] text-[#000000] text-sm font-lato h-7 w-full border-none leading-[17px] px-2 rounded-lg"
                                                            value={speaker.email}
                                                            onChange={(e) => {
                                                                setSpeakers(
                                                                    speakers.map((s, i) => {
                                                                        if (i === index) {
                                                                            return {
                                                                                ...s,
                                                                                email: e.target
                                                                                    .value,
                                                                            }
                                                                        }
                                                                        return s
                                                                    }),
                                                                )
                                                            }}
                                                        />
                                                        <button
                                                            className="p-1"
                                                            onClick={() =>
                                                                removeSpeakerHandler(index)
                                                            }
                                                        >
                                                            <div className="flex flex-row gap-1.5 w-6 h-6">
                                                                <img
                                                                    src="/asset/trash_bin.svg"
                                                                    alt="trash_icon"
                                                                />
                                                            </div>
                                                        </button>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className="mt-4 flex items-center flex-row gap-4">
                                            <button className="p-1" onClick={addSpeakerHandler}>
                                                <svg
                                                    width="25"
                                                    height="25"
                                                    viewBox="0 0 25 25"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M12.5 2.34375C6.8999 2.34375 2.34375 6.8999 2.34375 12.5C2.34375 18.1001 6.8999 22.6563 12.5 22.6563C18.1001 22.6563 22.6563 18.1001 22.6563 12.5C22.6563 6.8999 18.1001 2.34375 12.5 2.34375ZM16.4063 13.2813H13.2813V16.4063C13.2813 16.6135 13.1989 16.8122 13.0524 16.9587C12.9059 17.1052 12.7072 17.1875 12.5 17.1875C12.2928 17.1875 12.0941 17.1052 11.9476 16.9587C11.8011 16.8122 11.7188 16.6135 11.7188 16.4063V13.2813H8.59375C8.38655 13.2813 8.18784 13.1989 8.04132 13.0524C7.89481 12.9059 7.8125 12.7072 7.8125 12.5C7.8125 12.2928 7.89481 12.0941 8.04132 11.9476C8.18784 11.8011 8.38655 11.7188 8.59375 11.7188H11.7188V8.59375C11.7188 8.38655 11.8011 8.18784 11.9476 8.04132C12.0941 7.89481 12.2928 7.8125 12.5 7.8125C12.7072 7.8125 12.9059 7.89481 13.0524 8.04132C13.1989 8.18784 13.2813 8.38655 13.2813 8.59375V11.7188H16.4063C16.6135 11.7188 16.8122 11.8011 16.9587 11.9476C17.1052 12.0941 17.1875 12.2928 17.1875 12.5C17.1875 12.7072 17.1052 12.9059 16.9587 13.0524C16.8122 13.1989 16.6135 13.2813 16.4063 13.2813Z"
                                                        fill="#99A4B1"
                                                    />
                                                </svg>
                                            </button>
                                            <p>{t('recorder_button.add_speaker')}</p>
                                        </div>
                                        {errorSubmitting && showError && (
                                            <div className="h-[56px] mt-8">
                                                <p className="px-5 py-4 bg-[#B6D6FA5E] text-[#007AFF] font-lato">
                                                    🚨 {errorSubmitting}
                                                </p>
                                            </div>
                                        )}
                                        <div className="mt-8 flex justify-end">
                                            <button
                                                className={`text-[#BFDEFF] ${
                                                    !accepted || isSubmitted
                                                        ? 'bg-[#add4ff] text-[#AFAFAF]'
                                                        : 'bg-[#007AFF] '
                                                } px-6 py-1 rounded-[10px]`}
                                                disabled={!accepted || isSubmitted}
                                                onClick={() => {
                                                    setShowError(false)
                                                    if (isSubmitted) return
                                                    handleSubmit()
                                                }}
                                            >
                                                {t('common.submit')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {!isQuestionResponderLanded && !accepted && openFooter && (
                                <p className="text-sm text-[#007AFF] font-bold mt-6">
                                    {t('recorder_button.please_accept_the_terms_first')}
                                </p>
                            )}
                        </div>
                        {errorSubmitting && (
                            <p className="mt-4 mb-4 text-red-400">
                                🚨 {t('error_messages.submit_error')}
                            </p>
                        )}
                        {!isRecordingSupported && (
                            <p className="mt-4 text-red-400">
                                {t('question_responder_page.note_recording_not_supported')}
                            </p>
                        )}
                    </div>
                </div>
                <div
                    className={`fixed bottom-0 w-full left-0 bg-[#0D0D0D] rounded-t-lg px-[40px] lg:px-[100px] py-[30px] md:pb-[40px] lg:pb-[50px] flex-col md:flex-row items-center justify-between gap-5 transition-all ${
                        openFooter && !accepted ? 'flex' : 'hidden'
                    }`}
                >
                    <div>
                        <h2 className="text-xl leading-9 font-bold text-[#BFDEFF]">
                            {t('recorder_button.before_user_can_hear_your_voice').replace(
                                '{first_name}',
                                interview?.author?.first_name || '',
                            )}
                        </h2>
                        <p className="text-[15px] text-[#BFDEFF]">
                            {t('recorder_button.in_order_to_respond_please_accept_our')}{' '}
                            <>
                                <a
                                    href="/terms"
                                    target="_blank"
                                    className="hover:underline text-[#007AFF]"
                                >
                                    {t('recorder_button.terms_of_use')}
                                </a>{' '}
                                {t('common.and')}{' '}
                                <a
                                    href="/privacy"
                                    target="_blank"
                                    className="hover:underline text-[#007AFF]"
                                >
                                    {t('recorder_button.privacy_and_policy')}
                                </a>
                                {interview?.author?.id === FINAL_THIRD_FOUNDATION_ID && (
                                    <>
                                        <span>
                                            .{' '}
                                            {t(
                                                'recorder_button.accepting_serves_as_your_consent_to_the',
                                            )}{' '}
                                        </span>
                                        <a
                                            href="https://docs.google.com/document/d/1p8TQ7qZS5p9wUSKJCGteginWb_JmMuKemHO14N4d48o/edit"
                                            target="_blank"
                                            className="hover:underline text-[#007AFF]"
                                        >
                                            {t('recorder_button.media_release_form')}
                                        </a>
                                        <span>
                                            {' '}
                                            {t('recorder_button.of')} Final Third Foundation.
                                        </span>
                                    </>
                                )}
                            </>
                        </p>
                    </div>
                    <div className="flex gap-4">
                        <button
                            className="text-sm bg-[#007AFF] text-[#FFFFFF] font-bold px-12 py-2 rounded-lg"
                            onClick={() => {
                                setQuestionResponderLanded(false)
                                setOpenFooter(false)
                                setAccepted(true)
                            }}
                        >
                            {t('common.accept')}
                        </button>
                        <button
                            className="text-sm bg-[#FFFFFF] text-[#001F40DE] font-bold px-12 py-2 rounded-lg"
                            onClick={() => {
                                setQuestionResponderLanded(false)
                                setAccepted(false)
                                setOpenFooter(false)
                            }}
                        >
                            {t('common.decline')}
                        </button>
                    </div>
                </div>
            </div>
            <div className="flex justify-end items-center mb-10 mr-10">
                <a href="/privacy" target="_blank" className="hover:underline text-[#007AFF]">
                    Tone AI {t('recorder_button.privacy_and_policy_custom_consent')}
                </a>
            </div>
        </div>
    )
}
export default QuestionResponder
