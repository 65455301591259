import { CreatedQuestionsResponse } from '../../types/CreatedQuestionsResponse'
import { Question } from '../../types/Question'
import types, { QuestionAction } from './types'
import { Dispatch } from 'react'

export const setTooltipHoverAction = (
    status: boolean,
    dispatch: Dispatch<QuestionAction>,
): void => {
    dispatch({
        type: types.SET_TOOLTIP_HOVER,
        payload: { status },
    })
}

export const setCreatedQuestionsAction = (
    data: CreatedQuestionsResponse | null,
    dispatch: Dispatch<QuestionAction>,
): void => {
    dispatch({
        type: types.SET_CREATED_QUESTIONS,
        payload: { data },
    })
}

export const setLinkTitleAction = (linkTitle: string, dispatch: Dispatch<QuestionAction>): void => {
    dispatch({
        type: types.SET_LINK_TITLE,
        payload: { linkTitle },
    })
}

export const setQuestionsAction = (
    questions: Question[],
    dispatch: Dispatch<QuestionAction>,
): void => {
    dispatch({
        type: types.SET_QUESTIONS,
        payload: { questions },
    })
}

export const setAcceptedAction = (accepted: boolean, dispatch: Dispatch<QuestionAction>): void => {
    dispatch({
        type: types.SET_ACCEPTED,
        payload: { accepted },
    })
}

export const setShowTipAction = (showTip: boolean, dispatch: Dispatch<QuestionAction>): void => {
    dispatch({
        type: types.SET_SHOW_TIP,
        payload: { showTip },
    })
}

export const setOpenFooterAction = (
    openFooter: boolean,
    dispatch: Dispatch<QuestionAction>,
): void => {
    dispatch({
        type: types.SET_OPEN_FOOTER,
        payload: { openFooter },
    })
}

export const setQuestionResponderLandedAction = (
    questionResponderLanded: boolean,
    dispatch: Dispatch<QuestionAction>,
): void => {
    dispatch({
        type: types.SET_QUESTION_RESPONDER_LANDED,
        payload: { questionResponderLanded },
    })
}
