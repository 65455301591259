import { QuestionAsset } from '../types/QuestionAsset'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Image from 'react-image-enlarger'

interface QuestionAssetsProps {
    assets: QuestionAsset[]
    className?: string
    showDelete?: boolean
    handleDelete?: (index: number) => void
    currentImageIndexStyle?: string
}

const QuestionAssets = ({
    assets,
    className,
    showDelete,
    handleDelete,
    currentImageIndexStyle,
}: QuestionAssetsProps) => {
    const { t } = useTranslation()
    const [currentIndex, setCurrentIndex] = useState(0)
    const [zoomed, setZoomed] = useState(false)

    const goToPrevious = () => {
        if (currentIndex === 0) {
            return
        }
        setCurrentIndex((currentIndex) => (currentIndex > 0 ? currentIndex - 1 : assets.length - 1))
    }

    const goToNext = () => {
        if (currentIndex === assets.length - 1) {
            return
        }
        setCurrentIndex((currentIndex) => (currentIndex < assets.length - 1 ? currentIndex + 1 : 0))
    }

    if (!assets || !assets[currentIndex]) {
        return null
    }

    return (
        <div className="flex flex-row items-center justify-center gap-4">
            <div className="w-[68px]">
                {currentIndex > 0 && (
                    <button
                        onClick={goToPrevious}
                        className=" h-10 bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded-l"
                    >
                        <svg
                            width="14"
                            height="24"
                            viewBox="0 0 14 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                opacity="0.8"
                                d="M0.93934 10.9393C0.353553 11.5251 0.353553 12.4749 0.93934 13.0607L10.4853 22.6066C11.0711 23.1924 12.0208 23.1924 12.6066 22.6066C13.1924 22.0208 13.1924 21.0711 12.6066 20.4853L4.12132 12L12.6066 3.51472C13.1924 2.92893 13.1924 1.97919 12.6066 1.3934C12.0208 0.807611 11.0711 0.807611 10.4853 1.3934L0.93934 10.9393ZM3 10.5H2L2 13.5H3L3 10.5Z"
                                fill="#001F40"
                            />
                        </svg>
                    </button>
                )}
            </div>
            <div className="relative">
                <div>
                    {showDelete && (
                        <div className="w-10 h-10 bg-gray-300 absolute top-0 right-0 opacity-90 z-10">
                            <button
                                onClick={() => {
                                    if (handleDelete) {
                                        handleDelete(currentIndex)
                                        setCurrentIndex(0)
                                    }
                                }}
                                className="flex items-center justify-center w-full h-full"
                                title="Delete file"
                            >
                                <img width={32} height={32} src="/asset/trash_bin.svg" />
                            </button>
                        </div>
                    )}
                    {assets[currentIndex]?.type === 'image' ? (
                        <div className="flex flex-col justify-center">
                            <Image
                                src={assets[currentIndex].path}
                                zoomed={zoomed}
                                className={`mx-auto object-contain w-[400px] h-[400px] ${className}`}
                                onClick={() => setZoomed(true)}
                                onRequestClose={() => setZoomed(false)}
                            />
                            <p className="text-center text-sm text-white italic mb-2">
                                {t('common.click-to-enlarge')}
                            </p>
                        </div>
                    ) : (
                        <video
                            key={assets[currentIndex].path}
                            controls
                            className={`mx-auto object-contain w-[400px] h-[400px] ${className}`}
                        >
                            <source src={assets[currentIndex].path} />
                        </video>
                    )}
                </div>
                {assets?.length > 1 && (
                    <div className={`text-center text-[#001F40] text-sm ${currentImageIndexStyle}`}>
                        {currentIndex + 1} of {assets.length}
                    </div>
                )}
            </div>
            <div className="w-[68px]">
                {currentIndex < assets.length - 1 && (
                    <button
                        onClick={goToNext}
                        className="h-10 bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded-r"
                    >
                        <svg
                            width="14"
                            height="24"
                            viewBox="0 0 14 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                opacity="0.8"
                                d="M13.0607 13.0607C13.6464 12.4749 13.6464 11.5251 13.0607 10.9393L3.51472 1.3934C2.92893 0.807611 1.97919 0.807611 1.3934 1.3934C0.807611 1.97919 0.807611 2.92893 1.3934 3.51472L9.87868 12L1.3934 20.4853C0.807611 21.0711 0.807611 22.0208 1.3934 22.6066C1.97919 23.1924 2.92893 23.1924 3.51472 22.6066L13.0607 13.0607ZM11 13.5H12V10.5H11V13.5Z"
                                fill="#001F40"
                            />
                        </svg>
                    </button>
                )}
            </div>
        </div>
    )
}

export default QuestionAssets
