import { NodeioApi } from '../api/nodeio'
import { Callback } from '../types/Authentication'
import { Nodeio } from '../types/Nodeio'

export const isSignedUrlExpired = async (signedUrl: string): Promise<boolean> => {
    try {
        const response = await fetch(signedUrl)
        // Check if the request was successful
        if (response.ok) {
            return false
        } else {
            // If the status code is 403, it could mean the URL is expired
            if (response.status === 403) {
                return true
            } else {
                return false
            }
        }
    } catch (error) {
        return true
    }
}

export const reloadAudio = async (nodeio: Nodeio, callback?: Callback) => {
    try {
        const response = await NodeioApi.getById(nodeio.id)
        if (callback) callback(response.data)
    } finally {
    }
    // }
}

export function isJsonString(str: string) {
    try {
        JSON.parse(str)
    } catch (e) {
        return false
    }
    return true
}
