import { Nodeio } from '../../types/Nodeio'
import { reloadAudio } from '../../utils/audio'
import { getLanguageName } from '../../utils/getLanguageName'
import { Tooltip } from 'flowbite-react'
import { useEffect, useRef, useState } from 'react'
import { useOverflowDetector } from 'react-detectable-overflow'
import AudioPlayer from 'react-h5-audio-player'

interface ResponseMVPCardStripeProps {
    onSelect: (id: string) => void
    mvp: Nodeio
}

const ResponseMVPCardStripe = ({ mvp, onSelect }: ResponseMVPCardStripeProps) => {
    const [nodeio, setNodeio] = useState<Nodeio>(mvp)
    const player = useRef<any>()
    const { ref, overflow } = useOverflowDetector({})
    const [originalLanguage, setOriginalLanguage] = useState<string | null>('Responded in English')

    useEffect(() => {
        setNodeio(mvp)
        if (mvp?.media?.transcription_language) {
            setOriginalLanguage('Responded in ' + getLanguageName(mvp.media.transcription_language))
        } else if (mvp?.settings?.original_language) {
            setOriginalLanguage('Responded in ' + getLanguageName(mvp.settings.original_language))
        }
    }, [mvp])

    const [more, setMore] = useState<boolean>(false)
    const [isExpand, setIsExpand] = useState<boolean>(false)

    const toggleMore = () => setMore((_) => !_)
    const toggleExpand = () => setIsExpand((_) => !_)

    return (
        <div className="my-1.5 p-4 rounded-lg bg-[#1F2C3B] w-full gap-4">
            <div className="flex gap-4">
                <input
                    type="checkbox"
                    checked={nodeio.checked || false}
                    onChange={() => onSelect(nodeio.id)}
                    className="mt-4 mr-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <div className="flex flex-col flex-1">
                    <p className="text-white text-xs">
                        {!nodeio.author?.first_name && !nodeio.author?.last_name
                            ? 'Anonymous'
                            : `${nodeio.author?.first_name} ${nodeio.author?.last_name}`}
                    </p>
                    {nodeio.impact_statement &&
                        nodeio.impact_statement.trim() !== '' &&
                        !nodeio.impact_statement.includes('Impact statement not available') && (
                            <p className="text-base text-white">
                                {nodeio?.impact_statement?.replaceAll('"', '')}
                            </p>
                        )}
                    <div className="flex mt-2 items-start gap-10">
                        <p
                            ref={ref as any}
                            className={`mt-1 flex-1 text-xs leading-[1.05rem] text-white ${
                                !more ? 'ellipsis-1' : ''
                            }`}
                        >
                            {nodeio.media?.translated_transcription_text ??
                                nodeio.media?.transcription_text ??
                                nodeio.settings?.translated_title ??
                                nodeio.title ??
                                'No transcription available.'}
                        </p>
                        {(nodeio.media?.translated_transcription_text ||
                            nodeio.media?.transcription_text ||
                            nodeio.settings?.translated_title ||
                            nodeio.title) &&
                            overflow && (
                                <button
                                    className="mt-0.5 flex items-center gap-2 font-semibold"
                                    onClick={toggleMore}
                                >
                                    <p className="text-sm text-[#007AFF]">Read response</p>
                                    <img
                                        src="/asset/up.svg"
                                        className={`${!more && 'rotate-180'}`}
                                    />
                                </button>
                            )}
                    </div>
                </div>
                <div className="flex flex-col">
                    <button
                        className="w-[36px] h-[36px] flex items-center justify-center"
                        onClick={() => {
                            toggleExpand()
                            setTimeout(() => {
                                player.current?.playAudioPromise()
                            }, 1000)
                        }}
                    >
                        <img src="/asset/play-button-icon.svg" />
                    </button>
                    {((nodeio.media?.transcription_language &&
                        nodeio.media?.transcription_language.toLocaleLowerCase() !== 'en' &&
                        nodeio.media?.transcription_language.toLocaleLowerCase() !== 'english') ||
                        (nodeio.settings?.original_language &&
                            nodeio.settings?.original_language.toLocaleLowerCase() !== 'en' &&
                            nodeio.settings?.original_language.toLocaleLowerCase() !==
                                'english')) && (
                        <Tooltip content={originalLanguage} style="light">
                            <button
                                className="text-sm text-white bg-[#007AFF55] rounded-xl px-3 self-center mt-2"
                                disabled
                            >
                                {nodeio.media?.transcription_language ||
                                    nodeio.settings?.original_language}
                            </button>
                        </Tooltip>
                    )}
                </div>
            </div>
            {isExpand && (
                <div className="mt-4">
                    <AudioPlayer
                        ref={player}
                        src={nodeio?.media?.url}
                        timeFormat="mm:ss"
                        className="rounded-lg bg-[#EAECF0]"
                        loop={false}
                        autoPlay={false}
                        autoPlayAfterSrcChange={false}
                        onPlayError={() => {
                            if (nodeio.media?.url) {
                                reloadAudio(nodeio, (data) => {
                                    setNodeio(data)
                                    player.current?.playAudioPromise()
                                })
                            }
                        }}
                    />
                </div>
            )}
        </div>
    )
}

export default ResponseMVPCardStripe
