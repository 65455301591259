/* eslint-disable indent */
import { User } from '../../types/User'
import { SET_LOADING, SET_USER, UserAction } from './types'
import * as Sentry from '@sentry/react'

export interface UserState {
    user: User | null
    loading: boolean
}

export const initialUserStoreState: UserState = {
    user: null,
    loading: false,
}

export const userReducer = (state = initialUserStoreState, action: UserAction): UserState => {
    switch (action.type) {
        case SET_USER:
            if (action.payload.user === null) {
                Sentry.setUser(null)
            } else {
                Sentry.setUser({
                    id: action.payload.user?.id,
                })
            }
            return {
                ...state,
                user: action.payload.user,
            }
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload.loading,
            }
        default:
            return state
    }
}
