import AutoSuggestedSingleOption from './AutoSuggestedSingleOption'
import React from 'react'

export interface AutoSuggestedOptionsProps {
    options: string[]
    onOptionClick: (option: string) => void
}

const AutoSuggestedOptions = ({
    options,
    onOptionClick,
}: AutoSuggestedOptionsProps): JSX.Element => {
    return (
        <div className="flex flex-wrap gap-2 text-sm text-[#101828] font-lato mb-5">
            {options.map((option, index) => (
                <AutoSuggestedSingleOption
                    key={index}
                    text={option}
                    onClick={() => onOptionClick(option)}
                />
            ))}
        </div>
    )
}

export default AutoSuggestedOptions
