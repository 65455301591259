import { NodeioApi } from '../../../api/nodeio'
import { useUserStore } from '../../../context/user/store'
import { MVPEditFieldType } from '../../../types/MVPResponse'
import { Nodeio } from '../../../types/Nodeio'
import { removeQuoteString } from '../../../utils/upperCaseLeading'
import { useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { toast } from 'react-toastify'

interface EditMVPCardModalProps {
    mvp: Nodeio
    setNodio: (data: Nodeio) => void
    open: boolean
    onToggle: () => void
    disableTranscriptionEdit?: boolean
}

const EditMVPCardModal = ({
    mvp,
    setNodio,
    open,
    onToggle,
    disableTranscriptionEdit = false,
}: EditMVPCardModalProps) => {
    const { setLoading } = useUserStore()

    const [fields, setFields] = useState<MVPEditFieldType>({
        first_name: mvp.author?.first_name,
        last_name: mvp.author?.last_name,
        impact_statement: mvp.impact_statement,
        transcription:
            mvp.media?.translated_transcription_text ||
            mvp.media?.transcription_text ||
            mvp.settings?.translated_title ||
            mvp.title,
    })

    const updateMVP = async () => {
        setLoading(true)
        try {
            const response = await NodeioApi.updateMVP(mvp.id, fields)

            if (response.status === 200) {
                setNodio(response.data)
                onToggle()
            }
        } catch (e) {
            toast.error('Failed updating Soundbite!')
        } finally {
            setLoading(false)
        }
    }

    return (
        <div
            className={`fixed top-0 left-0 w-full h-full bg-[#000000c7] flex justify-center items-center z-20 ${
                open ? 'fixed' : 'hidden'
            }`}
        >
            <div
                className="relative mx-4 w-full sm:w-[750px] bg-white p-6 rounded-2xl flex flex-col z-10"
                style={{ boxShadow: '0px 4px 4px 0px #00000040' }}
            >
                <div className="flex-1">
                    <div className="flex justify-between items-center">
                        <h2 className="text-[#101828] text-2xl font-semibold">
                            Clean up the Soundbite
                        </h2>
                        <button onClick={onToggle} className="p-2.5">
                            <img src="/asset/close.svg" />
                        </button>
                    </div>
                    <div className="mt-4 flex flex-col">
                        <span className="font-semibold text-black mb-1.5">First & Last Name</span>
                        <div className="flex items-center gap-4">
                            <input
                                className="text-sm leading-5 px-5 py-2.5 text-[#001F40] bg-[#e9e9ed] rounded-[10px] focus:outline focus:outline-[#D9D9D9] flex-[0.3]"
                                placeholder="First Name"
                                value={fields.first_name}
                                onChange={(e) =>
                                    setFields({ ...fields, first_name: e.target.value })
                                }
                            />
                            <input
                                className="text-sm leading-5 px-5 py-2.5 text-[#001F40] bg-[#e9e9ed] rounded-[10px] focus:outline focus:outline-[#D9D9D9] flex-[0.3]"
                                placeholder="Last Name"
                                value={fields.last_name}
                                onChange={(e) =>
                                    setFields({ ...fields, last_name: e.target.value })
                                }
                            />
                        </div>

                        <span className="font-semibold text-black mt-4 mb-2">Impact Statement</span>

                        <div className="flex">
                            <img src="/asset/quote-blue.svg" className="absolute pl-4 mt-3.5" />
                            <TextareaAutosize
                                className="text-base font-semibold text-[#007AFF] leading-[1.5] px-5 pl-12 py-4 bg-[#e9e9ed] rounded-[10px] focus:outline-none resize-none border-none flex-1 !max-h-[250px]"
                                placeholder="Impact Statement"
                                value={removeQuoteString(fields.impact_statement) || ''}
                                onChange={(e) =>
                                    setFields({ ...fields, impact_statement: e.target.value })
                                }
                            />
                        </div>

                        {disableTranscriptionEdit ? (
                            <span className="font-semibold text-[#001F4060] mt-4 mb-2">
                                Text Response (edit disabled)
                            </span>
                        ) : (
                            <span className="font-semibold text-black mt-4 mb-2">Transcript</span>
                        )}

                        <TextareaAutosize
                            className={`${
                                disableTranscriptionEdit ? 'bg-[#001F4060]' : ''
                            } text-[#001F40] text-sm bg-[#e9e9ed] focus:outline-none resize-none border-none mb-[20px] leading-[1.5] px-4 py-3 w-full rounded-[10px]  !max-h-[400px]`}
                            placeholder="Transcription text"
                            disabled={disableTranscriptionEdit}
                            value={fields.transcription || ''}
                            onChange={(e) =>
                                setFields({ ...fields, transcription: e.target.value })
                            }
                        />

                        <button
                            className="text-[#007AFF] text-[15px] font-semibold border-[#007AFF] border-[3px] rounded-3xl py-2 px-10 self-end"
                            onClick={updateMVP}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>

            <div className="absolute w-full h-full bg-transparent z-0" onClick={onToggle} />
        </div>
    )
}

export default EditMVPCardModal
