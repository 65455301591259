export const generateSlug = (inputText: string, generateRandom: boolean = true): string => {
    // Convert to lowercase
    let slugValue = inputText.toLowerCase().substring(0, 30)

    // Replace spaces and special characters with hyphens
    slugValue = slugValue.replace(/[^\w\s-]/g, '').replace(/\s+/g, '-')

    // Remove any remaining non-alphanumeric characters (except hyphens)
    slugValue = slugValue.replace(/[^a-zA-Z0-9-]/g, '')
    if (!generateRandom) return slugValue

    const randomDigits = Math.floor(Math.random() * 1000000)
    return `${slugValue}-${randomDigits}`
}
