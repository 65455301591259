//import { NodeioApi } from '../api/nodeio'
import { useUserStore } from '../context/user/store'
import { Nodeio } from '../types/Nodeio'
import { reloadAudio } from '../utils/audio'
import Spinner from './Spinner'
//import saveAs from 'file-saver'
//import moment from 'moment'
import React, { useRef, useState } from 'react'
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'

interface StoryboardSingleMVPItemBearsProps {
    nodeio: Nodeio
}

const StoryboardSingleMVPItemBears: React.FC<StoryboardSingleMVPItemBearsProps> = ({ nodeio }) => {
    const [showMore, setShowMore] = useState(false)

    const player = useRef<AudioPlayer>(null)
    const {
        state: { loading },
        //setLoading,
    } = useUserStore()

    const responseText =
        nodeio.media?.translated_transcription_text ||
        nodeio.media?.transcription_text ||
        nodeio.title ||
        'No transcription available'

    // const fullName = useMemo(() => {
    //     let temp = nodeio.author?.first_name + '-' + nodeio.author?.last_name
    //     if (temp === '-') temp = 'Guest'
    //     return temp
    // }, [nodeio.author])

    // const downloadAudio = async () => {
    //     try {
    //         var au = document.createElement('audio')
    //         au.src = nodeio.media?.url as string
    //         au.addEventListener(
    //             'loadedmetadata',
    //             async function () {
    //                 setLoading(true)
    //                 const res = await NodeioApi.downloadAudioStoryboard(nodeio.id as string)
    //                 const mimeType = res.headers['content-type']
    //                 if (res.status === 200) {
    //                     var blob = new Blob([res.data])
    //                     saveAs(
    //                         blob,
    //                         `${fullName}-${moment(nodeio.media?.created_at).format('MMDDYYYY')}.${
    //                             mimeType?.split('/')[1]
    //                         }`,
    //                     )
    //                 }
    //                 setLoading(false)
    //             },
    //             false,
    //         )
    //     } finally {
    //         setLoading(false)
    //     }
    // }

    const toggleShowMore = () => setShowMore((prevState) => !prevState)

    return (
        <div className="flex flex-col bg-[#1F2C3B] p-4 rounded-lg shadow-lg">
            <div className="flex flex-row justify-between items-center h-6">
                <p className="text-white text-xs font-lato font-semibold">
                    {nodeio.author?.first_name} {nodeio.author?.last_name}
                </p>
                <img src="/asset/bear_head_rgb.png" width={42} height={42} alt="Bears icon" />
                {/* <img
                    src={
                        nodeio.media?.url
                            ? '/asset/audio_nodeio_icon.svg'
                            : '/asset/text_nodeio_icon.svg'
                    }
                    alt="nodeio icon"
                /> */}
            </div>
            {nodeio.impact_statement &&
                nodeio.impact_statement.trim() !== '' &&
                !nodeio.impact_statement.includes('Impact statement not available') && (
                    <div className="flex items-start gap-1 mt-5">
                        <img src="/asset/quote.svg" alt="quote" />
                        <p className="text-base text-white">
                            {nodeio.impact_statement.replace(/"/g, '')}
                        </p>
                    </div>
                )}
            {nodeio.media?.url && (
                <div className="mt-6">
                    <AudioPlayer
                        ref={player}
                        src={nodeio.media.trimmed_url ?? nodeio.media.url}
                        customProgressBarSection={[
                            RHAP_UI.MAIN_CONTROLS,
                            RHAP_UI.PROGRESS_BAR,
                            RHAP_UI.CURRENT_TIME,
                            <div key="separator">/</div>,
                            RHAP_UI.DURATION,
                            // <button className="ml-3" onClick={downloadAudio}>
                            //     <svg
                            //         width="25"
                            //         height="24"
                            //         viewBox="0 0 25 24"
                            //         fill="none"
                            //         xmlns="http://www.w3.org/2000/svg"
                            //     >
                            //         <path
                            //             d="M21.3333 21H3.33325M18.3333 11L12.3333 17M12.3333 17L6.33325 11M12.3333 17V3"
                            //             stroke="#1570EF"
                            //             strokeWidth="2"
                            //             strokeLinecap="round"
                            //             strokeLinejoin="round"
                            //         />
                            //     </svg>
                            // </button>,
                        ]}
                        autoPlay={false}
                        autoPlayAfterSrcChange={false}
                        showJumpControls={false}
                        customVolumeControls={[]}
                        customAdditionalControls={[]}
                        customControlsSection={[]}
                        timeFormat="mm:ss"
                        className="customized-audio-player rounded-lg bg-[#EAECF0]"
                        onPlayError={() => {
                            reloadAudio(nodeio, () => {
                                player.current?.playAudioPromise()
                            })
                        }}
                    />
                </div>
            )}
            <button className="mt-5 flex items-center gap-2 font-semibold" onClick={toggleShowMore}>
                <p className="text-sm text-[#007AFF]">Read response</p>
                <img
                    src="/asset/up.svg"
                    className={`${!showMore && 'rotate-180'}`}
                    alt="toggle read response"
                />
            </button>
            {showMore && <p className="text-xs text-white mt-2">{responseText}</p>}
            {loading && <Spinner />}
        </div>
    )
}

export default StoryboardSingleMVPItemBears
