import { Campaign } from '../types/Campaign'
import { pluralizationUtil } from '../utils/pluralizationUtil'
import { useEffect, useState } from 'react'

interface CreatedCampaignCardProps {
    campaign: Campaign
    onDelete?: (id: string) => void
    onCampaignClick?: (id: string) => void
}

const CreatedCampaignCard = ({
    campaign,
    onCampaignClick,
}: CreatedCampaignCardProps): JSX.Element => {
    const [isCopied, setIsCopied] = useState<boolean>(false)
    useEffect(() => {
        if (isCopied) {
            const timeout = setTimeout(() => {
                setIsCopied(false)
            }, 2000)
            return () => clearTimeout(timeout)
        }
    }, [isCopied])

    return (
        <div
            onClick={() => onCampaignClick && onCampaignClick(campaign?.id)}
            className="p-3 rounded-lg shadow-xl flex flex-col justify-between items-start gap-3 w-[480px] h-[120px] overflow-hidden cursor-pointer"
            style={{
                background: '#FFFFFF',
            }}
        >
            <div className="flex">
                <p className="text-[#001F40] text-base font-bold mb-2 px-1 ellipsis-1 break-all">
                    {campaign?.title}
                </p>
            </div>
            <div className="flex flex-col px-1">
                <p className="text-[#001F40] text-sm">
                    {campaign?.number_of_data_sources ? (
                        <span className="text-[#54779C] text-sm">
                            {campaign?.number_of_data_sources} Data{' '}
                            {pluralizationUtil(
                                campaign?.number_of_data_sources,
                                'Source',
                                'Sources',
                            )}
                        </span>
                    ) : (
                        <span className="text-[#54779C] text-sm">0 Data Sources</span>
                    )}
                </p>
                <p className="text-[#001F40] text-sm">
                    {campaign?.words_captured ? (
                        <span className="text-[#54779C] text-sm">
                            {campaign?.words_captured.toLocaleString()}{' '}
                            {pluralizationUtil(campaign?.words_captured, 'Word', 'Words')}
                        </span>
                    ) : (
                        <span className="text-[#54779C] text-sm">0 Words</span>
                    )}
                </p>
            </div>
        </div>
    )
}

export default CreatedCampaignCard
