import { Brand } from '../types/Brand'
import { BrandPayload, ProfilePhotoPayload, ProfilePayload } from '../types/BrandPayload'
import { ProfilePhoto } from '../types/ProfilePhoto'
import ApiService from './apiService'
import { AxiosResponse } from 'axios'

export interface BrandApi {
    setBrand(payload: BrandPayload): Promise<AxiosResponse<Brand>>
    setProfilePhotos(payload: ProfilePhotoPayload): Promise<AxiosResponse<ProfilePhoto>>
    saveProfile(payload: ProfilePayload): Promise<AxiosResponse<ProfilePhoto>>
}

export const BrandApi: BrandApi = Object.freeze({
    setBrand(payload: BrandPayload): Promise<AxiosResponse<Brand>> {
        return ApiService.patch(
            `/brands`,
            {
                background_color: payload.background_color,
                background_image: payload.background_image,
                background_image_mobile: payload.background_image_mobile,
            },
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        )
    },
    setProfilePhotos(payload: ProfilePhotoPayload): Promise<AxiosResponse<ProfilePhoto>> {
        return ApiService.post(`/profile-photos`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    },
    saveProfile(payload: ProfilePayload): Promise<AxiosResponse<any>> {
        return ApiService.patch(`/users`, payload)
    },
})
