import { RagQueryParam } from '../types/RagQueryParam'
import { RagQueryResponse } from '../types/RagQueryResponse'
import ApiService from './apiService'
import { AxiosResponse } from 'axios'

export interface RagApiInterface {
    query(data: RagQueryParam): Promise<AxiosResponse<RagQueryResponse>>
}

export const RagApi: RagApiInterface = Object.freeze({
    query(data: RagQueryParam): Promise<AxiosResponse<RagQueryResponse>> {
        return ApiService.post(
            `/rag/query`,
            {
                query: data.query,
                survey_ids: data.surveyIds,
                interview_ids: data.interviewIds,
            },
            {
                timeout: 600000, // 10 minutes
            },
        )
    },
})
