import ApiService from './apiService'
import { AxiosResponse } from 'axios'

export interface ChatApiInterface {
    delete(campaignId: string): Promise<AxiosResponse<void>>
}

export const ChatApi: ChatApiInterface = Object.freeze({
    delete(campaignId: string): Promise<AxiosResponse<void>> {
        return ApiService.delete(`/rag/chat/${campaignId}`)
    },
})
