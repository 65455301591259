import { Campaign } from '../types/Campaign'
import { getColCount } from '../utils/getGridCount'
import CreatedCampaignCard from './CreatedCampaignCard'
import { useLayoutEffect, useMemo, useState } from 'react'

interface CampaignCardGridProps {
    campaigns: Campaign[]
    onDelete?: (id: string) => void
    onCampaignClick?: (id: string) => void
}

const CampaignCardGrid = ({ campaigns, onCampaignClick }: CampaignCardGridProps) => {
    const [colCount, setColCount] = useState(getColCount(window.innerWidth, 'question'))

    useLayoutEffect(() => {
        const onResize = () => {
            setColCount(getColCount(window.innerWidth, 'question'))
        }
        window.addEventListener('resize', onResize)

        return () => window.removeEventListener('resize', onResize)
    }, [])

    const matrix = useMemo(() => {
        let arr: Campaign[][] = []
        let rowCount = Math.ceil(campaigns.length / colCount)

        for (let i = 0; i < colCount; i++) {
            arr[i] = []
            for (let k = 0; k < rowCount; k++) {
                i + colCount * k < campaigns.length && arr[i].push(campaigns[i + colCount * k])
            }
        }

        return arr
    }, [colCount, campaigns])

    return (
        <div className="flex">
            {matrix.map((row, index) => (
                <div key={index} className="flex flex-col flex-1 gap-6 ml-2 mr-2">
                    {row.map((campaign) => (
                        <div key={campaign.id}>
                            <CreatedCampaignCard
                                campaign={campaign}
                                onCampaignClick={onCampaignClick}
                            />
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
}

export default CampaignCardGrid
