// @ts-nocheck
import { setInterviewIdsAction, setSurveyIdsAction, setRagSourcePageAction } from './actions'
import { initialRagStoreState, ragReducer } from './reducer'
import { createContext, useContext, useReducer } from 'react'

export const RagStore = createContext(null)

export const useRagStore = (): any => useContext(RagStore)

export const RagStoreProvider = ({ children }): any => {
    const [state, dispatch] = useReducer(ragReducer, initialRagStoreState)

    const setInterviewIds = (interviewIds: string[]) => {
        setInterviewIdsAction(interviewIds, dispatch)
    }

    const setSurveyIds = (surveyIds: string[]) => {
        setSurveyIdsAction(surveyIds, dispatch)
    }

    const setRagSourcePage = (sourcePage: string) => {
        setRagSourcePageAction(sourcePage, dispatch)
    }

    return (
        <RagStore.Provider
            value={{
                state: state,
                setInterviewIds,
                setSurveyIds,
                setRagSourcePage,
            }}
        >
            {children}
        </RagStore.Provider>
    )
}
