import { CampaignApi } from '../api/campaign'
import Spinner from '../components/Spinner'
import { CampaignType } from '../enums/CampaignType'
import {
    CampaignDataSourceItem,
    ListOfCampaignDataSourcesResponse,
} from '../types/CampaignDataSources'
import { User } from '../types/User'
import { pluralizationUtil } from '../utils/pluralizationUtil'
import CampaignDataSourceCard from './CampaignDataSourceCard'
import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { toast } from 'react-toastify'

interface CampaignDataSourcesModalProps {
    open: boolean
    user: User
    onToggle: () => void
    onSuccess: (campaignDataSources: CampaignDataSourceItem[]) => void
    campaignId?: string
    campaignType?: CampaignType | null
}

const CampaignDataSourcesModal = ({
    open,
    onToggle,
    campaignId = '',
    onSuccess,
    campaignType = null,
}: CampaignDataSourcesModalProps) => {
    const [campaignDataSourcesResponse, setCampaignDataSourcesResponse] =
        useState<ListOfCampaignDataSourcesResponse | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [scrollPosition, setScrollTopPosition] = useState<number>(0)
    const fetchCampaignDataSources = async (page: number) => {
        try {
            setLoading(true)
            let response = null
            if (campaignType) {
                response = await CampaignApi.getAllCampaignDataSourcesForCampaignIdAndCampaignType(
                    campaignId,
                    campaignType,
                    page,
                    1000,
                )
            } else {
                response = await CampaignApi.getAvailableCampaignDataSourcesForCampaignId(
                    campaignId,
                    page,
                    1000,
                )
            }
            if (response) setCampaignDataSourcesResponse(response.data)
        } catch (e) {
            setCampaignDataSourcesResponse(null)
            onToggle()
            toast.error('Error retrieving list of available data sources!', {
                className: 'z-[999999] mt-5',
            })
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (open) {
            fetchCampaignDataSources(1)
        }
    }, [open])

    const handleChangePage = (page: number) => {
        fetchCampaignDataSources(page)
    }

    const handleSelect = (id: string) => {
        setCampaignDataSourcesResponse(
            (res: ListOfCampaignDataSourcesResponse | null) =>
                ({
                    ...res,
                    data_sources: res?.data_sources?.map((item) =>
                        item.id === id ? { ...item, selected: !item.selected } : item,
                    ),
                } as ListOfCampaignDataSourcesResponse),
        )
        setTimeout(() => {
            document.getElementById('scrollableDiv')?.scrollTo(0, scrollPosition)
        }, 0)
    }

    const isSaveDisabled = false
    //campaignDataSourcesResponse?.data_sources.filter((item) => item.selected).length === 0

    return (
        <>
            {loading ? (
                <Spinner />
            ) : (
                <div
                    className={`modal-base-class fixed top-0 left-0 w-full h-full bg-[#000000c7] flex justify-end items-center z-[999999]`}
                >
                    <div
                        className={`relative h-full w-full lg:w-7/12 bg-white p-6 py-5 lg:rounded-l-xl flex flex-col z-[10] ${
                            open ? 'modal-enter' : 'modal-exit'
                        }`}
                        style={{ boxShadow: '0px 4px 4px 0px #00000040' }}
                    >
                        <div className="h-full flex flex-col">
                            <div className="flex justify-between items-center">
                                <div className="flex flex-row">
                                    <h2 className="text-[#101828] text-2xl font-semibold">
                                        Campaign Data (
                                        {campaignDataSourcesResponse?.pagination.total_items})
                                    </h2>
                                </div>

                                <button
                                    onClick={() => {
                                        setCampaignDataSourcesResponse(null)
                                        onToggle()
                                    }}
                                    className="p-2.5"
                                >
                                    <img src="/asset/close.svg" />
                                </button>
                            </div>
                            <p className="text-sm py-2">
                                Select data sources (exising surveys and conversations) to add to
                                this campaign
                            </p>
                            <div
                                className="flex-1 mt-3 mb-6 flex flex-col overflow-y-auto"
                                id="scrollableDiv"
                                onScroll={(e) => {
                                    setScrollTopPosition(e.currentTarget.scrollTop)
                                }}
                            >
                                <InfiniteScroll
                                    dataLength={
                                        campaignDataSourcesResponse?.data_sources?.length ?? 0
                                    } //This is important field to render the next data
                                    next={() =>
                                        handleChangePage(
                                            campaignDataSourcesResponse?.pagination?.next_page ?? 1,
                                        )
                                    }
                                    hasMore={
                                        campaignDataSourcesResponse?.pagination?.next_page !== null
                                    }
                                    loader={<h4>Loading...</h4>}
                                    scrollableTarget="scrollableDiv"
                                >
                                    {campaignDataSourcesResponse?.data_sources
                                        .sort((a, b) =>
                                            a.selected === b.selected ? 0 : a.selected ? -1 : 1,
                                        )
                                        .map((item: CampaignDataSourceItem) => (
                                            <CampaignDataSourceCard
                                                key={item.id}
                                                onSelect={handleSelect}
                                                campaignData={item}
                                            />
                                        ))}
                                </InfiniteScroll>
                            </div>
                            <div className="mt-2 flex items-center justify-between gap-3">
                                <div className="flex items-center gap-3">
                                    <button
                                        disabled={isSaveDisabled}
                                        className={`text-sm font-semibold flex items-center p-2 px-4 rounded-2xl border-2 text-[#FFFFFF] ${
                                            isSaveDisabled ? 'bg-[#add4ff]' : 'bg-[#007AFF]'
                                        }`}
                                        onClick={() => {
                                            onSuccess(
                                                campaignDataSourcesResponse?.data_sources
                                                    .filter((item) => item.selected)
                                                    .map((item) => ({
                                                        id: item.id,
                                                        title: item.title,
                                                        type: item.type,
                                                    })) as CampaignDataSourceItem[],
                                            )
                                        }}
                                    >
                                        Save
                                    </button>
                                    <p className="text-sm text-black">
                                        Selected{' '}
                                        {
                                            campaignDataSourcesResponse?.data_sources.filter(
                                                (item) => item.selected,
                                            ).length
                                        }{' '}
                                        data{' '}
                                        {pluralizationUtil(
                                            Number(
                                                campaignDataSourcesResponse?.data_sources.filter(
                                                    (item) => item.selected,
                                                ).length,
                                            ),
                                            'source',
                                            'sources',
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="absolute w-full h-full bg-transparent z-0"
                        onClick={() => {
                            setCampaignDataSourcesResponse(null)
                            onToggle()
                        }}
                    />
                </div>
            )}
        </>
    )
}

export default CampaignDataSourcesModal
