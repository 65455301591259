import { ToggleSwitch as ReactToggleSwitch } from 'flowbite-react'

interface ToggleSwitchProps {
    disabled?: boolean
    checked: boolean
    label: string
    onChange: (v: boolean) => void
    theme?: any
}

const ToggleSwitch = ({ disabled, checked, label, onChange, theme }: ToggleSwitchProps) => {
    return (
        <ReactToggleSwitch
            disabled={disabled}
            checked={checked}
            label={label}
            onChange={onChange}
            theme={{
                toggle: {
                    checked: {
                        color: {
                            blue: 'bg-[#007AFF] border-[#007AFF]',
                        },
                    },
                },
                ...theme,
            }}
        />
    )
}

export default ToggleSwitch
