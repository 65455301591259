import QuestionAssets from './QuestionAssets'
import { Modal } from 'flowbite-react'
import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'

interface Props {
    onConfirm: (files: File[]) => void
    onClose: () => void
}

const UploadQuestionAssets = ({ onConfirm, onClose }: Props) => {
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([])
    const [isSubmitting, setIsSubmitting] = useState(false)

    const onDrop = useCallback((acceptedFiles: File[]) => {
        setUploadedFiles(acceptedFiles)
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/*': [],
            'video/*': [],
        },
    })

    const onSubmit = () => {
        setIsSubmitting(true)
        onConfirm(uploadedFiles)
    }

    return (
        <Modal show={true} size="4xl" id="modal" className="z-[100000]">
            <div className="p-6 text-center w-full">
                {/* SVG and Dropzone code remains unchanged */}
                <div
                    {...getRootProps()}
                    className="border-dashed border-4 border-gray-600 p-10 text-center cursor-pointer"
                >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <p className="text-gray-700">Drop the files here ...</p>
                    ) : (
                        <p className="text-gray-700">
                            Drag and drop images and/or videos here OR click to select files
                            (include multiple files if needed)
                        </p>
                    )}
                </div>
                <div className="mt-4 flex w-full justify-center items-center">
                    {uploadedFiles.length > 0 && (
                        <QuestionAssets
                            assets={uploadedFiles.map((file) => {
                                return {
                                    path: URL.createObjectURL(file),
                                    type: file.type.startsWith('image') ? 'image' : 'video',
                                    name: file.name,
                                }
                            })}
                        />
                    )}
                </div>
                <div className="my-8 gap-4 flex justify-end">
                    <button
                        onClick={onClose}
                        className="text-[#BFDEFF] bg-[#007AFF] px-6 py-1 rounded-[10px]"
                    >
                        Close
                    </button>
                    <button
                        disabled={!uploadedFiles.length && isSubmitting}
                        onClick={onSubmit}
                        className={`text-[#BFDEFF] ${
                            isSubmitting ? 'bg-[#add4ff] text-[#AFAFAF]' : 'bg-[#007AFF]'
                        } px-6 py-1 rounded-[10px]`}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default UploadQuestionAssets
