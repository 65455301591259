import SelectLanguage from '../components/SelectLanguage'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const Privacy = (): JSX.Element => {
    useEffect(() => {
        document.body.style.overflowY = 'scroll'
    })

    const { t } = useTranslation()

    return (
        <div className="container overflow-scroll mx-auto mb-[100px] px-4 pt-[100px] font-lato">
            <SelectLanguage
                style={{
                    marginBottom: 16,
                    background: 'white',
                }}
            />
            <div>
                <p
                    dir="ltr"
                    style={{
                        lineHeight: '1.2',
                        textAlign: 'justify',
                        marginTop: '0pt',
                        marginBottom: '12pt',
                    }}
                >
                    <span
                        style={{
                            fontSize: '13pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 700,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.title')}
                    </span>
                </p>
                <p
                    dir="ltr"
                    style={{
                        lineHeight: '1.2',
                        textAlign: 'justify',
                        marginTop: '12pt',
                        marginBottom: '12pt',
                    }}
                >
                    <span
                        style={{
                            fontSize: '11pt',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.last_updated')}
                    </span>
                </p>
                <p dir="ltr" style={{ lineHeight: '1.2', marginTop: '12pt', marginBottom: '12pt' }}>
                    <span
                        style={{
                            fontSize: '11pt',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.subtitle_1')}
                    </span>
                </p>
                <p dir="ltr" style={{ lineHeight: '1.2', marginTop: '12pt', marginBottom: '12pt' }}>
                    <span
                        style={{
                            fontSize: '11pt',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.subtitle_2')}
                    </span>
                </p>
                <p dir="ltr" style={{ lineHeight: '1.2', marginTop: '12pt', marginBottom: '12pt' }}>
                    <span
                        style={{
                            fontSize: '11pt',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.subtitle_3')}
                    </span>
                </p>
                <p
                    dir="ltr"
                    style={{
                        lineHeight: '1.2',
                        textAlign: 'justify',
                        marginTop: '12pt',
                        marginBottom: '12pt',
                    }}
                >
                    <span
                        style={{
                            fontSize: '13pt',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 700,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.platform_information_we_collect')}
                    </span>
                </p>
                <p
                    dir="ltr"
                    style={{
                        lineHeight: '1.2',
                        backgroundColor: '#ffffff',
                        marginTop: '0pt',
                        marginBottom: '0pt',
                        padding: '0pt 0pt 12pt 0pt',
                    }}
                >
                    <span
                        style={{
                            fontSize: '11pt',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.we_collect_subtitle')}
                    </span>
                </p>
                <p
                    dir="ltr"
                    style={{
                        lineHeight: '1.2',
                        backgroundColor: '#ffffff',
                        marginTop: '0pt',
                        marginBottom: '12pt',
                    }}
                >
                    <span
                        style={{
                            fontSize: '11pt',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 700,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.information_you_provide_to_us')}
                    </span>
                </p>
                <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: 'disc',
                            fontSize: '10pt',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre',
                        }}
                        aria-level={1}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: '1.2',
                                textAlign: 'justify',
                                marginTop: '12pt',
                                marginBottom: '12pt',
                            }}
                        >
                            <span
                                style={{
                                    fontSize: '11pt',
                                    color: '#000000',
                                    backgroundColor: 'transparent',
                                    fontWeight: 700,
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    textDecoration: 'none',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {t('platform_policy_statement.contact_information_title')}
                            </span>
                            <span
                                style={{
                                    fontSize: '11pt',
                                    color: '#000000',
                                    backgroundColor: 'transparent',
                                    fontWeight: 400,
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    textDecoration: 'none',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                ,
                            </span>
                            <span
                                style={{
                                    fontSize: '11pt',
                                    color: '#000000',
                                    backgroundColor: 'transparent',
                                    fontWeight: 700,
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    textDecoration: 'none',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                &nbsp;
                            </span>
                            <span
                                style={{
                                    fontSize: '11pt',
                                    color: '#000000',
                                    backgroundColor: 'transparent',
                                    fontWeight: 400,
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    textDecoration: 'none',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {t('platform_policy_statement.contact_information_details')}
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: 'disc',
                            fontSize: '10pt',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre',
                        }}
                        aria-level={1}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: '1.2',
                                marginRight: '12pt',
                                textAlign: 'justify',
                                backgroundColor: '#ffffff',
                                marginTop: '12pt',
                                marginBottom: '12pt',
                            }}
                        >
                            <span
                                style={{
                                    fontSize: '11pt',
                                    color: '#000000',
                                    backgroundColor: 'transparent',
                                    fontWeight: 700,
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    textDecoration: 'none',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {t('platform_policy_statement.account_information_title')}
                            </span>
                            <span
                                style={{
                                    fontSize: '11pt',
                                    color: '#000000',
                                    backgroundColor: 'transparent',
                                    fontWeight: 400,
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    textDecoration: 'none',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                , {t('platform_policy_statement.account_information_details')}
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: 'disc',
                            fontSize: '10pt',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre',
                        }}
                        aria-level={1}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: '1.2',
                                textAlign: 'justify',
                                marginTop: '12pt',
                                marginBottom: '12pt',
                            }}
                        >
                            <span
                                style={{
                                    fontSize: '11pt',
                                    color: '#000000',
                                    backgroundColor: 'transparent',
                                    fontWeight: 700,
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    textDecoration: 'none',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {t('platform_policy_statement.communication_title')}&nbsp;
                            </span>
                            <span
                                style={{
                                    fontSize: '11pt',
                                    color: '#000000',
                                    backgroundColor: 'transparent',
                                    fontWeight: 400,
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    textDecoration: 'none',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {t('platform_policy_statement.communication_details')}
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: 'disc',
                            fontSize: '11pt',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre',
                        }}
                        aria-level={1}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: '1.2',
                                textAlign: 'justify',
                                marginTop: '12pt',
                                marginBottom: '12pt',
                            }}
                        >
                            <span
                                style={{
                                    fontSize: '11pt',
                                    color: '#000000',
                                    backgroundColor: 'transparent',
                                    fontWeight: 700,
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    textDecoration: 'none',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {t(
                                    'platform_policy_statement.voice_recordings_and_transcripts_title',
                                )}
                                &nbsp;
                            </span>
                            <span
                                style={{
                                    fontSize: '11pt',
                                    color: '#000000',
                                    backgroundColor: 'transparent',
                                    fontWeight: 400,
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    textDecoration: 'none',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {t(
                                    'platform_policy_statement.voice_recordings_and_transcripts_details',
                                )}
                                &nbsp;
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: 'disc',
                            fontSize: '11pt',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre',
                        }}
                        aria-level={1}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: '1.2',
                                textAlign: 'justify',
                                marginTop: '12pt',
                                marginBottom: '12pt',
                            }}
                        >
                            <span
                                style={{
                                    fontSize: '11pt',
                                    color: '#000000',
                                    backgroundColor: 'transparent',
                                    fontWeight: 700,
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    textDecoration: 'none',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {t('platform_policy_statement.usage_information_title')}
                            </span>
                            <span
                                style={{
                                    fontSize: '11pt',
                                    color: '#000000',
                                    backgroundColor: 'transparent',
                                    fontWeight: 400,
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    textDecoration: 'none',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {' '}
                                {t('platform_policy_statement.usage_information_details')} &nbsp;
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: 'disc',
                            fontSize: '11pt',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre',
                        }}
                        aria-level={1}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: '1.2',
                                textAlign: 'justify',
                                marginTop: '12pt',
                                marginBottom: '12pt',
                            }}
                        >
                            <span
                                style={{
                                    fontSize: '11pt',
                                    color: '#000000',
                                    backgroundColor: 'transparent',
                                    fontWeight: 700,
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    textDecoration: 'none',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {t('platform_policy_statement.other_information_title')}
                            </span>
                            <span
                                style={{
                                    fontSize: '11pt',
                                    color: '#000000',
                                    backgroundColor: 'transparent',
                                    fontWeight: 400,
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    textDecoration: 'none',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                &nbsp;{t('platform_policy_statement.other_information_details')}
                            </span>
                        </p>
                    </li>
                </ul>
                <p
                    dir="ltr"
                    style={{
                        lineHeight: '1.2',
                        textAlign: 'justify',
                        marginTop: '12pt',
                        marginBottom: '12pt',
                    }}
                >
                    <span
                        style={{
                            fontSize: '11pt',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 700,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.automatic_data_collection')}
                    </span>
                    <span
                        style={{
                            fontSize: '11pt',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        &nbsp;{t('platform_policy_statement.automatic_data_collection_details')}
                    </span>
                </p>
                <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: 'disc',
                            fontSize: '10pt',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre',
                        }}
                        aria-level={1}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: '1.2',
                                textAlign: 'justify',
                                marginTop: '12pt',
                                marginBottom: '12pt',
                            }}
                        >
                            <span
                                style={{
                                    fontSize: '11pt',
                                    color: '#000000',
                                    backgroundColor: 'transparent',
                                    fontWeight: 700,
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    textDecoration: 'none',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {t('platform_policy_statement.device_data')}
                            </span>
                            <span
                                style={{
                                    fontSize: '11pt',
                                    color: '#000000',
                                    backgroundColor: 'transparent',
                                    fontWeight: 400,
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    textDecoration: 'none',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                , {t('platform_policy_statement.device_data_details')}
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: 'disc',
                            fontSize: '10pt',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre',
                        }}
                        aria-level={1}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: '1.2',
                                textAlign: 'justify',
                                marginTop: '12pt',
                                marginBottom: '12pt',
                            }}
                        >
                            <span
                                style={{
                                    fontSize: '11pt',
                                    color: '#000000',
                                    backgroundColor: 'transparent',
                                    fontWeight: 700,
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    textDecoration: 'none',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {t('platform_policy_statement.online_activity_data')}
                            </span>
                            <span
                                style={{
                                    fontSize: '11pt',
                                    color: '#000000',
                                    backgroundColor: 'transparent',
                                    fontWeight: 400,
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    textDecoration: 'none',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                , {t('platform_policy_statement.online_activity_data_details')}
                            </span>
                        </p>
                    </li>
                </ul>
                <p
                    dir="ltr"
                    style={{
                        lineHeight: '1.2',
                        marginRight: '12pt',
                        backgroundColor: '#ffffff',
                        marginTop: '12pt',
                        marginBottom: '12pt',
                    }}
                >
                    <span
                        style={{
                            fontSize: '11pt',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 700,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t(
                            'platform_policy_statement.we_use_the_following_tools_for_automatic_data_collection',
                        )}
                    </span>
                </p>
                <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: 'disc',
                            fontSize: '10pt',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre',
                        }}
                        aria-level={1}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: '1.2',
                                marginRight: '12pt',
                                textAlign: 'justify',
                                backgroundColor: '#ffffff',
                                marginTop: '12pt',
                                marginBottom: '0pt',
                                padding: '0pt 0pt 12pt 0pt',
                            }}
                        >
                            <span
                                style={{
                                    fontSize: '11pt',
                                    color: '#000000',
                                    backgroundColor: 'transparent',
                                    fontWeight: 700,
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    textDecoration: 'none',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {t('platform_policy_statement.cookies')}
                            </span>
                            <span
                                style={{
                                    fontSize: '11pt',
                                    color: '#000000',
                                    backgroundColor: 'transparent',
                                    fontWeight: 400,
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    textDecoration: 'none',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                , {t('platform_policy_statement.cookies_details')}
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: 'disc',
                            fontSize: '10pt',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre',
                        }}
                        aria-level={1}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: '1.2',
                                marginRight: '12pt',
                                textAlign: 'justify',
                                backgroundColor: '#ffffff',
                                marginTop: '0pt',
                                marginBottom: '0pt',
                                padding: '0pt 0pt 12pt 0pt',
                            }}
                        >
                            <span
                                style={{
                                    fontSize: '11pt',
                                    color: '#000000',
                                    backgroundColor: 'transparent',
                                    fontWeight: 700,
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    textDecoration: 'none',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {t('platform_policy_statement.local_storage_technologies')}
                            </span>
                            <span
                                style={{
                                    fontSize: '11pt',
                                    color: '#000000',
                                    backgroundColor: 'transparent',
                                    fontWeight: 400,
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    textDecoration: 'none',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                ,{' '}
                                {t('platform_policy_statement.local_storage_technologies_details')}
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: 'disc',
                            fontSize: '10pt',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre',
                        }}
                        aria-level={1}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: '1.2',
                                marginRight: '12pt',
                                textAlign: 'justify',
                                backgroundColor: '#ffffff',
                                marginTop: '0pt',
                                marginBottom: '12pt',
                            }}
                        >
                            <span
                                style={{
                                    fontSize: '11pt',
                                    color: '#000000',
                                    backgroundColor: 'transparent',
                                    fontWeight: 700,
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    textDecoration: 'none',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {t('platform_policy_statement.web_beacons')}
                            </span>
                            <span
                                style={{
                                    fontSize: '11pt',
                                    color: '#000000',
                                    backgroundColor: 'transparent',
                                    fontWeight: 400,
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    textDecoration: 'none',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                , {t('platform_policy_statement.web_beacons_details')}
                            </span>
                        </p>
                    </li>
                </ul>
                <p
                    dir="ltr"
                    style={{
                        lineHeight: '1.2',
                        textAlign: 'justify',
                        marginTop: '12pt',
                        marginBottom: '12pt',
                    }}
                >
                    <span
                        style={{
                            fontSize: '13pt',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 700,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.how_we_use_personal_information')}
                    </span>
                </p>
                <p
                    dir="ltr"
                    style={{
                        lineHeight: '1.2',
                        textAlign: 'justify',
                        marginTop: '12pt',
                        marginBottom: '12pt',
                    }}
                >
                    <span
                        style={{
                            fontSize: '11pt',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.how_we_use_personal_information_details')}
                    </span>
                </p>
                <p
                    dir="ltr"
                    style={{
                        lineHeight: '1.295',
                        backgroundColor: '#ffffff',
                        marginTop: '0pt',
                        marginBottom: '12pt',
                    }}
                >
                    <span
                        style={{
                            fontSize: '11pt',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 700,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.to_operate_our_services')}
                    </span>
                </p>
                <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: 'disc',
                            fontSize: '10pt',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre',
                        }}
                        aria-level={1}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: '1.2',
                                backgroundColor: '#ffffff',
                                marginTop: '12pt',
                                marginBottom: '0pt',
                                padding: '0pt 0pt 12pt 0pt',
                            }}
                        >
                            <span
                                style={{
                                    fontSize: '11pt',

                                    color: '#000000',
                                    backgroundColor: 'transparent',
                                    fontWeight: 400,
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    textDecoration: 'none',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {t('platform_policy_statement.to_operate_our_services_item_1')}
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: 'disc',
                            fontSize: '10pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre',
                        }}
                        aria-level={1}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: '1.2',
                                backgroundColor: '#ffffff',
                                marginTop: '0pt',
                                marginBottom: '0pt',
                                padding: '0pt 0pt 12pt 0pt',
                            }}
                        >
                            <span
                                style={{
                                    fontSize: '11pt',

                                    color: '#000000',
                                    backgroundColor: 'transparent',
                                    fontWeight: 400,
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    textDecoration: 'none',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {t('platform_policy_statement.to_operate_our_services_item_2')}
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: 'disc',
                            fontSize: '10pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre',
                        }}
                        aria-level={1}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: '1.2',
                                backgroundColor: '#ffffff',
                                marginTop: '0pt',
                                marginBottom: '0pt',
                                padding: '0pt 0pt 12pt 0pt',
                            }}
                        >
                            <span
                                style={{
                                    fontSize: '11pt',

                                    color: '#000000',
                                    backgroundColor: 'transparent',
                                    fontWeight: 400,
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    textDecoration: 'none',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {t('platform_policy_statement.to_operate_our_services_item_3')}
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: 'disc',
                            fontSize: '10pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre',
                        }}
                        aria-level={1}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: '1.2',
                                backgroundColor: '#ffffff',
                                marginTop: '0pt',
                                marginBottom: '0pt',
                                padding: '0pt 0pt 12pt 0pt',
                            }}
                        >
                            <span
                                style={{
                                    fontSize: '11pt',

                                    color: '#000000',
                                    backgroundColor: 'transparent',
                                    fontWeight: 400,
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    textDecoration: 'none',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {t('platform_policy_statement.to_operate_our_services_item_4')}
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: 'disc',
                            fontSize: '10pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre',
                        }}
                        aria-level={1}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: '1.2',
                                backgroundColor: '#ffffff',
                                marginTop: '0pt',
                                marginBottom: '12pt',
                            }}
                        >
                            <span
                                style={{
                                    fontSize: '11pt',

                                    color: '#000000',
                                    backgroundColor: 'transparent',
                                    fontWeight: 400,
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    textDecoration: 'none',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {t('platform_policy_statement.to_operate_our_services_item_5')}
                            </span>
                        </p>
                    </li>
                </ul>
                <p
                    dir="ltr"
                    style={{
                        lineHeight: '1.295',
                        textAlign: 'justify',
                        backgroundColor: '#ffffff',
                        marginTop: '12pt',
                        marginBottom: '12pt',
                    }}
                >
                    <span
                        style={{
                            fontSize: '11pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 700,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.research_and_development')}
                    </span>
                    <span
                        style={{
                            fontSize: '11pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        &nbsp;{t('platform_policy_statement.research_and_development_details')}
                    </span>
                </p>
                <p
                    dir="ltr"
                    style={{
                        lineHeight: '1.295',
                        marginRight: '12pt',
                        textAlign: 'justify',
                        backgroundColor: '#ffffff',
                        marginTop: '12pt',
                        marginBottom: '12pt',
                    }}
                >
                    <span
                        style={{
                            fontSize: '11pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 700,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.compliance_and_protection')}&nbsp;
                    </span>
                    <span
                        style={{
                            fontSize: '11pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.including_to')}
                    </span>
                </p>
                <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: 'disc',
                            fontSize: '10pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre',
                        }}
                        aria-level={1}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: '1.2',
                                backgroundColor: '#ffffff',
                                marginTop: '12pt',
                                marginBottom: '0pt',
                                padding: '0pt 0pt 12pt 0pt',
                            }}
                        >
                            <span
                                style={{
                                    fontSize: '11pt',

                                    color: '#000000',
                                    backgroundColor: 'transparent',
                                    fontWeight: 400,
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    textDecoration: 'none',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {t('platform_policy_statement.compliance_and_protection_item_1')}
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: 'disc',
                            fontSize: '10pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre',
                        }}
                        aria-level={1}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: '1.2',
                                backgroundColor: '#ffffff',
                                marginTop: '0pt',
                                marginBottom: '0pt',
                                padding: '0pt 0pt 12pt 0pt',
                            }}
                        >
                            <span
                                style={{
                                    fontSize: '11pt',

                                    color: '#000000',
                                    backgroundColor: 'transparent',
                                    fontWeight: 400,
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    textDecoration: 'none',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {t('platform_policy_statement.compliance_and_protection_item_2')}
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: 'disc',
                            fontSize: '10pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre',
                        }}
                        aria-level={1}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: '1.2',
                                backgroundColor: '#ffffff',
                                marginTop: '0pt',
                                marginBottom: '0pt',
                                padding: '0pt 0pt 12pt 0pt',
                            }}
                        >
                            <span
                                style={{
                                    fontSize: '11pt',

                                    color: '#000000',
                                    backgroundColor: 'transparent',
                                    fontWeight: 400,
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    textDecoration: 'none',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {t('platform_policy_statement.compliance_and_protection_item_3')}
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: 'disc',
                            fontSize: '10pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre',
                        }}
                        aria-level={1}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: '1.2',
                                backgroundColor: '#ffffff',
                                marginTop: '0pt',
                                marginBottom: '0pt',
                                padding: '0pt 0pt 12pt 0pt',
                            }}
                        >
                            <span
                                style={{
                                    fontSize: '11pt',

                                    color: '#000000',
                                    backgroundColor: 'transparent',
                                    fontWeight: 400,
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    textDecoration: 'none',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {t('platform_policy_statement.compliance_and_protection_item_4')}
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: 'disc',
                            fontSize: '10pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre',
                        }}
                        aria-level={1}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: '1.2',
                                backgroundColor: '#ffffff',
                                marginTop: '0pt',
                                marginBottom: '12pt',
                            }}
                        >
                            <span
                                style={{
                                    fontSize: '11pt',

                                    color: '#000000',
                                    backgroundColor: 'transparent',
                                    fontWeight: 400,
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    textDecoration: 'none',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {t('platform_policy_statement.compliance_and_protection_item_5')}
                            </span>
                        </p>
                    </li>
                </ul>
                <p
                    dir="ltr"
                    style={{
                        lineHeight: '1.2',
                        textAlign: 'justify',
                        marginTop: '12pt',
                        marginBottom: '12pt',
                    }}
                >
                    <span
                        style={{
                            fontSize: '13pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 700,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.how_do_we_disclose_personal_information')}
                    </span>
                </p>
                <p
                    dir="ltr"
                    style={{
                        lineHeight: '1.2',
                        textAlign: 'justify',
                        marginTop: '12pt',
                        marginBottom: '12pt',
                    }}
                >
                    <span
                        style={{
                            fontSize: '11pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t(
                            'platform_policy_statement.how_do_we_disclose_personal_information_details',
                        )}
                    </span>
                </p>
                <p
                    dir="ltr"
                    style={{
                        lineHeight: '1.2',
                        textAlign: 'justify',
                        marginTop: '12pt',
                        marginBottom: '12pt',
                    }}
                >
                    <span
                        style={{
                            fontSize: '11pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 700,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.nodeios_enterprise_customers')}
                    </span>
                    <span
                        style={{
                            fontSize: '11pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        &nbsp;{t('platform_policy_statement.nodeios_enterprise_customers_info')}
                    </span>
                </p>
                <p
                    dir="ltr"
                    style={{
                        lineHeight: '1.2',
                        textAlign: 'justify',
                        marginTop: '12pt',
                        marginBottom: '12pt',
                    }}
                >
                    <span
                        style={{
                            fontSize: '11pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 700,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.service_providers')}&nbsp;
                    </span>
                    <span
                        style={{
                            fontSize: '11pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.service_providers_info')}
                    </span>
                </p>
                <p
                    dir="ltr"
                    style={{
                        lineHeight: '1.2',
                        textAlign: 'justify',
                        marginTop: '12pt',
                        marginBottom: '12pt',
                    }}
                >
                    <span
                        style={{
                            fontSize: '11pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 700,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.professional_advisors')}&nbsp;
                    </span>
                    <span
                        style={{
                            fontSize: '11pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.professional_advisors_info')}
                    </span>
                </p>
                <p
                    dir="ltr"
                    style={{
                        lineHeight: '1.2',
                        textAlign: 'justify',
                        marginTop: '12pt',
                        marginBottom: '12pt',
                    }}
                >
                    <span
                        style={{
                            fontSize: '11pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 700,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.authorities_and_others')}&nbsp;
                    </span>
                    <span
                        style={{
                            fontSize: '11pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.authorities_and_others_info')}
                    </span>
                </p>
                <p
                    dir="ltr"
                    style={{
                        lineHeight: '1.2',
                        textAlign: 'justify',
                        marginTop: '12pt',
                        marginBottom: '12pt',
                    }}
                >
                    <span
                        style={{
                            fontSize: '11pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 700,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.business_transfers')}
                    </span>
                    <span
                        style={{
                            fontSize: '11pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        &nbsp;{t('platform_policy_statement.business_transfers_info')}
                    </span>
                </p>
                <p
                    dir="ltr"
                    style={{
                        lineHeight: '1.2',
                        textAlign: 'justify',
                        marginTop: '12pt',
                        marginBottom: '12pt',
                    }}
                >
                    <span
                        style={{
                            fontSize: '13pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 700,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.your_rights_choices')}
                    </span>
                </p>
                <p
                    dir="ltr"
                    style={{
                        lineHeight: '1.2',
                        textAlign: 'justify',
                        backgroundColor: '#ffffff',
                        marginTop: '0pt',
                        marginBottom: '0pt',
                        padding: '0pt 0pt 12pt 0pt',
                    }}
                >
                    <span
                        style={{
                            fontSize: '11pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 700,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.data_subject_rights')}&nbsp;
                    </span>
                    <span
                        style={{
                            fontSize: '11pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.data_subject_rights_info')}
                    </span>
                </p>
                <p
                    dir="ltr"
                    style={{
                        lineHeight: '1.2',
                        textAlign: 'justify',
                        backgroundColor: '#ffffff',
                        marginTop: '0pt',
                        marginBottom: '0pt',
                        padding: '0pt 0pt 12pt 0pt',
                    }}
                >
                    <span
                        style={{
                            fontSize: '11pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 700,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.cookies')}.
                    </span>
                    <span
                        style={{
                            fontSize: '11pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        &nbsp;{t('platform_policy_statement.cookies_info')} www.allaboutcookies.org.
                    </span>
                </p>
                <p
                    dir="ltr"
                    style={{
                        lineHeight: '1.2',
                        textAlign: 'justify',
                        backgroundColor: '#ffffff',
                        marginTop: '0pt',
                        marginBottom: '0pt',
                        padding: '0pt 0pt 12pt 0pt',
                    }}
                >
                    <span
                        style={{
                            fontSize: '11pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 700,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.do_not_track')}&nbsp;
                    </span>
                    <span
                        style={{
                            fontSize: '11pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.do_not_track_info')}&nbsp;
                    </span>
                    <a href="http://www.allaboutdnt.com" style={{ textDecoration: 'none' }}>
                        <span
                            style={{
                                fontSize: '11pt',

                                color: '#0563c1',
                                backgroundColor: 'transparent',
                                fontWeight: 400,
                                fontStyle: 'normal',
                                fontVariant: 'normal',
                                textDecoration: 'underline',
                                WebkitTextDecorationSkip: 'none',
                                textDecorationSkipInk: 'none',
                                verticalAlign: 'baseline',
                                whiteSpace: 'pre-wrap',
                            }}
                        >
                            http://www.allaboutdnt.com
                        </span>
                    </a>
                    <span
                        style={{
                            fontSize: '11pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        .
                    </span>
                </p>
                <p
                    dir="ltr"
                    style={{
                        lineHeight: '1.2',
                        textAlign: 'justify',
                        marginTop: '12pt',
                        marginBottom: '12pt',
                    }}
                >
                    <span
                        style={{
                            fontSize: '13pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 700,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.third_party_ai_technology')}
                    </span>
                </p>
                <p
                    dir="ltr"
                    style={{
                        lineHeight: '1.2',
                        textAlign: 'justify',
                        marginTop: '12pt',
                        marginBottom: '12pt',
                    }}
                >
                    <span
                        style={{
                            fontSize: '11pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.third_party_ai_technology_info')}
                    </span>
                </p>
                <p
                    dir="ltr"
                    style={{
                        lineHeight: '1.2',
                        textAlign: 'justify',
                        marginTop: '12pt',
                        marginBottom: '12pt',
                    }}
                >
                    <span
                        style={{
                            fontSize: '13pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 700,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.other_sites_and_services')}
                    </span>
                </p>
                <p
                    dir="ltr"
                    style={{
                        lineHeight: '1.2',
                        textAlign: 'justify',
                        marginTop: '12pt',
                        marginBottom: '12pt',
                    }}
                >
                    <span
                        style={{
                            fontSize: '11pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.other_sites_and_services_info')}
                    </span>
                </p>
                <p
                    dir="ltr"
                    style={{
                        lineHeight: '1.2',
                        textAlign: 'justify',
                        marginTop: '12pt',
                        marginBottom: '12pt',
                    }}
                >
                    <span
                        style={{
                            fontSize: '13pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 700,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.security')}
                    </span>
                </p>
                <p
                    dir="ltr"
                    style={{
                        lineHeight: '1.2',
                        textAlign: 'justify',
                        marginTop: '12pt',
                        marginBottom: '12pt',
                    }}
                >
                    <span
                        style={{
                            fontSize: '11pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.security_info')}
                    </span>
                </p>
                <p
                    dir="ltr"
                    style={{
                        lineHeight: '1.2',
                        textAlign: 'justify',
                        marginTop: '12pt',
                        marginBottom: '12pt',
                    }}
                >
                    <span
                        style={{
                            fontSize: '13pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 700,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.how_to_contact_us')}
                    </span>
                </p>
                <p
                    dir="ltr"
                    style={{
                        lineHeight: '1.2',
                        textAlign: 'justify',
                        marginTop: '12pt',
                        marginBottom: '12pt',
                    }}
                >
                    <span
                        style={{
                            fontSize: '11pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 700,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {t('platform_policy_statement.contact_us')}
                    </span>
                    <span
                        style={{
                            fontSize: '11pt',

                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        . {t('platform_policy_statement.contact_us_info')}
                        <a href="mailto:hello@nodeio.xyz"> hello@nodeio.xyz</a>.
                    </span>
                </p>
            </div>
        </div>
    )
}
export default Privacy
