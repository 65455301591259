export interface CreateNodeioPayload {
    title: string
    responding_to_question_id?: string
    request_id?: string
    client: Client.WEB_APP
    first_name?: string
    last_name?: string
    email?: string
    phone_number?: string
}

export enum Client {
    WEB_APP = 'web-app',
}
