import types, { RagAction } from './types'

interface RagState {
    surveyIds: string[]
    interviewIds: string[]
    sourcePage: string
}

export const initialRagStoreState: RagState = {
    surveyIds: [],
    interviewIds: [],
    sourcePage: '',
}

export const ragReducer = (state = initialRagStoreState, action: RagAction): RagState => {
    switch (action.type) {
        case types.SET_SURVEY_IDS:
            return {
                ...state,
                surveyIds: action.payload.surveyIds,
            }
        case types.SET_INTERVIEW_IDS:
            return {
                ...state,
                interviewIds: action.payload.interviewIds,
            }
        case types.SET_RAG_SOURCE_PAGE:
            return {
                ...state,
                sourcePage: action.payload.sourcePage,
            }
        default:
            return state
    }
}
