import { CountryCode } from '../types/CountryCode'
import ApiService from './apiService'
import { AxiosResponse } from 'axios'

export interface CountryCodeApi {
    getCountryCodes(): Promise<AxiosResponse<CountryCode[]>>
}

export const CountryCodeApi: CountryCodeApi = Object.freeze({
    getCountryCodes(): Promise<AxiosResponse<CountryCode[]>> {
        return ApiService.get(`/country-codes`)
    },
})
