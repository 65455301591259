/* eslint-disable indent */
import { CreatedQuestionsResponse } from '../../types/CreatedQuestionsResponse'
import { Question } from '../../types/Question'
import types, { QuestionAction } from './types'
import * as _ from 'lodash'

interface QuestionState {
    tooltipHover: boolean
    linkTitle: string
    createdQuestions?: CreatedQuestionsResponse | null
    questions?: Question[]
    accepted: boolean
    showTip: boolean
    openFooter: boolean
    isQuestionResponderLanded: boolean
}

export const initialQuestionStoreState: QuestionState = {
    tooltipHover: false,
    linkTitle: '',
    createdQuestions: null,
    questions: [],
    accepted: false,
    showTip: true,
    openFooter: true,
    isQuestionResponderLanded: true,
}

export const questionReducer = (
    state = initialQuestionStoreState,
    action: QuestionAction,
): QuestionState => {
    switch (action.type) {
        case types.SET_TOOLTIP_HOVER:
            return {
                ...state,
                tooltipHover: action.payload.status,
            }
        case types.SET_CREATED_QUESTIONS:
            return {
                ...state,
                createdQuestions: action.payload.data,
            }
        case types.SET_LINK_TITLE:
            return {
                ...state,
                linkTitle: action.payload.linkTitle,
            }
        case types.SET_QUESTIONS:
            return {
                ...state,
                questions: _.cloneDeep(action.payload.questions),
            }
        case types.SET_ACCEPTED:
            return {
                ...state,
                accepted: action.payload.accepted,
            }
        case types.SET_SHOW_TIP:
            return {
                ...state,
                showTip: action.payload.showTip,
            }
        case types.SET_OPEN_FOOTER:
            return {
                ...state,
                openFooter: action.payload.openFooter,
            }
        case types.SET_QUESTION_RESPONDER_LANDED:
            return {
                ...state,
                isQuestionResponderLanded: action.payload.questionResponderLanded,
            }
        default:
            return state
    }
}
