import { Language } from '../pages/QuestionResponder'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface LanguageElement {
    name: string
    code: Language
}

interface SelectLanguageProps {
    className?: string
    style?: React.CSSProperties
}

const SelectLanguage = ({ className, style }: SelectLanguageProps): JSX.Element => {
    const { i18n } = useTranslation()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    const languages: LanguageElement[] = [
        {
            name: 'English',
            code: Language.ENGLISH,
        },
        {
            name: 'Español',
            code: Language.SPANISH,
        },
        {
            name: 'Français',
            code: Language.FRENCH,
        },
        {
            name: 'Deutsch',
            code: Language.GERMAN,
        },
        {
            name: 'Italiano',
            code: Language.ITALIAN,
        },
        {
            name: '简体中文',
            code: Language.CHINESE,
        },
        {
            name: '日本語',
            code: Language.JAPANESE,
        },
        {
            name: '한국어',
            code: Language.KOREAN,
        },
        {
            name: 'हिंदी',
            code: Language.HINDI,
        },
        {
            name: 'Kreyòl Ayisyen',
            code: Language.HAITIAN_CREOLE,
        },
    ]

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const handleLanguageChange = (code: string) => {
        i18n.changeLanguage(code as Language)
    }

    const renderDropdown = () => (
        <div className="flex flex-row gap-6 justify-center items-center">
            <button
                key={Language.ENGLISH}
                onClick={() => handleLanguageChange(Language.ENGLISH)}
                className={`font-lato text-sm sm:text-base leading-4 ${
                    i18n.language === Language.ENGLISH ? 'text-[#007AFF]' : 'text-[#001F40]'
                } `}
            >
                English
            </button>
            <button
                key={Language.SPANISH}
                onClick={() => handleLanguageChange(Language.SPANISH)}
                className={`font-lato text-sm sm:text-base leading-4 ${
                    i18n.language === Language.SPANISH ? 'text-[#007AFF]' : 'text-[#001F40]'
                } `}
            >
                Español
            </button>
            <button
                key={Language.FRENCH}
                onClick={() => handleLanguageChange(Language.FRENCH)}
                className={`font-lato text-sm sm:text-base leading-4 ${
                    i18n.language === Language.FRENCH ? 'text-[#007AFF]' : 'text-[#001F40]'
                } `}
            >
                Français
            </button>
            <select
                onChange={(e) => handleLanguageChange(e.target.value)}
                value={i18n.language}
                className="flex flex-row justify-center bg-[#e4f1ff] border-none font-lato text-sm sm:text-base"
            >
                {[
                    {
                        name: 'Deutsch',
                        code: Language.GERMAN,
                    },
                    {
                        name: 'Italiano',
                        code: Language.ITALIAN,
                    },
                    {
                        name: '简体中文',
                        code: Language.CHINESE,
                    },
                    {
                        name: '日本語',
                        code: Language.JAPANESE,
                    },
                    {
                        name: '한국어',
                        code: Language.KOREAN,
                    },
                    {
                        name: 'हिंदी',
                        code: Language.HINDI,
                    },
                    {
                        name: 'Kreyòl Ayisyen',
                        code: Language.HAITIAN_CREOLE,
                    },
                ].map((language) => (
                    <option key={language.code} value={language.code}>
                        {language.name}
                    </option>
                ))}
            </select>
        </div>
    )

    const renderButtons = () =>
        languages.map((language) => (
            <button
                key={language.code}
                onClick={() => handleLanguageChange(language.code)}
                className={`font-lato text-[13px] leading-4 ${
                    i18n.language === language.code ? 'text-[#007AFF]' : 'text-[#001F40]'
                } `}
            >
                {language.name}
            </button>
        ))

    return (
        <div
            className={`w-full py-4 bg-[#e4f1ff] pr-0 sm:pr-[50px] flex flex-row justify-end gap-6 z-10 ${className}`}
            style={style}
        >
            {windowWidth <= 768 ? renderDropdown() : renderButtons()}
        </div>
    )
}

export default SelectLanguage
