export enum QuestionTypeSettings {
    VOICE_ONLY = 'VOICE_ONLY',
    VOICE_OR_TEXT = 'VOICE_OR_TEXT',
    FREE_TEXT = 'FREE_TEXT',
    MULTI_SELECT = 'MULTI_SELECT',
    SINGLE_SELECT = 'SINGLE_SELECT',
    NO_RESPONSE = 'NO_RESPONSE',
    VIDEO_ONLY = 'VIDEO_ONLY',
    VIDEO_OR_AUDIO_OR_TEXT = 'VIDEO_OR_AUDIO_OR_TEXT',
}

export interface QuestionTypesType {
    id: QuestionTypeSettings
    icon: string
    title: string
    color: string
    tooltip: string
}
