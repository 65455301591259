import { QuestionApi } from '../api/question'
import FreeTextResponder from '../components/FreeTextResponder'
import MultipleSelectResponder from '../components/MultipleSelectResponder'
import NextQuestionButton from '../components/NextQuestionButton'
import PreviousQuestionButton from '../components/PreviousQuestionButton'
import QuestionAssets from '../components/QuestionAssets'
import SingleSelectResponder from '../components/SingleSelectResponder'
import Spinner from '../components/Spinner'
import ToggleSwitch from '../components/ToggleSwitch'
import UploadQuestionAssets from '../components/UploadQuestionAssets'
import VideoOnlyResponder from '../components/VideoOnlyResponder'
import VideoVoiceTextResponder from '../components/VideoVoiceTextResponder'
import VoiceOnlyResponder from '../components/VoiceOnlyResponder'
import VoiceTextResponder from '../components/VoiceTextResponder'
import { useRagStore } from '../context/rag/store'
import { useUserStore } from '../context/user/store'
import { CollectCampaignTypeOption, CollectNameOption } from '../enums/CollectNameOption'
import { Mandatory } from '../enums/Mandatory'
import { Page } from '../enums/Page'
import { Question } from '../types/Question'
import { QuestionTypeSettings, QuestionTypesType } from '../types/QuestionType'
import { questionTypes } from '../utils/constant'
import { Tooltip } from 'flowbite-react'
import { useEffect, useRef, useState } from 'react'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'
import TextareaAutosize from 'react-textarea-autosize'
import { v4 as uuid } from 'uuid'

interface EditLinkQuestionCardProps {
    selectedIndex: number
    totalQuestions: number
    question: Question
    onQuestionChange: (question: string) => void
    onHelpTextChange: (help_text: string) => void
    onQuestionIndexChange: (index: number) => void
    onIsRequiredChange: () => void
    onIsHiddenChange: () => void
    onShowNotesChange: () => void
    isSeIsHiddenDisabled: boolean
    setQuestions: (questions: Partial<Question>[]) => void
    questions: Partial<Question>[]
    onUploadQuestionAssets: (files: File[]) => void
}

export const EditLinkQuestionCard = ({
    selectedIndex,
    totalQuestions,
    question,
    onQuestionChange,
    onHelpTextChange,
    onQuestionIndexChange,
    onIsRequiredChange,
    onIsHiddenChange,
    onShowNotesChange,
    isSeIsHiddenDisabled,
    questions,
    setQuestions,
    onUploadQuestionAssets,
}: EditLinkQuestionCardProps): JSX.Element => {
    const showNextQuestionButton = selectedIndex < totalQuestions - 1
    const showPreviousQuestionButton = selectedIndex > 0
    const ref = useRef<any>(null)
    const [showUploadQuestionAssets, setShowUploadQuestionAssets] = useState<boolean>(false)
    const [showSpinner, setShowSpinner] = useState<boolean>(false)
    const { setInterviewIds, setSurveyIds, setRagSourcePage } = useRagStore()

    useEffect(() => {
        ref?.current?.focus()
    }, [selectedIndex])

    useEffect(() => {
        setSurveyIds([])
        setInterviewIds([])
        setRagSourcePage('EDIT_QUESTIONS_PAGE')
    }, [])

    if (selectedIndex === -1) return <></>
    if (selectedIndex === -2) return <></>

    return (
        <div className="flex flex-col p-5 xs:p-10 h-full">
            <div>{showSpinner && <Spinner />}</div>
            {showUploadQuestionAssets && (
                <UploadQuestionAssets
                    onConfirm={async (files: File[]) => {
                        setShowUploadQuestionAssets(false)
                        onUploadQuestionAssets(files)
                    }}
                    onClose={() => setShowUploadQuestionAssets(false)}
                />
            )}
            <TextareaAutosize
                ref={ref}
                className="text-[#001F40] placeholder-[#8E8D93] text-[25px] bg-transparent focus:outline-none resize-none border-none mb-[10px] leading-[1.2] w-full"
                placeholder="Start typing your question"
                value={question?.question}
                onChange={(e) => onQuestionChange(e.target.value)}
            />
            <TextareaAutosize
                className="text-[#001F40] placeholder-[#8E8D93] text-md bg-transparent focus:outline-none resize-none border-none mb-[20px] leading-[1.2] w-full"
                placeholder="Type some context to help respondents know why you are asking..."
                value={question?.help_text}
                onChange={(e) => onHelpTextChange(e.target.value)}
            />
            <div className="flex flex-row mb-4 w-full justify-center">
                {[...(question?.assets ?? []), ...(question?.uploaded_files ?? [])].length > 0 && (
                    <QuestionAssets
                        key={selectedIndex}
                        assets={[
                            ...(question?.assets ?? []).map((asset: any) => {
                                return {
                                    path: asset.path ?? URL.createObjectURL(asset),
                                    name: asset.name,
                                    type: asset.type?.startsWith('image') ? 'image' : 'video',
                                }
                            }),
                            ...(question?.uploaded_files ?? []).map((file: any) => {
                                return {
                                    path: URL.createObjectURL(file),
                                    name: file.name,
                                    type: file.type?.startsWith('image') ? 'image' : 'video',
                                }
                            }),
                        ]}
                        showDelete={true}
                        handleDelete={async (index: number) => {
                            try {
                                setShowSpinner(true)
                                const combinedAssets = [
                                    ...(question?.assets ?? []),
                                    ...(question?.uploaded_files ?? []),
                                ]
                                const assetToDelete = combinedAssets[index]
                                if (assetToDelete) {
                                    if (
                                        'size' in assetToDelete &&
                                        'lastModified' in assetToDelete
                                    ) {
                                        setQuestions(
                                            questions.map((q) => {
                                                if (q.id === question.id) {
                                                    return {
                                                        ...q,
                                                        uploaded_files: q.uploaded_files?.filter(
                                                            (f) =>
                                                                f.name !== assetToDelete.name &&
                                                                f.size !== assetToDelete.size,
                                                        ),
                                                    }
                                                }
                                                return q
                                            }),
                                        )
                                    } else {
                                        await QuestionApi.deleteQuestionAssets(
                                            question.id,
                                            assetToDelete.path,
                                        )
                                        setQuestions(
                                            questions.map((q) => {
                                                if (q.id === question.id) {
                                                    return {
                                                        ...q,
                                                        assets: q.assets?.filter(
                                                            (a) => a.path !== assetToDelete.path,
                                                        ),
                                                    }
                                                }
                                                return q
                                            }),
                                        )
                                    }
                                }
                            } finally {
                                setShowSpinner(false)
                            }
                        }}
                    />
                )}
            </div>

            {question?.question_settings.type === QuestionTypeSettings.VIDEO_ONLY && (
                <VideoOnlyResponder
                    disabled
                    showNotes={!!question?.question_settings?.show_notes}
                />
            )}
            {question?.question_settings.type === QuestionTypeSettings.VIDEO_OR_AUDIO_OR_TEXT && (
                <VideoVoiceTextResponder
                    disabled
                    showNotes={!!question?.question_settings?.show_notes}
                />
            )}
            {question?.question_settings?.type === QuestionTypeSettings.VOICE_ONLY && (
                <VoiceOnlyResponder
                    disabled
                    showNotes={!!question?.question_settings?.show_notes}
                />
            )}
            {question?.question_settings?.type === QuestionTypeSettings.VOICE_OR_TEXT && (
                <VoiceTextResponder
                    disabled
                    showNotes={!!question?.question_settings?.show_notes}
                />
            )}
            {question?.question_settings?.type === QuestionTypeSettings.SINGLE_SELECT && (
                <SingleSelectResponder
                    disabled
                    selectedQuestion={question}
                    setQuestions={setQuestions}
                    questionIndex={selectedIndex}
                    questions={questions}
                />
            )}
            {question?.question_settings?.type === QuestionTypeSettings.MULTI_SELECT && (
                <MultipleSelectResponder
                    setQuestions={setQuestions}
                    questionIndex={selectedIndex}
                    questions={questions}
                    selectedQuestion={question}
                    disabled
                />
            )}
            {question?.question_settings.type === QuestionTypeSettings.FREE_TEXT && (
                <FreeTextResponder disabled showRecordInstead={false} />
            )}

            <div className="flex flex-row flex-wrap justify-between gap-5 mt-8">
                <div className="ml-[9px] flex flex-row items-center flex-wrap gap-6">
                    <Tooltip content={<p>All questions are optional by default</p>}>
                        <ToggleSwitch
                            checked={
                                question?.question_settings.require_question_response ===
                                Mandatory.REQUIRED
                            }
                            label="Required"
                            onChange={onIsRequiredChange}
                        />
                    </Tooltip>
                    <div>
                        {isSeIsHiddenDisabled && !question?.question_settings?.is_hidden ? (
                            <Tooltip content={<p>A link must have at least one question</p>}>
                                <ToggleSwitch
                                    disabled={
                                        isSeIsHiddenDisabled &&
                                        !question?.question_settings?.is_hidden
                                    }
                                    checked={question?.question_settings?.is_hidden ?? false}
                                    label="Hide"
                                    onChange={onIsHiddenChange}
                                />
                            </Tooltip>
                        ) : (
                            <ToggleSwitch
                                checked={question?.question_settings?.is_hidden ?? false}
                                label="Hide"
                                onChange={onIsHiddenChange}
                            />
                        )}
                    </div>
                    {(question?.question_settings.type === QuestionTypeSettings.VOICE_ONLY ||
                        question?.question_settings.type ===
                            QuestionTypeSettings.VOICE_OR_TEXT) && (
                        <Tooltip
                            content={
                                <p>
                                    Let respondents draft thoughts before recording. For voice
                                    responses only.
                                </p>
                            }
                        >
                            <ToggleSwitch
                                checked={!!question?.question_settings.show_notes}
                                label="Add Notes"
                                onChange={onShowNotesChange}
                            />
                        </Tooltip>
                    )}
                    <button
                        className="flex justify-center items-center py-2 px-4 gap-3 disabled:opacity-60"
                        onClick={() => {
                            setShowUploadQuestionAssets(true)
                        }}
                    >
                        <img className="w-6 h-6" src="/asset/images.svg" alt="images" />
                    </button>
                </div>
                <div className="flex-1 self-end flex flex-row gap-10 justify-end">
                    <div className="flex flex-row items-center gap-2.5">
                        {totalQuestions > 1 && (
                            <p className="text-[#001F40] text-sm font-lato">
                                {selectedIndex + 1}/{totalQuestions}
                            </p>
                        )}
                        <div className="flex flex-row gap-3">
                            <PreviousQuestionButton
                                onClick={() => onQuestionIndexChange(selectedIndex - 1)}
                                disabled={!showPreviousQuestionButton}
                            />
                            <NextQuestionButton
                                onClick={() => onQuestionIndexChange(selectedIndex + 1)}
                                disabled={!showNextQuestionButton}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const EditQuestions = (): JSX.Element => {
    const { username, slug } = useParams<{ username: string; slug: string }>()
    const { state } = useLocation()
    const campaignId = state?.campaignId
    const [questions, setQuestions] = useState<Partial<Question>[]>([])
    const [linkTitle, setLinkTitle] = useState<string>('')
    const [selectedIndex, setSelectedIndex] = useState<number>(state?.questionIndex ?? 0)
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
    const [isCollectNameEnabled, setIsCollectNameEnabled] = useState<boolean>(false)
    const [isCollectEmailEnabled, setIsCollectEmailEnabled] = useState<boolean>(false)
    const [collectNameValue, setCollectNameValue] = useState<CollectNameOption>(
        CollectNameOption.OPTIONAL,
    )
    const [collectEmailValue, setCollectEmailValue] = useState<CollectNameOption>(
        CollectNameOption.OPTIONAL,
    )
    const [collectCampaignTypeValue, setCollectCampaignTypeValue] =
        useState<CollectCampaignTypeOption>(CollectCampaignTypeOption.ASYNC)

    const [openResponseTypesModal, setOpenResponseTypesModal] = useState<boolean>(false)
    const [showChangeOrder, setShowChangeOrder] = useState<boolean>(false)
    const [initialQuestionsLength, setInitialQuestionsLength] = useState<number>(0)

    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const toggleModal = () => setDeleteModal((v) => !v)
    const selectedQuestion = questions[selectedIndex]
    const navigate = useNavigate()
    const isSeIsHiddenDisabled: boolean =
        questions.filter((q) => !q.question_settings?.is_hidden ?? false).length <= 1
    const {
        state: { user },
    } = useUserStore()

    const fetchQuestions = async (): Promise<void> => {
        if (username && slug) {
            const questionResponse = await QuestionApi.getQuestionsByIdOrSlugForLandingPage(slug)
            setQuestions(questionResponse.data)
            setLinkTitle(questionResponse.data[0]?.question_settings?.title ?? '')
            setInitialQuestionsLength(questionResponse.data.length)

            if (
                questionResponse.data[0]?.question_settings?.campaign_type ===
                CollectCampaignTypeOption.LIVE
            ) {
                setCollectCampaignTypeValue(CollectCampaignTypeOption.LIVE)
            } else {
                setCollectCampaignTypeValue(CollectCampaignTypeOption.ASYNC)
            }

            if (
                questionResponse.data[0]?.question_settings?.require_email === Mandatory.OPTIONAL ||
                questionResponse.data[0]?.question_settings?.require_email === Mandatory.REQUIRED
            ) {
                setCollectEmailValue(
                    questionResponse.data[0]?.question_settings?.require_email ===
                        Mandatory.OPTIONAL
                        ? CollectNameOption.OPTIONAL
                        : CollectNameOption.REQUIRED,
                )
                setIsCollectEmailEnabled(true)
            }

            if (
                questionResponse.data[0]?.question_settings?.require_first_name ===
                    Mandatory.OPTIONAL ||
                questionResponse.data[0]?.question_settings?.require_first_name ===
                    Mandatory.REQUIRED
            ) {
                setCollectNameValue(
                    questionResponse.data[0]?.question_settings?.require_first_name ===
                        Mandatory.OPTIONAL
                        ? CollectNameOption.OPTIONAL
                        : CollectNameOption.REQUIRED,
                )
                setIsCollectNameEnabled(true)
            }
        }
    }

    useEffect(() => {
        fetchQuestions()
    }, [username, slug])

    const toggleResponseTypeModal = () => setOpenResponseTypesModal((v) => !v)
    const doAllQuestionsHaveTitle = questions.every((q) => q?.question?.length ?? 0 > 0)

    const moveQuestion = (index: number) => {
        setQuestions(
            questions.map((q: Partial<Question>, i: number) => {
                if (i === index) {
                    const question = questions[i + 1]
                    return {
                        ...question,
                        question_sequence: (question?.question_sequence as number) - 1,
                    }
                } else if (i === index + 1) {
                    const question = questions[i - 1]
                    return {
                        ...question,
                        question_sequence: (question?.question_sequence as number) + 1,
                    }
                } else {
                    return q
                }
            }),
        )
    }

    const onRemovePressed = () => {
        if (questions.length === 1) {
            setQuestions([])
            setSelectedIndex(0)
        } else {
            setQuestions(questions.filter((q: any, index: number) => index !== selectedIndex))
            setSelectedIndex(selectedIndex - 1)
        }
    }

    return (
        <div className="flex-1 px-[25px] sm:px-[70px] lg:pr-[120px] pb-20">
            <div className="flex flex-col xs:flex-row justify-between gap-0 md:gap-10">
                <div className="flex items-center gap-6 flex-1">
                    <button
                        className="text-[#007AFF] text-base mr-3 disabled:text-[#007AFF80]"
                        onClick={() => {
                            let selectedQuestionIndex = selectedIndex
                            if (selectedIndex >= initialQuestionsLength) {
                                setSelectedIndex(0)
                                selectedQuestionIndex = 0
                            }
                            navigate(
                                generatePath(Page.QUESTION_LANDING_PAGE, {
                                    username: username as string,
                                    slug: slug as string,
                                }),
                                {
                                    state: {
                                        questionIndex: selectedQuestionIndex,
                                        campaignId: campaignId,
                                    },
                                },
                            )
                        }}
                    >
                        <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8.93934 13.9393C8.35355 14.5251 8.35355 15.4749 8.93934 16.0607L18.4853 25.6066C19.0711 26.1924 20.0208 26.1924 20.6066 25.6066C21.1924 25.0208 21.1924 24.0711 20.6066 23.4853L12.1213 15L20.6066 6.51472C21.1924 5.92893 21.1924 4.97919 20.6066 4.3934C20.0208 3.80761 19.0711 3.80761 18.4853 4.3934L8.93934 13.9393ZM12 13.5H10V16.5H12V13.5Z"
                                fill="#8092A5"
                            />
                        </svg>
                    </button>
                    <input
                        className="text-[25px] font-bold text-[#8092A5] placeholder-[rgba(191,_222,_255,_0.33)] outline-none focus-within:outline-none bg-transparent flex-1 min-w-[180px] sm:min-w-fit"
                        placeholder="Give your link a title..."
                        value={questions[0]?.question_settings?.title || ''}
                        onChange={(event: any) => {
                            setLinkTitle(event.target.value)
                            setQuestions(
                                questions.map((q) => {
                                    return {
                                        ...q,
                                        question_settings: {
                                            ...q.question_settings,
                                            title: event.target.value,
                                        },
                                    }
                                }),
                            )
                        }}
                    />
                </div>
                <div className="flex flex-row gap-7 items-center mt-5 xs:mt-0 self-end xs:self-center">
                    <button
                        className={`font-bold text-base text-white ${
                            isSubmitted || showChangeOrder || !doAllQuestionsHaveTitle
                                ? 'bg-[#AFAFAF]'
                                : 'bg-[#007AFF]'
                        } w-[100px] xs:w-[120px] g-9 rounded-[15px] py-1`}
                        disabled={isSubmitted || showChangeOrder || !doAllQuestionsHaveTitle}
                        onClick={async () => {
                            setIsSubmitted(true)
                            await QuestionApi.patchQuestions(
                                questions.map((q, index) => {
                                    return {
                                        id: q.id,
                                        question: q.question ?? '',
                                        is_responding_nodeios_private: true,
                                        is_draft: false,
                                        help_text: q.help_text,
                                        is_multi_question_link: questions.length > 1,
                                        question_sequence: index + 1,
                                        question_settings: {
                                            ...q.question_settings,
                                            title: linkTitle,
                                            /* eslint-disable indent */
                                            require_first_name: !isCollectNameEnabled
                                                ? Mandatory.HIDDEN
                                                : collectNameValue === CollectNameOption.OPTIONAL
                                                ? Mandatory.OPTIONAL
                                                : Mandatory.REQUIRED,
                                            require_last_name: !isCollectNameEnabled
                                                ? Mandatory.HIDDEN
                                                : Mandatory.OPTIONAL,
                                            require_email: !isCollectEmailEnabled
                                                ? Mandatory.HIDDEN
                                                : collectEmailValue === CollectNameOption.OPTIONAL
                                                ? Mandatory.OPTIONAL
                                                : Mandatory.REQUIRED,
                                        },
                                        assets: q.uploaded_files,
                                    }
                                }),
                                slug ?? '',
                            )
                            navigate(
                                generatePath(Page.QUESTION_LANDING_PAGE, {
                                    username: username as string,
                                    slug: slug as string,
                                }),
                                {
                                    state: {
                                        questionIndex: selectedIndex,
                                        campaignId: campaignId,
                                    },
                                },
                            )
                        }}
                    >
                        Save
                    </button>
                </div>
            </div>
            <div className="flex-1">
                <div
                    className="mt-12 px-6 py-3 flex flex-col sm:flex-row justify-between items-center rounded-2xl gap-1 sm:gap-7"
                    style={{
                        background:
                            'var(--bg-for-questions, linear-gradient(313deg, rgba(107, 178, 255, 0.19) 7.36%, rgba(0, 122, 255, 0.13) 33.85%, rgba(107, 178, 255, 0.13) 68.75%, rgba(0, 122, 255, 0.19) 93.75%), #FFF)',
                    }}
                >
                    <p className="text-[#007AFF] ellipsis flex-1">
                        {`${window.location.origin}/${user.username}/${slug}`}
                    </p>
                </div>
            </div>

            <div className="mt-[40px] flex min-h-[650px] gap-6 flex-col md:flex-row">
                {collectCampaignTypeValue === CollectCampaignTypeOption.ASYNC && (
                    <div className="w-full md:w-[205px] rounded-2xl bg-white p-4 px-3 flex flex-col relative">
                        {!showChangeOrder && (
                            <div className="flex justify-between mx-1 py-3">
                                <p className="text-[#0C1B2C] text-sm font-bold">
                                    Add question type
                                </p>
                                <button onClick={toggleResponseTypeModal}>
                                    <img src="/asset/plus_blue.svg" />
                                </button>
                            </div>
                        )}
                        {showChangeOrder && (
                            <div className="flex justify-end mx-1 py-1">
                                {/* <button
                                    className="text-[#007AFF] font-lato font-bold text-sm sm:text-base p-2"
                                    onClick={() => setShowChangeOrder(false)}
                                >
                                    Cancel
                                </button> */}
                                <button
                                    className="text-[#007AFF] font-lato font-bold text-sm sm:text-base p-2"
                                    onClick={() => setShowChangeOrder(false)}
                                >
                                    Done
                                </button>
                            </div>
                        )}
                        {openResponseTypesModal && (
                            <>
                                <div className="z-10 rounded-xl bg-[var(--base-white,_#FFF)] grid p-4 gap-4 absolute -mr-5 top-[60px] md:top-[0] right-[30px] md:right-[unset] left-[unset] md:left-[calc(100%_+_10px)] grid-cols-[repeat(auto-fit,_minmax(150px,_1fr))] xs:w-[370px] shadow-[rgba(16,_24,_26,_0.3)_0px_4px_6px_-2px,_rgba(16,_24,_39,_0.8)_0px_12px_16px_2px] md:shadow-[0px_4px_6px_-2px_rgba(16,_24,_40,_0.03),_0px_12px_16px_-4px_rgba(16,_24,_40,_0.08)]">
                                    {questionTypes.map((item: any) => (
                                        <button
                                            key={item.id}
                                            className="flex items-center gap-3 p-2 rounded-md hover:bg-[var(--blue-100,_#D1E9FF)] cursor-pointer"
                                            onClick={() => {
                                                setQuestions([
                                                    ...questions,
                                                    {
                                                        id: uuid(),
                                                        question: '',
                                                        help_text: '',
                                                        question_sequence: questions.length + 1,
                                                        question_settings: {
                                                            title: linkTitle,
                                                            require_question_response:
                                                                Mandatory.OPTIONAL,
                                                            require_first_name: Mandatory.HIDDEN,
                                                            require_last_name: Mandatory.HIDDEN,
                                                            require_email: Mandatory.HIDDEN,
                                                            type: item.id,
                                                            options: [
                                                                QuestionTypeSettings.SINGLE_SELECT,
                                                                QuestionTypeSettings.MULTI_SELECT,
                                                            ].includes(item.id)
                                                                ? ['']
                                                                : undefined,
                                                        },
                                                        is_new: true,
                                                    },
                                                ])
                                                setSelectedIndex(questions.length)
                                                toggleResponseTypeModal()
                                            }}
                                        >
                                            <div
                                                className="p-1.5 rounded-md w-fit"
                                                style={{
                                                    background: item.color,
                                                }}
                                            >
                                                <img
                                                    src={item.icon}
                                                    className="w-[18px] h-[18px]"
                                                />
                                            </div>
                                            <p className="text-[#0C1B2C] text-sm">{item.title}</p>
                                        </button>
                                    ))}
                                </div>
                                <div
                                    className="top-0 left-0 w-full h-full fixed bg-transparent z-1"
                                    onClick={toggleResponseTypeModal}
                                ></div>
                            </>
                        )}
                        <div className="flex-1 mt-4 mb-4 overflow-y-auto flex flex-col gap-3">
                            {questions.map((question: Partial<Question>, index: number) => {
                                const questionType = questionTypes.find(
                                    (_) => _.id === question?.question_settings?.type,
                                )
                                return (
                                    <div key={question.id}>
                                        <button
                                            key={question.id}
                                            className="flex items-center justify-between gap-2 p-2 pr-1 rounded-md w-full border-[1px] bg-white hover:bg-[var(--blue-100,_#D1E9FF)] border-[var(--gray-300,_#D0D5DD)] hover:border-[#007AFF] cursor-pointer"
                                            onClick={() => setSelectedIndex(index)}
                                            style={{
                                                backgroundColor:
                                                    selectedIndex === index
                                                        ? 'var(--blue-100, #D1E9FF)'
                                                        : 'white',
                                                borderColor:
                                                    selectedIndex === index
                                                        ? '#007AFF'
                                                        : 'var(--gray-300, #D0D5DD)',
                                                opacity:
                                                    selectedIndex === index
                                                        ? 1
                                                        : question.question_settings?.is_hidden
                                                        ? 0.5
                                                        : 1,
                                            }}
                                        >
                                            <div className="flex flex-row gap-2 items-center">
                                                <p>{index + 1}</p>
                                                <div
                                                    className="p-1.5 rounded-md w-fit"
                                                    style={{
                                                        background: questionType?.color,
                                                    }}
                                                >
                                                    <img
                                                        src={questionType?.icon}
                                                        className="w-[18px] h-[18px]"
                                                    />
                                                </div>
                                                <p className="text-[#0C1B2C] text-sm ellipsis-1 break-all">
                                                    {questionType?.title}
                                                </p>
                                            </div>
                                            {question.is_new && (
                                                <button onClick={() => question && toggleModal()}>
                                                    <div className="w-[20px] h-[20px] justify-end">
                                                        <img
                                                            src="/asset/trash_bin.svg"
                                                            alt="trash_icon"
                                                        />
                                                    </div>
                                                </button>
                                            )}
                                        </button>
                                        {showChangeOrder && index < questions.length - 1 && (
                                            <button
                                                onClick={() => moveQuestion(index)}
                                                className="flex items-center justify-center"
                                            >
                                                <img src="/asset/swap_horiz.svg" alt="swap_horiz" />
                                            </button>
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}
                <div className="flex-1 flex flex-col">
                    <div className="flex items-center gap-2">
                        <p className="text-2xl font-bold text-[#8092A5]">
                            {
                                questionTypes.find(
                                    (item: QuestionTypesType) =>
                                        item.id === selectedQuestion?.question_settings?.type,
                                )?.title
                            }
                        </p>
                        <Tooltip
                            content={
                                questionTypes.find(
                                    (item: QuestionTypesType) =>
                                        item.id === selectedQuestion?.question_settings?.type,
                                )?.tooltip
                            }
                            className="text-base px-5 py-2 max-w-[300px]"
                            placement="right"
                            theme={{
                                base: 'absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-bold shadow-sm !bg-[#BFDEFF] !text-[#001F40]',
                                arrow: {
                                    base: 'absolute z-10 h-2 w-2 rotate-45 !bg-[#BFDEFF]',
                                },
                            }}
                        >
                            <img src="/asset/help-circle.svg" />
                        </Tooltip>
                    </div>
                    <div className="h-[1px] w-full mt-3 mb-6 bg-[#BFDEFF]" />
                    <div
                        className="rounded-2xl flex-1"
                        style={{
                            background:
                                'var(--bg-for-questions, linear-gradient(313deg, rgba(107, 178, 255, 0.19) 7.36%, rgba(0, 122, 255, 0.13) 33.85%, rgba(107, 178, 255, 0.13) 68.75%, rgba(0, 122, 255, 0.19) 93.75%), #FFF)',
                        }}
                    >
                        <EditLinkQuestionCard
                            selectedIndex={selectedIndex}
                            totalQuestions={questions.length}
                            question={selectedQuestion as Question}
                            onQuestionChange={(question) => {
                                const newQuestions = [...questions]
                                newQuestions[selectedIndex].question = question
                                setQuestions(newQuestions)
                            }}
                            onHelpTextChange={(help_text) => {
                                const newQuestions = [...questions]
                                newQuestions[selectedIndex].help_text = help_text
                                setQuestions(newQuestions)
                            }}
                            onQuestionIndexChange={(index) => {
                                setSelectedIndex(index)
                            }}
                            onIsRequiredChange={() => {
                                setQuestions(
                                    questions.map((q: Partial<Question>, index: number) => {
                                        if (index === selectedIndex) {
                                            return {
                                                ...q,
                                                question_settings: {
                                                    ...q.question_settings,
                                                    require_question_response:
                                                        q?.question_settings
                                                            ?.require_question_response ===
                                                        Mandatory.REQUIRED
                                                            ? Mandatory.OPTIONAL
                                                            : Mandatory.REQUIRED,
                                                },
                                            }
                                        }
                                        return q
                                    }),
                                )
                            }}
                            setQuestions={setQuestions}
                            questions={questions}
                            onIsHiddenChange={() => {
                                setQuestions(
                                    questions.map((q, index) => {
                                        if (index === selectedIndex) {
                                            return {
                                                ...q,
                                                question_settings: {
                                                    ...q.question_settings,
                                                    is_hidden: !q?.question_settings?.is_hidden,
                                                },
                                            }
                                        }
                                        return q
                                    }),
                                )
                            }}
                            onShowNotesChange={() => {
                                setQuestions(
                                    questions.map((q, index) => {
                                        if (index === selectedIndex) {
                                            return {
                                                ...q,
                                                question_settings: {
                                                    ...q.question_settings,
                                                    show_notes: !q?.question_settings?.show_notes,
                                                },
                                            }
                                        }
                                        return q
                                    }),
                                )
                            }}
                            isSeIsHiddenDisabled={isSeIsHiddenDisabled}
                            onUploadQuestionAssets={(files) => {
                                setQuestions(
                                    questions.map((q, index) => {
                                        if (index === selectedIndex) {
                                            return {
                                                ...q,
                                                uploaded_files: files,
                                            }
                                        }
                                        return q
                                    }),
                                )
                            }}
                        />
                    </div>
                </div>
            </div>
            <div>
                {collectCampaignTypeValue === CollectCampaignTypeOption.ASYNC && (
                    <div className="flex flex-col px-0 sm:px-6 pt-4">
                        <button
                            onClick={() => setShowChangeOrder(true)}
                            className="flex flex-row items-center gap-2"
                            disabled={questions.length <= 1 || isSubmitted || showChangeOrder}
                        >
                            {questions.length <= 1 || isSubmitted || showChangeOrder ? (
                                <img
                                    src="/asset/swap_horiz_disabled.svg"
                                    alt="swap_horiz_disabled"
                                />
                            ) : (
                                <img src="/asset/swap_horiz.svg" alt="swap_horiz" />
                            )}
                            <p
                                className={`font-bold font-lato text-base ${
                                    questions.length <= 1 || isSubmitted || showChangeOrder
                                        ? 'text-[#AFAFAF]'
                                        : 'text-[#007AFF]'
                                }`}
                            >
                                Change order
                            </p>
                        </button>
                    </div>
                )}

                <div className="flex flex-col px-0 sm:px-6">
                    <p className="font-lato font-bold text-base text-[#007AFF] mt-9 mb-6">
                        Collect from respondents
                    </p>
                    <div className="flex flex-col xs:flex-row items-start sm:items-center gap-5 xs:gap-3 xl:gap-8">
                        <ToggleSwitch
                            checked={isCollectNameEnabled}
                            label="Name"
                            onChange={() => setIsCollectNameEnabled(!isCollectNameEnabled)}
                            theme={{
                                root: {
                                    label: 'text-[#007AFF] font-lato text-base ml-2',
                                },
                            }}
                        />
                        {isCollectNameEnabled && (
                            <div className="flex flex-col xs:flex-row items-center gap-5">
                                <label className="flex flex-row items-center gap-1 text-[#007AFF] text-base font-lato">
                                    <input
                                        className="w-6 h-6"
                                        type="radio"
                                        name="collect-name-option"
                                        value={CollectNameOption.OPTIONAL}
                                        checked={collectNameValue === CollectNameOption.OPTIONAL}
                                        onChange={() =>
                                            setCollectNameValue(CollectNameOption.OPTIONAL)
                                        }
                                    />
                                    {CollectNameOption.OPTIONAL}
                                </label>

                                <label className="flex flex-row items-center gap-1 text-[#007AFF] text-base font-lato">
                                    <input
                                        className="w-6 h-6"
                                        type="radio"
                                        name="collect-name-option"
                                        value={CollectNameOption.REQUIRED}
                                        checked={collectNameValue === CollectNameOption.REQUIRED}
                                        onChange={() =>
                                            setCollectNameValue(CollectNameOption.REQUIRED)
                                        }
                                    />
                                    {CollectNameOption.REQUIRED}
                                </label>
                            </div>
                        )}
                    </div>

                    <div className="flex flex-col xs:flex-row  mt-[30px] items-start sm:items-center gap-5 xs:gap-3 xl:gap-8">
                        <ToggleSwitch
                            checked={isCollectEmailEnabled}
                            label="Email"
                            onChange={() => setIsCollectEmailEnabled(!isCollectEmailEnabled)}
                            theme={{
                                root: {
                                    label: 'text-[#007AFF] font-lato text-base ml-2',
                                },
                            }}
                        />
                        {isCollectEmailEnabled && (
                            <div className="flex flex-col xs:flex-row items-center gap-5">
                                <label className="flex flex-row items-center gap-1 text-[#007AFF] text-base font-lato">
                                    <input
                                        className="w-6 h-6"
                                        type="radio"
                                        name="collect-email-option"
                                        value={CollectNameOption.OPTIONAL}
                                        checked={collectEmailValue === CollectNameOption.OPTIONAL}
                                        onChange={() =>
                                            setCollectEmailValue(CollectNameOption.OPTIONAL)
                                        }
                                    />
                                    {CollectNameOption.OPTIONAL}
                                </label>

                                <label className="flex flex-row items-center gap-1 text-[#007AFF] text-base font-lato">
                                    <input
                                        className="w-6 h-6"
                                        type="radio"
                                        name="collect-email-option"
                                        value={CollectNameOption.REQUIRED}
                                        checked={collectEmailValue === CollectNameOption.REQUIRED}
                                        onChange={() =>
                                            setCollectEmailValue(CollectNameOption.REQUIRED)
                                        }
                                    />
                                    {CollectNameOption.REQUIRED}
                                </label>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div
                className={`fixed top-0 left-0 w-full h-full bg-[#000000c7] flex justify-center items-center z-20 ${
                    deleteModal ? 'fixed' : 'hidden'
                }`}
            >
                <div
                    className="relative w-[350px] sm:w-[480px] bg-white px-[30px] p-[30px] py-[20px] rounded-2xl flex flex-col"
                    style={{ boxShadow: '0px 4px 4px 0px #00000040' }}
                >
                    <div className="flex-1">
                        <h2 className="text-[#007AFF] text-[25px] leading-10 pb-2">
                            Delete Question
                        </h2>
                        <p className="text-[#0D0D0D] text-[13px]">
                            Are you sure you want to delete "
                            {
                                questionTypes.find(
                                    (item: QuestionTypesType) =>
                                        item.id ===
                                        questions[selectedIndex]?.question_settings?.type,
                                )?.title
                            }
                            " question?
                        </p>
                    </div>

                    <div className="flex self-end gap-7 mt-5">
                        <button
                            className="text-[#101828] text-base font-bold"
                            onClick={toggleModal}
                        >
                            Cancel
                        </button>
                        <button
                            className="text-white text-base font-bold flex bg-[var(--error-600,_#D92D20)] p-2 px-5 rounded-2xl shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)]"
                            onClick={() => {
                                onRemovePressed()
                                toggleModal()
                            }}
                        >
                            Yes, Delete it
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EditQuestions
