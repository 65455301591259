// @ts-nocheck
import { UserApi } from '../../api/user'
import { CookieKey } from '../../enums/CookieKey'
import { User } from '../../types/User'
import { deleteCookie } from '../../utils/useCookies'
import { setLoadingAction, setUserAction } from './actions'
import { initialUserStoreState, userReducer } from './reducer'
import { createContext, useContext, useReducer } from 'react'

export const UserStore = createContext(initialUserStoreState)

export const useUserStore = (): PageBannerContext => useContext(UserStore)

export const UserStoreProvider = ({ children }): any => {
    const [state, dispatch] = useReducer(userReducer, initialUserStoreState)

    const setUser = (user: User) => {
        setUserAction(user, dispatch)
    }

    const fetchUser = async () => {
        const response = await UserApi.getAuthenticatedUser()
        if (response) {
            setUser(response.data)
        }
    }

    const setLoading = (loading: boolean) => {
        setLoadingAction(loading, dispatch)
    }

    const logout = () => {
        deleteCookie(CookieKey.JWT_TOKEN)
        deleteCookie(CookieKey.REFRESH_TOKEN)
        setUserAction(null, dispatch)
    }

    return (
        <UserStore.Provider
            value={{
                state: state,
                setUser,
                fetchUser,
                setLoading,
                logout,
            }}
        >
            {children}
        </UserStore.Provider>
    )
}
