import { EmotionsByThemeResponse } from '../types/EmotionsByThemeResponse'
import { GeneratePreSignedUrlResponse } from '../types/GeneratePreSignedUrlResponse'
import { Interview } from '../types/Interview'
import { InterviewResponse } from '../types/InterviewResponse'
import { ListOfInterviewResponses } from '../types/ListOfInterviewResponses'
import ApiService from './apiService'
import { AxiosResponse } from 'axios'

export interface InterviewApi {
    getInterviewBySlug(slug: string): Promise<AxiosResponse<Interview>>
    saveInterview(title: string, campaignId: string): Promise<AxiosResponse<void>>
    updateInterviewById(id: string, title: string): Promise<AxiosResponse<void>>
    deleteById(id: string): Promise<AxiosResponse<void>>
    getInterviewResponsesById(id: string): Promise<AxiosResponse<Interview[]>>
    getPresignedUrlById(id: string): Promise<AxiosResponse<string>>
    generateHumeDataById(id: string): Promise<AxiosResponse<void>>
    getBySlug(slug: string): Promise<AxiosResponse<Interview>>
    generatePreSignedUrl(
        slug: string,
        body: any,
    ): Promise<AxiosResponse<GeneratePreSignedUrlResponse>>
    getInterviewResponses(interviewId: string): Promise<AxiosResponse<ListOfInterviewResponses>>
    getEmotionAnalysisByInterviewIdAndThemeName(
        interviewId: string,
        themeName: string,
    ): Promise<AxiosResponse<EmotionsByThemeResponse[]>>
    getInterviewResponseById(id: string): Promise<AxiosResponse<InterviewResponse>>
    downloadReport(id: string): Promise<AxiosResponse<any>>
}

export const InterviewApi: InterviewApi = Object.freeze({
    getInterviewBySlug(slug: string): Promise<AxiosResponse<Interview>> {
        return ApiService.get(`/interviews/${slug}`)
    },
    saveInterview(title: string, campaignId: string): Promise<AxiosResponse<void>> {
        return ApiService.post('/interviews', {
            title: title,
            campaignId: campaignId,
        })
    },
    updateInterviewById(id: string, title: string): Promise<AxiosResponse<void>> {
        return ApiService.patch(`/interviews/${id}`, {
            title: title,
        })
    },
    deleteById(id: string): Promise<AxiosResponse<void>> {
        return ApiService.delete(`/interviews/${id}`)
    },
    getInterviewResponsesById(id: string): Promise<AxiosResponse<Interview[]>> {
        return ApiService.get(`/interviews/${id}/responses`)
    },
    getPresignedUrlById(id: string): Promise<AxiosResponse<string>> {
        return ApiService.get(`/interviews/${id}/pre-signed-url`)
    },
    generateHumeDataById(id: string): Promise<AxiosResponse<void>> {
        return ApiService.post(`/interviews/${id}/generate-hume-ai-data`, {})
    },
    getBySlug(slug: string): Promise<AxiosResponse<Interview>> {
        return ApiService.get(`/interviews/${slug}`)
    },
    generatePreSignedUrl(
        slug: string,
        body: any,
    ): Promise<AxiosResponse<GeneratePreSignedUrlResponse>> {
        return ApiService.post(`interviews/${slug}/pre-signed-url`, body)
    },
    getInterviewResponses(interviewId: string): Promise<AxiosResponse<ListOfInterviewResponses>> {
        return ApiService.get(`interviews/${interviewId}/responses`)
    },
    getEmotionAnalysisByInterviewIdAndThemeName(
        interviewId: string,
        themeName: string,
    ): Promise<AxiosResponse<EmotionsByThemeResponse[]>> {
        return ApiService.get(`interviews/${interviewId}/${themeName}/emotion-analysis`)
    },
    getInterviewResponseById(id: string): Promise<AxiosResponse<InterviewResponse>> {
        return ApiService.get(`interviews/responses/${id}`)
    },
    downloadReport(id: string): Promise<AxiosResponse<any>> {
        return ApiService.download(`interviews/${id}/download-report`)
    },
})
