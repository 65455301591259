import App from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'
import deTranslation from './translations/de.json'
import enTranslation from './translations/en.json'
import esTranslation from './translations/es.json'
import frTranslation from './translations/fr.json'
import hiTranslation from './translations/hi.json'
import htTranslation from './translations/ht.json'
import itTranslation from './translations/it.json'
import jaTranslation from './translations/ja.json'
import koTranslation from './translations/ko.json'
import zhTranslation from './translations/zh.json'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import i18n from 'i18next'
import React from 'react'
import ReactDOM from 'react-dom/client'
import ReactGA from 'react-ga4'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'

if (process.env.REACT_APP_ENV === 'production') {
    ReactGA.initialize('G-C29GC6BFFG')
}

Sentry.init({
    dsn: 'https://0d9bddea432447aba5eb0f8eda9d996c@o1133717.ingest.sentry.io/4504679631683584',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0,
    environment: process.env.REACT_APP_ENV,
})

i18n.init({
    interpolation: { escapeValue: false }, // React already does escaping
    lng: 'en',
    resources: {
        en: { translation: enTranslation },
        es: { translation: esTranslation },
        fr: { translation: frTranslation },
        zh: { translation: zhTranslation },
        ja: { translation: jaTranslation },
        ko: { translation: koTranslation },
        de: { translation: deTranslation },
        it: { translation: itTranslation },
        hi: { translation: hiTranslation },
        ht: { translation: htTranslation },
    },
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <BrowserRouter>
        <I18nextProvider i18n={i18n}>
            <App />
        </I18nextProvider>
    </BrowserRouter>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
