import * as React from 'react'

const SearchButton = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={46} height={46} fill="none" {...props}>
        <g filter="url(#a)">
            <rect width={35.565} height={35.565} x={5} y={3} fill="url(#b)" rx={17.783} />
        </g>
        <path
            fill="#fff"
            d="m29.274 26.173-2.94-2.941a5.433 5.433 0 0 0 1.088-3.27 5.467 5.467 0 0 0-5.46-5.46 5.467 5.467 0 0 0-5.462 5.46 5.467 5.467 0 0 0 5.461 5.462 5.432 5.432 0 0 0 3.269-1.089l2.941 2.941a.781.781 0 0 0 1.103-1.103Zm-11.214-6.21a3.9 3.9 0 1 1 7.801 0 3.9 3.9 0 0 1-7.8 0Z"
        />
        <defs>
            <linearGradient
                id="b"
                x1={22.783}
                x2={22.783}
                y1={3}
                y2={38.565}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#459EFF" />
                <stop offset={1} stopColor="#007AFF" />
            </linearGradient>
            <filter
                id="a"
                width={45.566}
                height={45.565}
                x={0}
                y={0}
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    result="hardAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset dy={2} />
                <feGaussianBlur stdDeviation={2.5} />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_3644_19376" />
                <feBlend in="SourceGraphic" in2="effect1_dropShadow_3644_19376" result="shape" />
                <feColorMatrix
                    in="SourceAlpha"
                    result="hardAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset dy={2} />
                <feGaussianBlur stdDeviation={2} />
                <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
                <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.55 0" />
                <feBlend in2="shape" result="effect2_innerShadow_3644_19376" />
            </filter>
        </defs>
    </svg>
)
export default SearchButton
