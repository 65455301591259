import { Storyboard } from '../types/Storyboard'
import ApiService from './apiService'
import { AxiosResponse } from 'axios'

export interface StoryboardApi {
    getByQuestionId(questionId: string): Promise<AxiosResponse<Storyboard>>
    getMvpsForCustomStoryboard(): Promise<AxiosResponse<[]>>
    save(
        questionId: string,
        backgroundColor?: string,
        backgroundImage?: any,
        backgroundImageMobile?: any,
    ): Promise<AxiosResponse<Storyboard>>
    patch(
        id: string,
        backgroundColor: string,
        backgroundImage: any,
        backgroundImageMobile?: any,
    ): Promise<AxiosResponse<Storyboard>>
    updateStoryboardTitle(id: string, title: string): Promise<AxiosResponse<Storyboard>>
    downloadAudioFiles(id: string): Promise<AxiosResponse<any>>
}

export const StoryboardApi: StoryboardApi = Object.freeze({
    getByQuestionId(questionId: string): Promise<AxiosResponse<Storyboard>> {
        return ApiService.get('/storyboards', questionId)
    },
    getMvpsForCustomStoryboard(): Promise<AxiosResponse<[]>> {
        return ApiService.get('/storyboards/custom/1')
    },
    save(
        questionId: string,
        backgroundColor?: string,
        backgroundImage?: any,
        backgroundImageMobile?: any,
    ): Promise<AxiosResponse<Storyboard>> {
        const formData = new FormData()
        formData.append('question_id', questionId)
        if (backgroundColor) formData.append('background_color', backgroundColor)
        if (backgroundImage) formData.append('background_image', backgroundImage)
        return ApiService.post(
            '/storyboards',
            {
                question_id: questionId,
                background_color: backgroundColor,
                background_image: backgroundImage,
                background_image_mobile: backgroundImageMobile,
            },
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        )
    },
    patch(
        id: string,
        backgroundColor: string,
        backgroundImage: any,
        backgroundImageMobile?: any,
    ): Promise<AxiosResponse<Storyboard>> {
        const formData = new FormData()
        formData.append('background_color', backgroundColor)
        formData.append('background_image', backgroundImage)
        return ApiService.patch(
            `/storyboards/${id}`,
            {
                background_color: backgroundColor,
                background_image: backgroundImage,
                background_image_mobile: backgroundImageMobile,
            },
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        )
    },
    updateStoryboardTitle(id: string, title: string): Promise<AxiosResponse<Storyboard>> {
        return ApiService.patch(`/storyboards/${id}/title`, { storyboard_title: title })
    },
    downloadAudioFiles(id: string): Promise<AxiosResponse<any>> {
        return ApiService.download(`/storyboards/${id}/download-files`)
    },
})
