import moment from 'moment'

export const timeTodayDateElse = (date: Date): string => {
    const today = moment()
    const yesterday = moment().subtract(1, 'day')
    let formattedDate = ''

    if (moment(date).isSame(today, 'day')) {
        //console.log('Today')
        formattedDate = moment(date).format('h:mm a')
    } else if (moment(date).isSame(yesterday, 'day')) {
        //console.log('Yesterday')
        formattedDate = 'yesterday'
    } else {
        formattedDate = moment(date).format('MMM D, YYYY')
    }

    return formattedDate
}

export const timeTodayDateElseWithTime = (date: Date): string => {
    const today = moment()
    const yesterday = moment().subtract(1, 'day')
    let formattedDate = ''

    if (moment(date).isSame(today, 'day')) {
        //console.log('Today')
        formattedDate = 'today at ' + moment(date).format('h:mm a')
    } else if (moment(date).isSame(yesterday, 'day')) {
        //console.log('Yesterday')
        formattedDate = 'yesterday at ' + moment(date).format('h:mm a')
    } else {
        formattedDate = moment(date).format('MMM D, YYYY') + ' at ' + moment(date).format('h:mm a')
    }

    return formattedDate
}
