import { useEffect } from 'react'

const PublicityRelease = (): JSX.Element => {
    useEffect(() => {
        document.body.style.overflowY = 'scroll'
    })

    return (
        <div className="container mx-auto h-screen pt-[100px]">
            <div className="flex flex-row justify-center">
                <img src="/asset/bears.png" />
            </div>
            <h1 className="font-lato font-bold underline mt-10 mb-[60px] text-center text-[26px]">
                CONSENT AND PUBLICITY RELEASE
            </h1>
            <ol className="flex flex-col gap-5 text-[18px] lato-bold px-10 pb-[50px]">
                <li>
                    1. I hereby irrevocably grant to the Chicago Bears Football Club, Inc.
                    (“Bears”), the National Football League (“NFL”), Ticketmaster L.L.C., and each
                    of their representatives, affiliates, agents, licensees, successors and assigns,
                    and anyone authorized by them (collectively “Released Parties”), the right and
                    license in perpetuity to publish, use, modify and distribute throughout the
                    world, in the Released Parties’ sole discretion, my name, image, likeness,
                    photograph, voice, personality, performance, and any other personally
                    identifiable information (“Information”) in connection with the advertising,
                    promotion, publicity and sales of the Bears, the NFL, and the products and
                    brands of their sponsors and/or affiliates in any and all forms of media now
                    known or hereafter developed (“Materials”). I also agree that the Released
                    Parties may copyright the Materials.
                </li>
                <li>
                    2. I hereby waive any inspection or approval of the Materials and waive any
                    rights I may have regarding the use and publication by the Release Parties of
                    the Materials. I expressly release and discharge the Released Parties and their
                    employees, agents and authorized representatives from any claim, demand, action,
                    license, royalty (or any other form of payment) arising out of or in connection
                    with the use of the Information including, but not limited to, any claims for
                    right of privacy, publicity, notoriety and/or defamation.
                </li>
                <li>
                    3. I represent and warrant that I have full right and power to enter into this
                    agreement and that it does not conflict with any other commitments or
                    obligations on my part, and I shall take no action that would breach or
                    undermine the same.
                </li>
                <li>
                    4. I also agree that the Bears own all rights in all photographs, video and
                    audio taken me, and any Materials. I shall not receive a copy of the Materials
                    and shall not in any way authorize any third party to use the Materials without
                    the permission of the Bears. I further understand that the Released Parties are
                    not under any obligation to make any use of the Information or any of the rights
                    granter herein.
                </li>
                <li>
                    5. I agree to keep the terms of this agreement confidential and to take
                    reasonable steps to secure such ends.
                </li>
            </ol>
            <p className="text-[18px] lato-bold px-10 pb-[50px]">
                I have read and fully understand the above agreement and do hereby consent to and
                approve all of its terms and conditions
            </p>
        </div>
    )
}
export default PublicityRelease
