import { ChatApi } from '../../api/chat'
import { ChatCompletionRequestMessageRole } from '../../enums/ChatCompletionRequestMessageRole'
import { useWebSocket } from '../../hooks/useWebSocket'
import { Message } from '../../types/Message'
import AutoSuggestedOptions from './AutoSuggestedOptions'
import ChatMessage from './ChatMessage'
import WaitingOnResponse from './WaitingOnResponse'
import React, { useEffect, useRef, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'

interface ChatProps {
    campaignId?: string
}

const Chat = ({ campaignId }: ChatProps): JSX.Element => {
    const [showSpinner, setShowSpinner] = useState<boolean>(false)
    const [showWaitingOnResponse, setShowWaitingOnResponse] = useState<boolean>(false)
    const [messages, setMessages] = useState<Message[]>([])
    const [question, setQuestion] = useState<string>('')

    const lastMessageRef = useRef<HTMLDivElement>(null)
    const chatContainerRef = useRef<HTMLDivElement>(null)

    const socket = useWebSocket()

    useEffect(() => {
        if (socket) {
            socket.emit('get_chat', { campaign_id: campaignId })
            socket.on('chat', (messages) => {
                setMessages(messages ?? [])
                setShowWaitingOnResponse(false)
            })
            socket.on('error', () => {
                setMessages([
                    {
                        content: 'Unexpected error occurred. Please try again.',
                        role: ChatCompletionRequestMessageRole.ASSISTANT,
                    },
                ])
                setShowWaitingOnResponse(false)
            })
        }

        return () => {
            if (socket) {
                socket.off('chat')
            }
        }
    }, [socket])

    // Scroll to the last message when messages change
    useEffect(() => {
        if (messages && messages.length > 0) {
            lastMessageRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' }) // Scroll only within the chat container
        }
    }, [messages])

    const onMessageSent = async (): Promise<void> => {
        const existingMessages = messages?.filter(
            (message) => message.content !== 'Unexpected error occurred. Please try again.',
        )
        setMessages([
            ...existingMessages,
            { content: question, role: ChatCompletionRequestMessageRole.USER },
        ])
        setQuestion('')
        setShowWaitingOnResponse(true)

        if (socket) {
            socket.emit('message', {
                message: question,
                campaign_id: campaignId,
            })
        }
    }

    const handleKeyUp = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && question.length) {
            onMessageSent()
            e.preventDefault()
            e.stopPropagation()
        }
    }

    const onAutoSuggestedOptionClick = (option: string): void => {
        const existingMessages = messages?.filter(
            (message) => message.content !== 'Unexpected error occurred. Please try again.',
        )
        setMessages([
            ...existingMessages,
            { content: option, role: ChatCompletionRequestMessageRole.USER },
        ])
        setShowWaitingOnResponse(true)
        if (socket) {
            socket.emit('message', {
                message: option,
                campaign_id: campaignId,
            })
        }
    }

    const onChatDeleted = async (): Promise<void> => {
        try {
            if (campaignId) {
                setShowSpinner(true)
                await ChatApi.delete(campaignId)
                setMessages([])
            }
        } finally {
            setShowSpinner(false)
        }
    }

    const filteredMessages = messages
        ?.filter((message: Message) => message.role != ChatCompletionRequestMessageRole.SYSTEM)
        ?.filter(
            (message: Message) =>
                !message.content.trim().startsWith('***Data set:') &&
                !message.content.trim().startsWith('***Full Data set:'),
        )

    return (
        <div className="mt-8 mb-4 w-full shadow-2xl rounded-[20px] relative">
            <div
                className="h-[700px] rounded-[20px] p-7 flex flex-col"
                style={{
                    background: 'linear-gradient(136deg, #FFF 60.8%, #F7FBFF 100%)',
                }}
            >
                <h2 className="text-center font-lato font-bold text-xl leading-[22px] mb-4">
                    Toni your AI Assistant
                </h2>
                <div className="flex flex-row justify-end">
                    <button
                        onClick={onChatDeleted}
                        disabled={showSpinner || showWaitingOnResponse}
                        className="bg-gray-300 mb-6 w-fit px-4 rounded-2xl"
                    >
                        Clear
                    </button>
                </div>
                <div
                    ref={chatContainerRef}
                    className="flex-grow flex flex-col gap-4 overflow-y-auto pb-10 mb-4" // Ensuring chat container is scrollable
                    style={{ maxHeight: '500px' }} // Adjust max height of the chat container as needed
                >
                    {filteredMessages.map((message: Message, index: number) => {
                        return (
                            <div
                                key={index}
                                ref={index === filteredMessages.length - 1 ? lastMessageRef : null}
                            >
                                <ChatMessage message={message} />
                            </div>
                        )
                    })}
                    {showWaitingOnResponse && <WaitingOnResponse />}
                </div>
                {messages?.length === 0 && (
                    <AutoSuggestedOptions
                        options={[
                            'What might I not know about my audience based on their voices?',
                            'Find the most recent clips capitalizing on audiences’ emotional connection.',
                            'How have our audiences’ emotions changed this month?',
                            'What campaign should I launch next based on these clips?',
                        ]}
                        onOptionClick={onAutoSuggestedOptionClick}
                    />
                )}
                <div className="flex flex-row relative mt-auto">
                    <TextareaAutosize
                        className="resize-none w-full rounded-lg bg-[#F4F4F4] border border-[#F4F4F4] text-sm font-lato text-[#101828] p-4 pr-12"
                        placeholder="Ask me anything"
                        value={question}
                        rows={1}
                        onChange={(e) => setQuestion(e.target.value)}
                        onKeyDown={(e: React.KeyboardEvent<HTMLTextAreaElement>) => handleKeyUp(e)}
                    />
                    <button
                        className="w-9 h-9 bg-[#007AFF] rounded-full flex items-center justify-center absolute right-2 top-2"
                        disabled={!question}
                        onClick={onMessageSent}
                    >
                        <img src="/asset/send-message-icon.svg" alt="send message" />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Chat
