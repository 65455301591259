enum ActionTypes {
    SET_INTERVIEW_IDS = 'SET_INTERVIEW_IDS',
    SET_SURVEY_IDS = 'SET_SURVEY_IDS',
    SET_RAG_SOURCE_PAGE = 'SET_RAG_SOURCE_PAGE',
}

interface SetInterviewIdsAction {
    type: ActionTypes.SET_INTERVIEW_IDS
    payload: {
        interviewIds: string[]
    }
}

interface SetSurveyIdsAction {
    type: ActionTypes.SET_SURVEY_IDS
    payload: {
        surveyIds: string[]
    }
}

interface SetRagSourcePageAction {
    type: ActionTypes.SET_RAG_SOURCE_PAGE
    payload: {
        sourcePage: string
    }
}

export type RagAction = SetInterviewIdsAction | SetSurveyIdsAction | SetRagSourcePageAction

export default ActionTypes
