import { ClipsResponse, ClipsTopicsResponse } from '../types/ClipsResponse'
import { SuggestVideoResponse } from '../types/Reels'
import ApiService from './apiService'
import { AxiosResponse } from 'axios'

export interface ClipsApi {
    getClipsTopicsByCampaignId(campaignId: string): Promise<AxiosResponse<ClipsTopicsResponse>>
    getClipsByCampaignIdAndTopicName(
        campaignId: string,
        topic: string,
        page?: number,
        perPage?: number,
    ): Promise<AxiosResponse<ClipsResponse>>
    getSelectedClipsByCampaignId(
        campaignId: string,
        page?: number,
        perPage?: number,
        selectedClipIds?: string[],
    ): Promise<AxiosResponse<ClipsResponse>>
    getSelectedClipsByCampaignIdAndTopicName(
        campaignId: string,
        topic: string,
        page?: number,
        perPage?: number,
    ): Promise<AxiosResponse<ClipsResponse>>
    editClip(clipId: string, from: number, to: number): Promise<AxiosResponse>
    updateSelectedStatus(clipId: string, selected: boolean): Promise<AxiosResponse>
    suggestVideo(campaignId: string, topic: string): Promise<AxiosResponse<SuggestVideoResponse>>
}

export const ClipsApi: ClipsApi = Object.freeze({
    getClipsTopicsByCampaignId(campaignId: string): Promise<AxiosResponse<ClipsTopicsResponse>> {
        return ApiService.get(`/clips/campaigns/${campaignId}/topics`)
    },
    getClipsByCampaignIdAndTopicName(
        campaignId: string,
        topic: string,
        page: number = 1,
        perPage: number = 20,
    ): Promise<AxiosResponse<ClipsResponse>> {
        return ApiService.get(`/clips/campaigns/${campaignId}/topics/${topic}`, null, {
            page,
            per_page: perPage,
        })
    },
    getSelectedClipsByCampaignId(
        campaignId: string,
        page?: number,
        perPage?: number,
        selectedClipIds?: string[],
    ): Promise<AxiosResponse<ClipsResponse>> {
        return ApiService.get(`/clips/campaigns/${campaignId}`, null, {
            page,
            per_page: perPage,
            selected_clip_ids: selectedClipIds,
        })
    },
    getSelectedClipsByCampaignIdAndTopicName(
        campaignId: string,
        topic: string,
        page?: number,
        perPage?: number,
    ): Promise<AxiosResponse<ClipsResponse>> {
        return ApiService.get(`/clips/campaigns/${campaignId}/topics/${topic}/selected`, null, {
            page,
            per_page: perPage,
        })
    },
    editClip(clipId: string, from: number, to: number): Promise<AxiosResponse> {
        return ApiService.post(`/clips/${clipId}/edit`, { from, to })
    },
    updateSelectedStatus(clipId: string, selected: boolean): Promise<AxiosResponse> {
        return ApiService.post(`/clips/${clipId}/set-is-selected`, { is_selected: selected })
    },
    suggestVideo(campaignId: string, topic: string): Promise<AxiosResponse<SuggestVideoResponse>> {
        return ApiService.post(`/reels/suggest-video`, { campaign_id: campaignId, topic: topic })
    },
})
