import { AuthenticationApi } from '../api/authentication'
import { WebAppAuthenticationApi } from '../api/webappAuthentication'
import ErrorMessage from '../components/ErrorMessage'
import PhoneNumberInput from '../components/PhoneNumberInput'
import Spinner from '../components/Spinner'
import { useUserStore } from '../context/user/store'
import { CookieKey } from '../enums/CookieKey'
import { Page } from '../enums/Page'
import { isPhoneNumberValid } from '../utils/isPhoneNumberValid'
import { setCookie } from '../utils/useCookies'
import { AxiosError } from 'axios/index'
import moment from 'moment'
import { ChangeEvent, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'

const Signin = (): JSX.Element => {
    const [phoneNumber, setPhoneNumber] = useState<string>('')
    const [smsCode, setSmsCode] = useState<string>('')
    const [smsCodeError, setSmsCodeError] = useState<string | null>(null)
    const [isOtpCodeSent, setIsOtpCodeSent] = useState<boolean>(false)
    const [isOtpCodeResent, setIsOtpCodeResent] = useState<boolean>(false)
    const [showSpinner, setShowSpinner] = useState<boolean>(false)

    const {
        state: { user },
    } = useUserStore()

    const navigate = useNavigate()

    if (user) {
        return <Navigate to={Page.HOME} />
    }

    const signIn = async (): Promise<void> => {
        try {
            setShowSpinner(true)
            setSmsCodeError(null)
            const response = await AuthenticationApi.login(smsCode, phoneNumber)
            setCookie(CookieKey.JWT_TOKEN, response.data.token, {
                expires: moment().add('30', 'minutes').toDate(),
            })
            setCookie(CookieKey.REFRESH_TOKEN, response.data.refresh_token, {
                expires: moment().add('1', 'day').toDate(),
            })
            navigate(Page.SELECT_PROFILE)
        } catch (e) {
            const error = e as AxiosError<any>
            if (error.response?.status === 403) {
                setSmsCodeError(String(error.response?.data?.message ?? 'Invalid code'))
            } else {
                setSmsCodeError('Invalid code')
            }
        } finally {
            setShowSpinner(false)
        }
    }

    const getACode = async (): Promise<void> => {
        await WebAppAuthenticationApi.sendOtpCode(phoneNumber)
        setIsOtpCodeSent(true)
    }

    const reSendCode = async (): Promise<void> => {
        setIsOtpCodeResent(true)
        await getACode()
    }

    const isGetACodeEnabled: boolean =
        !!phoneNumber.length && isPhoneNumberValid(phoneNumber) && !isOtpCodeSent

    const isSignInEnabled: boolean = isPhoneNumberValid(phoneNumber) && !!smsCode.length

    return (
        <div className="flex flex-row items-center justify-center h-screen">
            <div>{showSpinner && <Spinner />}</div>
            <div className="flex flex-col px-[12px] sm:px-0 mt-[-80px] pb-[20px] md:pb-[40px]">
                <h1 className="mb-10 text-[#007AFF] text-4xl">Sign in</h1>
                <div className="flex mb-4 lg:flex-row flex-col gap-4">
                    <PhoneNumberInput onChange={(phone) => setPhoneNumber(phone)} />
                    <button
                        className={`${
                            isGetACodeEnabled
                                ? 'text-[#BFDEFF] bg-[#007AFF]'
                                : 'text-white bg-[#add4ff]'
                        } rounded-[10px] font-bold text-base leading-5 px-5 py-2.5`}
                        disabled={!isGetACodeEnabled}
                        onClick={getACode}
                    >
                        Get a code
                    </button>
                </div>
                {/* <p className="text-base text-[#0D0D0D] font-normal">
                    Don’t want to use your personal phone number? Use a Google Voice Number
                </p> */}
                <div className="flex flex-col sm:flex-row mt-1 gap-5">
                    <input
                        className="text-center text-base leading-5 px-5 py-2.5 text-[#001F40] bg-[#e9e9ed] rounded-[10px] focus:outline focus:outline-[#D9D9D9]"
                        placeholder="Enter code"
                        value={smsCode}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setSmsCode(event.target.value)
                        }}
                    />
                    <button
                        className={`px-5 py-2 rounded-[10px] font-bold text-xs ${
                            isOtpCodeSent && !isOtpCodeResent
                                ? 'text-[#001F40]'
                                : 'bg-white text text-[#AFAFAF]'
                        }`}
                        onClick={reSendCode}
                        disabled={!isOtpCodeSent && !isOtpCodeResent}
                    >
                        Resend
                    </button>
                </div>
                <div className="h-8 flex items-center">
                    {smsCodeError && <ErrorMessage error={smsCodeError} />}
                </div>
                <div className="flex flex-row mb-5">
                    <button
                        className={`px-5 py-3 rounded-[10px] font-bold text-sm ${
                            isSignInEnabled
                                ? 'text-[#BFDEFF] bg-[#007AFF]'
                                : 'text-white bg-[#add4ff]'
                        }`}
                        disabled={!isSignInEnabled}
                        onClick={signIn}
                    >
                        Sign in
                    </button>
                </div>
                <p className="text-base text-[#007AFF] font-normal">
                    <a
                        href="https://airtable.com/appjBs0iqpqWSdyUv/shronMasmIRztbrTX"
                        target="_blank"
                        className="underline"
                    >
                        Sign up
                    </a>
                </p>
            </div>
        </div>
    )
}

export default Signin
