// @ts-nocheck
import { useUserStore } from '../context/user/store'
import { Question } from '../types/Question'
import { toPng } from 'html-to-image'
import QRCode from 'qrcode.react'
import { useRef } from 'react'

interface QrCodeModalProps {
    question: Question
    onClose: () => void
}

const QrCodeModal = ({ onClose, question }: QrCodeModalProps): JSX.Element => {
    const {
        state: { user },
    } = useUserStore()

    const qrCodeRef = useRef<any>(null)

    const downloadTopTopicsSentimentChart = () => {
        if (qrCodeRef.current === null) {
            return
        }

        const filter = (node: HTMLElement) => {
            const exclusionClasses = ['remove-me', 'secret-div']
            return !exclusionClasses.some((classname) => node.classList?.contains(classname))
        }

        toPng(qrCodeRef.current, {
            cacheBust: true,
            filter: filter,
            backgroundColor: 'transparent',
            style: {
                transform: 'scale(0.9)',
                transformOrigin: 'center',
            },
        })
            .then((dataUrl) => {
                const link = document.createElement('a')
                link.download = `qr_code.png`
                link.href = dataUrl
                link.click()
            })
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.log(err)
            })
    }

    return (
        <div className="fixed top-0 left-0 w-full h-full bg-[#00000080] flex flex-col justify-center items-center z-[99999]">
            <div className="bg-white w-full max-w-[90%] sm:max-w-[80%] md:max-w-[620px] rounded-[30px] flex flex-col items-center">
                <button className="flex justify-end pt-2 pr-2 w-full" onClick={onClose}>
                    <img src="/asset/close-modal-icon.svg" alt="Close" />
                </button>
                <div className=" px-[110px] mt-[50px] mb-[75px] flex flex-col items-center">
                    <p className="text-[#007AFF] text-2xl font-semibold mb-4 text-center three-lines-clamp">
                        {question.question}
                    </p>
                    <div className="p-10" ref={qrCodeRef}>
                        <QRCode
                            value={encodeURI(
                                `${window.location.origin}/${user.username}/${question.slug}`,
                            )}
                            size={300}
                        />
                    </div>
                    <div className="flex flex-col">
                        <button
                            className="text-[#007AFF] text-base mt-3 text-center"
                            onClick={downloadTopTopicsSentimentChart}
                        >
                            Download QR code
                        </button>
                        <a
                            className="text-[#007AFF] text-base text-center"
                            target="_blank"
                            href={`${window.location.origin}/${user.username}/${question.slug}`}
                        >
                            Open link
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QrCodeModal
