import { ClipContentType } from '../enums/ClipContentType'
import { Clip } from '../types/Clip'
import { toMMHH } from '../utils/toMMHH'
import { Tooltip } from 'flowbite-react'
import moment from 'moment'

interface CreateReelPageSingleClipItemProps {
    clip: Clip
    isSelected: boolean
    onToggleSelect: () => void
    onEdit: () => void
    index: number
    isSelectable?: boolean
}

const DragAndDropIcon = (): JSX.Element => {
    return (
        <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="1" cy="1" r="1" fill="#8092A5" />
            <circle cx="5" cy="1" r="1" fill="#8092A5" />
            <circle cx="1" cy="5" r="1" fill="#8092A5" />
            <circle cx="5" cy="5" r="1" fill="#8092A5" />
        </svg>
    )
}

const RemoveClipIcon = (): JSX.Element => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.5 10.001C17.5 5.86035 14.1406 2.50098 10 2.50098C5.85938 2.50098 2.5 5.86035 2.5 10.001C2.5 14.1416 5.85938 17.501 10 17.501C14.1406 17.501 17.5 14.1416 17.5 10.001Z"
                stroke="#8092A5"
                strokeWidth="1.00189"
                strokeMiterlimit="10"
            />
            <path
                d="M13.1259 10.001H6.875"
                stroke="#8092A5"
                strokeWidth="1.00189"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

const SelectClipIcon = (): JSX.Element => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10 6.87598V13.126M13.125 10.001H6.875M17.5 10.001C17.5 5.86035 14.1406 2.50098 10 2.50098C5.85938 2.50098 2.5 5.86035 2.5 10.001C2.5 14.1416 5.85938 17.501 10 17.501C14.1406 17.501 17.5 14.1416 17.5 10.001Z"
                stroke="#8092A5"
                strokeWidth="1.00189"
                strokeLinecap="round"
            />
        </svg>
    )
}

const CreateReelPageSingleClipItem = ({
    clip,
    isSelected,
    onToggleSelect,
    onEdit,
    index,
    isSelectable,
}: CreateReelPageSingleClipItemProps): JSX.Element => {
    const isVideo = !!clip.video_url
    let tooltipContent = ''
    if (clip?.source?.type === ClipContentType.SURVEY_RESPONSE) {
        tooltipContent = `This clip is from a survey response titled ${
            clip?.source?.source_title
        } from ${clip?.source?.author} recorder ${
            moment(new Date()).diff(clip?.source?.created_at, 'hours') > 72
                ? `on ${moment(clip?.source?.created_at).format('LL')}`
                : moment(clip?.source?.created_at).fromNow()
        }`
    } else if (clip?.source?.type === ClipContentType.CONVERSATION) {
        tooltipContent = `This clip is from a conversation titled ${
            clip?.source?.source_title
        } with ${clip?.source?.author} recorder ${
            moment(new Date()).diff(clip?.source?.created_at, 'hours') > 72
                ? `on ${moment(clip?.source?.created_at).format('LL')}`
                : moment(clip?.source?.created_at).fromNow()
        }`
    }
    return (
        <div
            className={`flex flex-row items-center justify-between gap-4 pr-5 ${
                !isSelectable ? 'pointer-events-none bg-gray-200 cursor-default' : 'cursor-grab'
            }`}
        >
            <div className="px-4 py-5 hover:bg-[#EFEFEF] rounded-lg w-full flex flex-row items-center justify-between gap-6">
                <div className="flex flex-row w-full items-center justify-between">
                    <div className="flex flex-row items-center">
                        <div className="mr-4">
                            <DragAndDropIcon />
                        </div>
                        <div className="text-[#8092A5] font-lato text-lg font-bold mr-4">
                            {isSelected && <span>{index}</span>}
                        </div>
                        <p className="font-bold font-lato text-sm 2xl:text-base">{clip.title}</p>
                    </div>
                    <div>
                        <p>
                            {clip.interview_response?.responders[0].first_name}{' '}
                            {clip.interview_response?.responders[0].last_name}
                            {clip.interview_response?.responders &&
                            clip.interview_response?.responders?.length > 1
                                ? `...`
                                : ''}
                        </p>
                    </div>
                </div>
                <div className="ml-[22px] flex flex-row items-center">
                    <p className="text-xs 2xl:text-base font-lato font-bold mr-4">
                        {toMMHH(clip?.duration ?? 0)}
                    </p>
                    {clip?.source && (
                        <Tooltip
                            content={tooltipContent}
                            className="text-sm p-5 py-2 max-w-[300px] text-black font-bold font-lato bg-[#f7f7f7]"
                            placement="right"
                            theme={{
                                base: 'absolute inline-block z-10 rounded-lg py-2 px-3 shadow-sm',
                                arrow: {
                                    base: 'display-none',
                                },
                            }}
                        >
                            <p className="text-[#001F40] text-sm text-black rounded-[30px] bg-[#D9D9D969] py-2 px-4">
                                Source
                            </p>
                        </Tooltip>
                    )}
                    <div
                        className="ml-[22px] flex justify-start items-center"
                        title={isVideo ? 'Video' : 'Audio'}
                    >
                        {isVideo && (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                width="20"
                                height="20"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z"
                                />
                            </svg>
                        )}
                        {!isVideo && (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                width="20"
                                height="20"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z"
                                />
                            </svg>
                        )}
                    </div>
                    <div className="ml-[22px] cursor-pointer flex items-center justify-center">
                        <button onClick={onEdit} disabled={isSelectable === false}>
                            <svg
                                width="19"
                                height="19"
                                viewBox="0 0 19 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    x="14.8496"
                                    width="5"
                                    height="14"
                                    rx="1"
                                    transform="rotate(45 14.8496 0)"
                                    fill="#8092A5"
                                />
                                <path
                                    d="M3.28128 12.081C3.39881 11.7284 3.84635 11.6227 4.10917 11.8855L6.50058 14.2769C6.76341 14.5398 6.65776 14.9873 6.30514 15.1048L2.71803 16.3005C2.32715 16.4308 1.95528 16.059 2.08557 15.6681L3.28128 12.081Z"
                                    fill="#8092A5"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <button onClick={onToggleSelect} disabled={isSelectable === false}>
                {isSelected ? <RemoveClipIcon /> : <SelectClipIcon />}
            </button>
        </div>
    )
}

export default CreateReelPageSingleClipItem
