import { useQuestionStore } from '../context/question/store'
import { Response, ResponseNote } from '../pages/QuestionResponder'
import { Question } from '../types/Question'
import { QuestionTypeSettings } from '../types/QuestionType'
import FreeTextResponder from './FreeTextResponder'
import VoiceOnlyResponder from './VoiceOnlyResponder'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

interface VoiceTextResponderProps {
    setResponses?: (value: Response[] | ((prevVar: Response[]) => Response[])) => void
    setRequiredToAllResponds?: (v: boolean | ((prevVar: boolean) => boolean)) => void
    setQuestionIndex?: (index: number | ((prevVar: number) => number)) => void
    setIsRecordingSupported?: (v: boolean | ((prevVar: boolean) => boolean)) => void
    setResponseNotes?: (
        responseNotes: ResponseNote[] | ((prevVar: ResponseNote[]) => ResponseNote[]),
    ) => void
    selectedQuestion?: Question | null
    questionIndex?: number
    responseNoteForSelectedQuestion?: ResponseNote | undefined
    questions?: Question[]
    disabled?: boolean
    showNotes?: boolean
    setIsRecording?: (v: boolean | ((prevVar: boolean) => boolean)) => void
    isReRecording?: boolean
    isDarkModeEnabled?: boolean
}

const VoiceTextResponder = ({
    setResponses,
    setRequiredToAllResponds,
    setQuestionIndex,
    setIsRecordingSupported,
    setResponseNotes,
    selectedQuestion,
    questionIndex,
    responseNoteForSelectedQuestion,
    questions,
    disabled,
    showNotes = false,
    setIsRecording,
    isReRecording = false,
    isDarkModeEnabled = false,
}: VoiceTextResponderProps) => {
    const {
        state: { accepted },
        setOpenFooter,
        setQuestionResponderLanded,
    } = useQuestionStore()
    const { t } = useTranslation()

    const handleAudioSelection = () => {
        setResponseNotes &&
            setResponseNotes((responseNotes) => {
                let resNotes = [...responseNotes]
                resNotes.splice(
                    responseNotes.findIndex(
                        (responseNote) => responseNote.questionId === selectedQuestion?.id,
                    ),
                    1,
                    {
                        ...(responseNoteForSelectedQuestion as ResponseNote),
                        selectedQuestionType: QuestionTypeSettings.VOICE_ONLY,
                    },
                )
                return resNotes
            })
    }

    useEffect(() => {
        // eslint-disable-next-line no-console
        console.log('isReRecording', isReRecording)
        if (isReRecording) {
            handleAudioSelection()
        }
    }, [isReRecording])

    return (
        <>
            {!responseNoteForSelectedQuestion?.selectedQuestionType && (
                <div className="flex flex-col items-center mt-8 px-2">
                    <p
                        className={`${
                            isDarkModeEnabled ? 'text-[#FFFFFF]' : 'text-[#0C1B2C]'
                        } text-sm`}
                    >
                        {t('question_responder_page.select_how_you_want_to_answer')}
                    </p>

                    <div className="flex flex-col xs:flex-row items-center gap-6 pt-4">
                        <button
                            className="flex flex-col items-center"
                            onClick={() => {
                                if (!accepted) {
                                    setQuestionResponderLanded(false)
                                    setOpenFooter(true)
                                    return
                                }
                                handleAudioSelection()
                            }}
                            disabled={disabled}
                        >
                            <div className="relative rounded-full w-[126px] h-[126px] bg-[var(--blue-600,_#1570EF)] flex items-center justify-center">
                                <img
                                    src="/asset/microphone.svg"
                                    className="absolute w-[40px] h-[40px]"
                                />
                            </div>
                            <p
                                className={`${
                                    isDarkModeEnabled ? 'text-[#FFFFFF]' : 'text-[#001F40]'
                                } text-[13px] pt-4`}
                            >
                                {t('question_responder_page.record_answer')}
                            </p>
                        </button>

                        <div className="flex flex-row xs:flex-col items-center gap-3">
                            <div className="w-[80px] xs:w-[1px] h-[1px] xs:h-[78px] bg-[#007AFF]" />
                            <p
                                className={`${
                                    isDarkModeEnabled ? 'text-[#FFFFFF]' : 'text-[#001F40]'
                                }`}
                            >
                                {t('question_responder_page.or')}
                            </p>
                            <div className="w-[80px] xs:w-[1px] h-[1px] xs:h-[78px] bg-[#007AFF]" />
                        </div>
                        <button
                            className="flex flex-col items-center"
                            onClick={() => {
                                if (!accepted) {
                                    setQuestionResponderLanded(false)
                                    setOpenFooter(true)
                                    return
                                }
                                setResponseNotes &&
                                    setResponseNotes((responseNotes) => {
                                        let resNotes = [...responseNotes]
                                        resNotes.splice(
                                            responseNotes.findIndex(
                                                (responseNote) =>
                                                    responseNote.questionId ===
                                                    selectedQuestion?.id,
                                            ),
                                            1,
                                            {
                                                ...(responseNoteForSelectedQuestion as ResponseNote),
                                                selectedQuestionType:
                                                    QuestionTypeSettings.FREE_TEXT,
                                            },
                                        )
                                        return resNotes
                                    })
                            }}
                            disabled={disabled}
                        >
                            <div className="relative rounded-full w-[126px] h-[126px] bg-white border-[2px] border-[var(--blue-600,_#1570EF)] flex items-center justify-center">
                                <img src="/asset/message-circle-blue.svg" className="absolute" />
                            </div>
                            <p
                                className={`${
                                    isDarkModeEnabled ? 'text-[#FFFFFF]' : 'text-[#001F40]'
                                } text-[13px] pt-4`}
                            >
                                {t('question_responder_page.type_answer')}
                            </p>
                        </button>
                    </div>
                </div>
            )}
            {responseNoteForSelectedQuestion?.selectedQuestionType ===
                QuestionTypeSettings.VOICE_ONLY && (
                <VoiceOnlyResponder
                    setResponses={setResponses}
                    setRequiredToAllResponds={setRequiredToAllResponds}
                    setQuestionIndex={setQuestionIndex}
                    setIsRecordingSupported={setIsRecordingSupported}
                    setResponseNotes={setResponseNotes}
                    selectedQuestion={selectedQuestion}
                    questionIndex={questionIndex}
                    responseNoteForSelectedQuestion={responseNoteForSelectedQuestion}
                    questions={questions}
                    showNotes={showNotes}
                    setIsRecording={setIsRecording}
                    isDarkModeEnabled={isDarkModeEnabled}
                />
            )}
            {responseNoteForSelectedQuestion?.selectedQuestionType ===
                QuestionTypeSettings.FREE_TEXT && (
                <FreeTextResponder
                    setResponses={setResponses}
                    setRequiredToAllResponds={setRequiredToAllResponds}
                    setQuestionIndex={setQuestionIndex}
                    setIsRecordingSupported={setIsRecordingSupported}
                    setResponseNotes={setResponseNotes}
                    selectedQuestion={selectedQuestion}
                    questionIndex={questionIndex}
                    responseNoteForSelectedQuestion={responseNoteForSelectedQuestion}
                    questions={questions}
                    isDarkModeEnabled={isDarkModeEnabled}
                />
            )}
        </>
    )
}

export default VoiceTextResponder
