import React from 'react'

interface TextWithLinksProps {
    text: string
    className?: string
    linkClassName?: string
}

const TextWithLinks = ({ text, className, linkClassName }: TextWithLinksProps) => {
    // Regular expression to match URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g

    // Split the text into an array of text and URL parts
    const parts = text.split(urlRegex)
    let periodAtEndExists = false

    // Map the parts to JSX elements
    const elements = parts.map((part, index) => {
        if (part.match(urlRegex)) {
            if (part.endsWith('.')) {
                part = part.substring(0, part.length - 1)
                periodAtEndExists = true
            }
            // If the part is a URL, render it as an <a> element
            return (
                <>
                    <a
                        key={index}
                        href={part}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={linkClassName}
                    >
                        {part}
                    </a>
                    {periodAtEndExists ? '.' : ''}
                </>
            )
        } else {
            // Otherwise, render the regular text
            return (
                <span className={className} key={index}>
                    {part}
                </span>
            )
        }
    })

    return <pre className={className}>{elements}</pre>
}

export default TextWithLinks
