import { CreatedQuestionsResponse } from '../../types/CreatedQuestionsResponse'
import { Question } from '../../types/Question'

enum ActionTypes {
    SET_TOOLTIP_HOVER = 'SET_TOOLTIP_HOVER',
    SET_LINK_TITLE = 'SET_LINK_TITLE',
    SET_CREATED_QUESTIONS = 'SET_CREATED_QUESTIONS',
    SET_QUESTIONS = 'SET_QUESTIONS',
    SET_ACCEPTED = 'SET_ACCEPTED',
    SET_SHOW_TIP = 'SET_SHOW_TIP',
    SET_OPEN_FOOTER = 'SET_OPEN_FOOTER',
    SET_QUESTION_RESPONDER_LANDED = 'SET_QUESTION_RESPONDER_LANDED',
}

interface SetTooltipHoverAction {
    type: ActionTypes.SET_TOOLTIP_HOVER
    payload: {
        status: boolean
    }
}

interface SetCreatedQuestionsAction {
    type: ActionTypes.SET_CREATED_QUESTIONS
    payload: {
        data: CreatedQuestionsResponse | null
    }
}

interface SetLinkTitleAction {
    type: ActionTypes.SET_LINK_TITLE
    payload: {
        linkTitle: string
    }
}

interface SetQuestionsAction {
    type: ActionTypes.SET_QUESTIONS
    payload: {
        questions: Question[]
    }
}

interface SetAcceptedAction {
    type: ActionTypes.SET_ACCEPTED
    payload: {
        accepted: boolean
    }
}

interface SetShowTipAction {
    type: ActionTypes.SET_SHOW_TIP
    payload: {
        showTip: boolean
    }
}

interface SetOpenFooterAction {
    type: ActionTypes.SET_OPEN_FOOTER
    payload: {
        openFooter: boolean
    }
}

interface SetQuestionResponderLandedAction {
    type: ActionTypes.SET_QUESTION_RESPONDER_LANDED
    payload: {
        questionResponderLanded: boolean
    }
}

export type QuestionAction =
    | SetTooltipHoverAction
    | SetCreatedQuestionsAction
    | SetLinkTitleAction
    | SetQuestionsAction
    | SetAcceptedAction
    | SetShowTipAction
    | SetOpenFooterAction
    | SetQuestionResponderLandedAction

export default ActionTypes
