import { UserApi } from '../api/user'
import { WebAppAuthenticationApi } from '../api/webappAuthentication'
import ErrorMessage from '../components/ErrorMessage'
import PhoneNumberInput from '../components/PhoneNumberInput'
import Spinner from '../components/Spinner'
import { useUserStore } from '../context/user/store'
import { CookieKey } from '../enums/CookieKey'
import { Page } from '../enums/Page'
import { isPhoneNumberValid } from '../utils/isPhoneNumberValid'
import { setCookie } from '../utils/useCookies'
import { AxiosError } from 'axios/index'
import moment from 'moment'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const Signup = (): JSX.Element => {
    const [inviteCode, setInviteCode] = useState<string>('')
    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [phoneNumber, setPhoneNumber] = useState<string>('')
    const [smsCode, setSmsCode] = useState<string>('')
    const [smsCodeError, setSmsCodeError] = useState<string | null>(null)
    const [isOtpCodeSent, setIsOtpCodeSent] = useState<boolean>(false)
    const [isOtpCodeResent, setIsOtpCodeResent] = useState<boolean>(false)
    const [showSpinner, setShowSpinner] = useState<boolean>(false)

    const { setUser } = useUserStore()

    const navigate = useNavigate()
    const { state } = useLocation()

    useEffect(() => {
        if (state?.user) {
            setFirstName(state?.user.first_name)
            setLastName(state?.user.last_name)
        }
    }, [])

    const signUp = async (): Promise<void> => {
        try {
            setShowSpinner(true)
            setSmsCodeError(null)
            if (!smsCode) {
                setSmsCodeError('Please specify an sms code.')
            } else {
                const response = await WebAppAuthenticationApi.signup(
                    smsCode,
                    phoneNumber,
                    firstName,
                    lastName,
                    undefined,
                    undefined,
                    inviteCode,
                )
                if (response.data.authenticated) {
                    setCookie(CookieKey.JWT_TOKEN, response.data.token, {
                        expires: moment().add('30', 'minutes').toDate(),
                    })
                    setCookie(CookieKey.REFRESH_TOKEN, response.data.refresh_token, {
                        expires: moment().add('1', 'day').toDate(),
                    })
                    const userResponse = await UserApi.getAuthenticatedUser()
                    if (userResponse) {
                        setUser(userResponse.data)
                        navigate(Page.SELECT_PROFILE)
                    }
                }
            }
        } catch (e) {
            const error = e as AxiosError<any>
            if (error.response?.status === 403) {
                setSmsCodeError(String(error.response?.data?.message ?? 'Invalid code'))
            } else {
                setSmsCodeError('Invalid code')
            }
        } finally {
            setShowSpinner(false)
        }
    }

    const getACode = async (): Promise<void> => {
        await WebAppAuthenticationApi.sendOtpCode(phoneNumber)
        setIsOtpCodeSent(true)
    }

    const reSendCode = async (): Promise<void> => {
        setIsOtpCodeResent(true)
        await getACode()
    }

    const isGetACodeEnabled: boolean =
        !!phoneNumber.length && isPhoneNumberValid(phoneNumber) && !isOtpCodeSent

    const fullName = useMemo(
        () => [firstName.trim(), lastName.trim()].join(' ').trim(),
        [firstName, lastName],
    )

    const isSignUpEnabled: boolean =
        isPhoneNumberValid(phoneNumber) && !!smsCode.length && !!fullName

    return (
        <div className="flex flex-row items-center justify-center h-screen">
            <div>{showSpinner && <Spinner />}</div>
            <div className="flex flex-col px-[12px] sm:px-0 pb-[20px] md:pb-[40px]">
                <h1 className="mb-8 text-[#007AFF] text-4xl">Sign up</h1>
                <p className="text-base text-[#0D0D0D] font-normal mb-3">
                    Your URL for generating links
                </p>
                <div className="text-[#001F40] text-lg font-bold bg-[rgba(107,_178,_255,_0.37)] rounded-2xl sm:rounded-full py-2 px-5 sm:px-8 mb-10">
                    nodeio.app<span className="mx-[1px]">/</span>
                    <span className={`${fullName === '' ? 'text-[#8E8D93]' : ''}`}>
                        {fullName.split(' ').join('-') || 'your-organization-name'}
                    </span>
                </div>
                <p className="text-[#0D0D0D] text-base mb-2">
                    If provided to you, enter your invite code below.
                </p>
                <div className="flex flex-col sm:flex-row item-center gap-4 mb-10">
                    <input
                        className="text-center text-base leading-5 px-5 py-2.5 text-[#001F40] bg-[#e9e9ed] rounded-[10px] focus:outline focus:outline-[#D9D9D9] flex-[0.2]"
                        placeholder="Invite Code (Optional)"
                        value={inviteCode}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setInviteCode(event.target.value)
                        }}
                    />
                </div>
                <div className="flex flex-col sm:flex-row item-center gap-4 mb-4">
                    <input
                        className="text-center text-base leading-5 px-5 py-2.5 text-[#001F40] bg-[#e9e9ed] rounded-[10px] focus:outline focus:outline-[#D9D9D9] min-w-[250px] flex-[0.7]"
                        placeholder="First Name or Organization"
                        value={firstName}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setFirstName(event.target.value)
                        }}
                    />
                    <input
                        className="text-center text-base leading-5 px-5 py-2.5 text-[#001F40] bg-[#e9e9ed] rounded-[10px] focus:outline focus:outline-[#D9D9D9] flex-[0.2]"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setLastName(event.target.value)
                        }}
                    />
                </div>
                <div className="flex mb-2.5 lg:flex-row flex-col gap-4">
                    <PhoneNumberInput onChange={(phone) => setPhoneNumber(phone)} />
                    <button
                        className={`${
                            isGetACodeEnabled
                                ? 'text-[#BFDEFF] bg-[#007AFF]'
                                : 'text-white bg-[#add4ff]'
                        } rounded-[10px] font-bold text-base leading-5 px-5 py-2.5`}
                        disabled={!isGetACodeEnabled}
                        onClick={getACode}
                    >
                        Get a code
                    </button>
                </div>
                <p className="text-[#0D0D0D] text-base mb-5">
                    For security purposes only. No spam from us.
                </p>
                <div className="flex flex-col sm:flex-row mt-1 gap-5">
                    <input
                        className="text-center text-base leading-5 px-5 py-2.5 text-[#001F40] bg-[#e9e9ed] rounded-[10px] focus:outline focus:outline-[#D9D9D9]"
                        placeholder="Enter code"
                        value={smsCode}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setSmsCode(event.target.value)
                        }}
                    />
                    <button
                        className={`px-5 py-2 rounded-[10px] font-bold text-xs ${
                            isOtpCodeSent && !isOtpCodeResent
                                ? 'text-[#001F40]'
                                : 'bg-white text text-[#AFAFAF]'
                        }`}
                        onClick={reSendCode}
                        disabled={!isOtpCodeSent && !isOtpCodeResent}
                    >
                        Resend
                    </button>
                </div>
                <div className="h-8 flex items-center">
                    {smsCodeError && <ErrorMessage error={smsCodeError} />}
                </div>
                <div className="flex flex-row mt-3 mb-5 justify-center">
                    <button
                        className={`px-5 py-3 w-full sm:w-auto rounded-[10px] font-bold text-sm ${
                            isSignUpEnabled
                                ? 'text-[#BFDEFF] bg-[#007AFF]'
                                : 'text-white bg-[#add4ff]'
                        }`}
                        disabled={!isSignUpEnabled}
                        onClick={signUp}
                    >
                        Get started
                    </button>
                </div>
                <p className="text-base text-[#0D0D0D] font-normal self-center">
                    <a href="/signin" className="underline text-[#007AFF] font-bold">
                        Sign in
                    </a>
                </p>
            </div>
        </div>
    )
}

export default Signup
