// @ts-nocheck
import { CreatedQuestionsResponse } from '../../types/CreatedQuestionsResponse'
import { Question } from '../../types/Question'
import {
    setAcceptedAction,
    setCreatedQuestionsAction,
    setLinkTitleAction,
    setOpenFooterAction,
    setQuestionResponderLandedAction,
    setQuestionsAction,
    setShowTipAction,
    setTooltipHoverAction,
} from './actions'
import { initialQuestionStoreState, questionReducer } from './reducer'
import { createContext, useContext, useReducer } from 'react'

export const QuestionStore = createContext()

export const useQuestionStore = (): PageBannerContext => useContext(QuestionStore)

export const QuestionStoreProvider = ({ children }): any => {
    const [state, dispatch] = useReducer(questionReducer, initialQuestionStoreState)

    const setToolTipHover = (status: boolean) => {
        setTooltipHoverAction(status, dispatch)
    }

    const setCreatedQuestions = (data: CreatedQuestionsResponse | null) => {
        setCreatedQuestionsAction(data, dispatch)
    }

    const setLinkTitle = (text: string) => {
        setLinkTitleAction(text, dispatch)
    }

    const setQuestions = (questions: Question[]) => {
        setQuestionsAction(questions, dispatch)
    }

    const setAccepted = (accepted: boolean) => {
        setAcceptedAction(accepted, dispatch)
    }

    const setShowTip = (showTip: boolean) => {
        setShowTipAction(showTip, dispatch)
    }

    const setOpenFooter = (openFooter: boolean) => {
        setOpenFooterAction(openFooter, dispatch)
    }

    const setQuestionResponderLanded = (questionResponderLanded: boolean) => {
        setQuestionResponderLandedAction(questionResponderLanded, dispatch)
    }

    return (
        <QuestionStore.Provider
            value={{
                state: state,
                setToolTipHover,
                setCreatedQuestions,
                setLinkTitle,
                setQuestions,
                setAccepted,
                setShowTip,
                setOpenFooter,
                setQuestionResponderLanded,
            }}
        >
            {children}
        </QuestionStore.Provider>
    )
}
