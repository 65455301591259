import { QuestionApi } from '../../../api/question'
import { QuestionNodeiosResponse } from '../../../types/QuestionNodeiosResponse'
import Spinner from '../../Spinner'
import ResponseMVPCardStripe from '../ResponseMVPCardStripe'
import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { toast } from 'react-toastify'

interface MVPCardListModalProps {
    open: boolean
    onToggle: () => void
    onSuccess: (mvps: string[]) => void
    questionId?: string
    mvps?: any
}

const MVPCardListModal = ({
    open,
    onToggle,
    questionId,
    mvps,
    onSuccess,
}: MVPCardListModalProps) => {
    const [nodeiosResponse, setNodeiosResponse] = useState<QuestionNodeiosResponse | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [scrollPosition, setScrollTopPosition] = useState<number>(0)
    const fetchRespondingNodeios = async (questionId: string, page: number) => {
        try {
            const response = await QuestionApi.getRespondingNodeiosByQuestionId(
                questionId,
                page,
                10000,
            )
            setNodeiosResponse({
                ...response.data,
                nodeios: [
                    ...(nodeiosResponse?.nodeios || []),
                    ...response.data.nodeios.map((nodeio) => ({
                        ...nodeio,
                        checked: mvps?.includes(nodeio.id),
                    })),
                ],
            })
        } catch (e) {
            toast.error('Data fetch failed!')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (open) {
            setLoading(true)
            fetchRespondingNodeios(questionId as string, 1)
        }
    }, [questionId, open])

    const handleChangePage = (page: number) => {
        fetchRespondingNodeios(questionId as string, page)
    }

    const handleSelect = (id: string) => {
        setNodeiosResponse(
            (res: QuestionNodeiosResponse | null) =>
                ({
                    ...res,
                    nodeios: res?.nodeios?.map((item) =>
                        item.id === id ? { ...item, checked: !item.checked } : item,
                    ),
                } as QuestionNodeiosResponse),
        )
        setTimeout(() => {
            document.getElementById('scrollableDiv')?.scrollTo(0, scrollPosition)
        }, 0)
    }

    return (
        <>
            {loading ? (
                <Spinner />
            ) : (
                <div
                    className={`modal-base-class fixed top-0 left-0 w-full h-full bg-[#000000c7] flex justify-end items-center z-20`}
                >
                    <div
                        className={`relative h-full w-full lg:w-7/12 bg-white p-6 py-5 lg:rounded-l-xl flex flex-col z-10 ${
                            open ? 'modal-enter' : 'modal-exit'
                        }`}
                        style={{ boxShadow: '0px 4px 4px 0px #00000040' }}
                    >
                        <div className="h-full flex flex-col">
                            <div className="flex justify-between items-center">
                                <h2 className="text-[#101828] text-2xl font-semibold">
                                    Nodeios ({nodeiosResponse?.pagination.total_items})
                                </h2>

                                <button
                                    onClick={() => {
                                        setNodeiosResponse(null)
                                        onToggle()
                                    }}
                                    className="p-2.5"
                                >
                                    <img src="/asset/close.svg" />
                                </button>
                            </div>
                            <p className="text-sm pb-2">
                                Select from all audio responses and the most impactful statement
                                from each one
                            </p>
                            <div className="mt-2 flex items-center gap-3">
                                <button
                                    className={`text-sm font-semibold flex items-center p-2 px-4 rounded-2xl border-2 border-[#007AFF] text-[#007AFF]`}
                                    onClick={() => {
                                        onSuccess(
                                            nodeiosResponse?.nodeios
                                                .filter((nodeio) => nodeio.checked)
                                                .map((nodeio) => nodeio.id) as string[],
                                        )
                                    }}
                                >
                                    Update Soundbites
                                </button>
                                <p className="text-sm text-black">
                                    Selected{' '}
                                    {nodeiosResponse?.nodeios.filter((item) => item.checked).length}{' '}
                                    Soundbites
                                </p>
                            </div>
                            <div
                                className="flex-1 mt-3 flex flex-col overflow-y-auto"
                                id="scrollableDiv"
                                onScroll={(e) => {
                                    setScrollTopPosition(e.currentTarget.scrollTop)
                                }}
                            >
                                <InfiniteScroll
                                    dataLength={nodeiosResponse?.nodeios?.length ?? 0} //This is important field to render the next data
                                    next={() =>
                                        handleChangePage(
                                            nodeiosResponse?.pagination?.next_page ?? 1,
                                        )
                                    }
                                    hasMore={nodeiosResponse?.pagination?.next_page !== null}
                                    loader={<h4>Loading...</h4>}
                                    scrollableTarget="scrollableDiv"
                                >
                                    {nodeiosResponse?.nodeios
                                        .sort((a, b) =>
                                            a.checked === b.checked ? 0 : a.checked ? -1 : 1,
                                        )
                                        .map((item: any) => (
                                            <ResponseMVPCardStripe
                                                key={item.id}
                                                onSelect={handleSelect}
                                                mvp={item}
                                            />
                                        ))}
                                </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                    <div
                        className="absolute w-full h-full bg-transparent z-0"
                        onClick={() => {
                            setNodeiosResponse(null)
                            onToggle()
                        }}
                    />
                </div>
            )}
        </>
    )
}

export default MVPCardListModal
