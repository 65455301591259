import { CampaignDataSourceItem } from '../types/CampaignDataSources'
import { pluralizationUtil } from '../utils/pluralizationUtil'
import { useEffect, useState } from 'react'

interface CampaignDataCardProps {
    campaignData: CampaignDataSourceItem
    onSelect: (id: string) => void
}

const CampaignDataSourceCard = ({ campaignData, onSelect }: CampaignDataCardProps): JSX.Element => {
    const [isCopied, setIsCopied] = useState<boolean>(false)
    useEffect(() => {
        if (isCopied) {
            const timeout = setTimeout(() => {
                setIsCopied(false)
            }, 2000)
            return () => clearTimeout(timeout)
        }
    }, [isCopied])

    return (
        <div
            className="mb-3 p-5 rounded-2xl flex flex-col md:flex-row justify-between items-start md:items-center gap-5 md:gap-7"
            style={{
                background: '#1F2C3B',
            }}
        >
            <div className="flex flex-row items-center">
                <input
                    type="checkbox"
                    checked={campaignData.selected || false}
                    onChange={() => onSelect(campaignData.id)}
                    className="mr-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <p className="text-[#FFFFFF] ml-2 text-base font-bold ellipsis-1 break-all">
                    {campaignData?.title}
                </p>
            </div>
            <div className="flex flex-row gap-2 sm:gap-5 sm:min-w-[200px] items-center justify-end mr-4">
                {campaignData?.type.toLowerCase() === 'live' ? (
                    <p className="text-[#FFFFFF] text-sm">
                        {campaignData?.number_of_responses ? (
                            <span className="text-[#BFDEFF] text-sm">
                                {campaignData?.number_of_responses}{' '}
                                {pluralizationUtil(
                                    Number(campaignData?.number_of_responses),
                                    'conversation',
                                    'conversations',
                                )}
                            </span>
                        ) : (
                            <span className="text-[#007AFF] text-sm"></span>
                        )}
                    </p>
                ) : (
                    <p className="text-[#FFFFFF40] text-sm">
                        {campaignData?.number_of_responses ? (
                            <span className="text-[#BFDEFF] text-sm">
                                {campaignData?.number_of_responses}{' '}
                                {pluralizationUtil(
                                    Number(campaignData?.number_of_responses),
                                    'response',
                                    'responses',
                                )}
                            </span>
                        ) : (
                            <span className="text-[#54779C] text-sm"></span>
                        )}
                    </p>
                )}
            </div>
        </div>
    )
}

export default CampaignDataSourceCard
