import {
    ConfirmAccount,
    ConfirmLoginOtpCodeResponse,
    RefreshTokenResponse,
    registerPayload,
} from '../types/Authentication'
import { User } from '../types/User'
import ApiService from './apiService'
import { AxiosResponse } from 'axios'

export interface AuthenticationApi {
    verifyInviteCode(inviteCode: string): Promise<AxiosResponse<void>>
    register(payload: registerPayload): Promise<AxiosResponse<ConfirmAccount>>
    sendLoginOtpCode(phoneNumber: string): Promise<AxiosResponse<void>>
    login(otpCode: string, phoneNumber: string): Promise<AxiosResponse<ConfirmLoginOtpCodeResponse>>
    verifyPhoneNumber(otpCode: string, phoneNumber: string): Promise<AxiosResponse<ConfirmAccount>>
    refreshToken(refreshToken: string): Promise<AxiosResponse<RefreshTokenResponse>>
    verifyPhoneNumberResendOtpCode(phoneNumber: string): Promise<AxiosResponse<any>>
    addToWaitList(phoneNumber: string): Promise<AxiosResponse<any>>
    getProfiles(): Promise<AxiosResponse<User[]>>
    selectProfile(profileId: string): Promise<AxiosResponse<ConfirmLoginOtpCodeResponse>>
    shareProfileInvite(phoneNumber: string): Promise<AxiosResponse<any>>
    deleteProfile(profileId: string | null): Promise<AxiosResponse<any>>
    getSharedUsers(): Promise<AxiosResponse<User[]>>
    deleteUser(userId: string | null): Promise<AxiosResponse<any>>
}

export const AuthenticationApi: AuthenticationApi = Object.freeze({
    register(payload: registerPayload): Promise<AxiosResponse<ConfirmAccount>> {
        return ApiService.post('/authentication/register', payload)
    },

    sendLoginOtpCode(phoneNumber: string): Promise<AxiosResponse<void>> {
        return ApiService.post('/authentication/login/send-otp', {
            phone_number: phoneNumber,
        })
    },

    login(
        otpCode: string,
        phoneNumber: string,
    ): Promise<AxiosResponse<ConfirmLoginOtpCodeResponse>> {
        return ApiService.post('/authentication/login', {
            otp_code: otpCode,
            phone_number: phoneNumber,
        })
    },

    verifyPhoneNumber(
        otpCode: string,
        phoneNumber: string,
    ): Promise<AxiosResponse<ConfirmAccount>> {
        return ApiService.post(`/authentication/verify-phone-number`, {
            otp_code: otpCode,
            phone_number: phoneNumber,
        })
    },

    verifyPhoneNumberResendOtpCode(phoneNumber: string): Promise<AxiosResponse<any>> {
        return ApiService.post(`/authentication/verify-phone-number/resend`, {
            phone_number: phoneNumber,
        })
    },

    refreshToken(refreshToken: string): Promise<AxiosResponse<RefreshTokenResponse>> {
        return ApiService.post(`/authentication/refresh-token`, {
            refresh_token: refreshToken,
        })
    },

    addToWaitList(phoneNumber: string): Promise<AxiosResponse<any>> {
        return ApiService.post('/waitlist', {
            phone_number: phoneNumber,
        })
    },

    verifyInviteCode(inviteCode: string): Promise<AxiosResponse<void>> {
        return ApiService.post('/authentication/verify-invite-code', {
            invite_code: inviteCode,
        })
    },

    getProfiles(): Promise<AxiosResponse<User[]>> {
        return ApiService.get('/users/profiles')
    },

    selectProfile(profileId: string): Promise<AxiosResponse<ConfirmLoginOtpCodeResponse>> {
        return ApiService.post('/authentication/login-and-select-profile', {
            profile_id: profileId,
        })
    },
    shareProfileInvite(phoneNumber: string): Promise<AxiosResponse<any>> {
        return ApiService.post('/shared-profile-invites', {
            phone_number: phoneNumber,
        })
    },
    deleteProfile(profileId: string | null): Promise<AxiosResponse<any>> {
        return ApiService.delete(`/users/profiles/${profileId}`)
    },
    getSharedUsers(): Promise<AxiosResponse<User[]>> {
        return ApiService.get('/users/shared-account/users')
    },
    deleteUser(userId: string | null): Promise<AxiosResponse<any>> {
        return ApiService.delete(`/users/shared-account/users/${userId}`)
    },
})
