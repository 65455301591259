import { useQuestionStore } from '../context/question/store'
import { toMMHH } from '../utils/toMMHH'
import { Tooltip } from 'flowbite-react'
import getBlobDuration from 'get-blob-duration'
import { useRef } from 'react'
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder'
import { useTranslation } from 'react-i18next'

interface RecorderButtonProps {
    onStop: (formData: FormData, audioLength: number) => void
    onRecorderNotSupported: () => void
    disabled: boolean
    tooltip?: string
    isQuestionResponder?: boolean
    isLiveMode?: boolean
    setIsRecording?: (v: boolean | ((prevVar: boolean) => boolean)) => void
    isDarkModeEnabled?: boolean
}

const RecorderButton = ({
    onStop,
    onRecorderNotSupported,
    disabled = false,
    tooltip,
    isQuestionResponder = false,
    isLiveMode = false,
    setIsRecording,
    isDarkModeEnabled = false,
}: RecorderButtonProps): JSX.Element => {
    const {
        state: { accepted },
        setShowTip,
        setOpenFooter,
        setQuestionResponderLanded,
    } = useQuestionStore()

    const { t } = useTranslation()

    const StartRecordingIcon = () => (
        <div className="p-[28px] bg-[#d8ebff] rounded-full">
            <div className="p-[25px] bg-[#007AFF] rounded-full">
                <img src="/asset/mike-icon.svg" />
            </div>
        </div>
    )

    const StopRecordingIcon = () => (
        <div className="p-[28px] bg-[#d8ebff] rounded-full">
            <div className="p-[25px] bg-[#007AFF] rounded-full">
                <div className="w-[46px] h-[46px] bg-[#DF2D2D] rounded-md" />
            </div>
        </div>
    )

    const playerRef = useRef<HTMLAudioElement>(null)
    const recorderControls = useAudioRecorder()

    const addAudioElement = (blob: Blob) => {
        const url = URL.createObjectURL(blob)
        const audio = document.createElement('audio')
        audio.src = url
        audio.controls = true
        document.body.appendChild(audio)
    }

    return (
        <div className="player">
            <AudioRecorder
                downloadOnSavePress={false}
                recorderControls={recorderControls}
                classes={{
                    AudioRecorderClass: 'display-none',
                    AudioRecorderStartSaveClass: 'display-none',
                    AudioRecorderTimerClass: 'display-none',
                    AudioRecorderStatusClass: 'display-none',
                    AudioRecorderPauseResumeClass: 'display-none',
                    AudioRecorderDiscardClass: 'display-none',
                }}
                onRecordingComplete={async (blob) => {
                    addAudioElement(blob)
                    const audioURL = URL.createObjectURL(blob)
                    const formData = new FormData()
                    const audioLength = await getBlobDuration(blob)
                    const name = audioURL.split('/')[audioURL.split('/').length - 1]
                    formData.append('file', blob, `${name}`)
                    formData.append('audio_length', String(audioLength * 1000))
                    onStop(formData, audioLength)
                    if (playerRef.current) {
                        playerRef.current.src = audioURL
                    }
                }}
                downloadFileExtension="mp3"
            />
            <div className="flex flex-col gap-2.5 items-center">
                {tooltip ? (
                    <Tooltip
                        content={tooltip}
                        className="text-base px-5 py-2 max-w-[200px] sm:max-w-max"
                    >
                        <button
                            className=""
                            onClick={() => {
                                try {
                                    if (recorderControls.isRecording) {
                                        recorderControls.stopRecording()
                                        return
                                    }

                                    if (accepted) {
                                        recorderControls.startRecording()
                                        return
                                    }

                                    if (!accepted) {
                                        setQuestionResponderLanded(false)
                                        setOpenFooter(true)
                                    }
                                } catch (error) {
                                    onRecorderNotSupported()
                                }
                            }}
                            disabled={disabled}
                        >
                            {recorderControls.isRecording ? (
                                <StopRecordingIcon />
                            ) : (
                                <StartRecordingIcon />
                            )}
                        </button>
                    </Tooltip>
                ) : (
                    <button
                        className=""
                        onClick={() => {
                            try {
                                if (recorderControls.isRecording) {
                                    recorderControls.stopRecording()
                                    setIsRecording && setIsRecording(false)
                                    return
                                }

                                if (accepted) {
                                    recorderControls.startRecording()
                                    setIsRecording && setIsRecording(true)
                                    setTimeout(() => {
                                        setShowTip(false)
                                    }, 2000)
                                    return
                                }

                                if (!accepted) {
                                    setQuestionResponderLanded(false)
                                    setOpenFooter(true)
                                }
                            } catch (error) {
                                onRecorderNotSupported()
                            }
                        }}
                        disabled={disabled}
                    >
                        {recorderControls.isRecording ? (
                            <StopRecordingIcon />
                        ) : (
                            <StartRecordingIcon />
                        )}
                    </button>
                )}
                <div className="mb-4 flex flex-col items-center gap-4 mt-12">
                    {!recorderControls.isRecording && (
                        <div className="flex flex-col gap-3 text-center">
                            {isLiveMode ? (
                                <p className="text-[#001F40] text-sm">
                                    {t('recorder_button.click_to_activate_live')}
                                </p>
                            ) : (
                                <p
                                    className={`${
                                        isDarkModeEnabled ? 'text-[#FFFFFF]' : 'text-[#001F40]'
                                    } text-sm`}
                                >
                                    {t('recorder_button.click_to_activate')}
                                </p>
                            )}
                            {!isQuestionResponder && (
                                <p className="text-[#007AFF] text-sm font-semibold">
                                    {t(
                                        'recorder_button.you_can_listen_and_re_record_before_submitting',
                                    )}
                                </p>
                            )}
                        </div>
                    )}
                    {recorderControls.isRecording && (
                        <p
                            className={`${
                                isDarkModeEnabled ? 'text-[#FFFFFF]' : 'text-[#001F40]'
                            } text-2xl font-bold`}
                        >
                            {t('recorder_button.listening')}
                        </p>
                    )}
                    {recorderControls.isRecording && (
                        <p className="text-black text-base">
                            {toMMHH(recorderControls.recordingTime)}
                        </p>
                    )}
                </div>
            </div>
        </div>
    )
}

export default RecorderButton
