import { CountryCodeApi } from '../api/countryCode'
import { CountryCode } from '../types/CountryCode'
import { ChangeEvent, useEffect, useState } from 'react'

export interface PhoneNumberInputProps {
    refresh?: boolean
    onChange: (phoneNumber: string) => void
    direction?: 'col' | 'row'
}

const PhoneNumberInput = ({
    onChange,
    direction = 'row',
    refresh = false,
}: PhoneNumberInputProps) => {
    const [countryCodes, setCountryCodes] = useState<CountryCode[]>([])
    const [selectedCountryCode, setSelectedCountryCode] = useState<string>('+1')
    const [phoneNumber, setPhoneNumber] = useState<string>('')
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)

    const fetchCountryCodes = async (): Promise<void> => {
        const response = await CountryCodeApi.getCountryCodes()
        setCountryCodes(response.data)
    }

    useEffect(() => {
        if (refresh) {
            setPhoneNumber('')
            setSelectedCountryCode('+1')
        }
    }, [refresh])

    useEffect(() => {
        fetchCountryCodes()
    }, [])

    const isMobileDevice = () => {
        const userAgent = navigator.userAgent
        return /Mobi|Android/i.test(userAgent)
    }

    const renderText = (countryCode: CountryCode): string => {
        const isMobile = isMobileDevice()
        if (isMobile && isDropdownOpen) {
            return isDropdownOpen
                ? `${countryCode.name} ${countryCode.code}`
                : `kita ${countryCode.code}`
        }
        return `${countryCode.name} ${countryCode.code}`
    }

    return (
        <div
            className={`flex flex-col ${
                direction === 'col' ? 'md:flex-col gap-2' : 'md:flex-row gap-4'
            }`}
        >
            <select
                className="text-center text-base leading-5 px-5 py-2.5 text-[#001F40] bg-[#d9d9d940] rounded-[10px] outline-offset-0 outline-0 focus:outline focus:outline-1 focus:outline-offset-0 border-none focus:outline-[#D9D9D9] w-full sm:w-auto"
                value={selectedCountryCode}
                onBlur={() => setIsDropdownOpen(false)}
                onFocus={() => setIsDropdownOpen(true)}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    setSelectedCountryCode(e.target.value)
                    onChange(`${e.target.value}${phoneNumber}`)
                    setIsDropdownOpen(false)
                }}
            >
                {countryCodes.map((countryCode: CountryCode, index: number) => (
                    <option key={index} value={countryCode.code}>
                        {renderText(countryCode)}
                    </option>
                ))}
            </select>
            <input
                className="text-center text-base leading-5 px-[18px] py-2.5 text-[#001F40] bg-[#d9d9d940] rounded-[10px] outline-offset-0 outline-0 focus:outline focus:outline-1 focus:outline-offset-0 border-none focus:outline-[#D9D9D9] w-full sm:w-auto"
                value={phoneNumber}
                type="tel"
                placeholder="Enter phone number"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setPhoneNumber(event.target.value)
                    onChange(`${selectedCountryCode}${event.target.value}`)
                }}
            />
        </div>
    )
}

export default PhoneNumberInput
