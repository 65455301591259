import { CookieKey } from '../enums/CookieKey'
import { CookieOptions } from '../types/CookieOptions'
import * as Sentry from '@sentry/react'
import moment from 'moment'
import { useState } from 'react'

export function stringifyOptions(options: any): string {
    return Object.keys(options).reduce((acc: string, key: string) => {
        if (options[key] === false) {
            return acc
        } else if (options[key] === true) {
            return `${acc}; ${key}`
        } else {
            return `${acc}; ${key}=${options[key]}`
        }
    }, '')
}

export const getCookie = (name: CookieKey) => {
    return document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=')
        return parts[0] === name ? decodeURIComponent(parts[1]) : r
    }, '')
}

export const setCookie = <T>(key: CookieKey, value: T, options: CookieOptions = {}): void => {
    const defaultExpiresIn = moment().add('30', 'minutes')
    Sentry.captureMessage(`current timestamp: ${moment().format('YYYY-MM-DD HH:mm:ss')}`)
    Sentry.captureMessage(`Cookie expires in: ${defaultExpiresIn.format('YYYY-MM-DD HH:mm:ss')} `)
    document.cookie = `${key}=${value}${stringifyOptions({
        expires: defaultExpiresIn,
        path: '/',
        secure: true,
        sameSite: 'strict',
        ...options,
    })}`
}

export const deleteCookie = (name: CookieKey) => {
    document.cookie = `${name}=;${stringifyOptions({
        expires: 'Thu, 01 Jan 1970 00:00:01 GMT;`',
        path: '/',
        secure: true,
        sameSite: 'strict',
    })}`
}

function useCookies<T>(
    key: CookieKey,
    initialValue: T,
    options: CookieOptions = {},
): readonly [T, (input: T) => void] {
    const [cookieValue, setCookieValue] = useState<T>((): T => {
        try {
            const item = getCookie(key)
            // @ts-ignore
            return item ? item : initialValue
        } catch {
            return initialValue
        }
    })

    const setValue = (value: T): void => {
        setCookieValue(value)
        setCookie(key, value, options)
    }

    return [cookieValue, setValue]
}

export default useCookies
