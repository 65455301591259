const LinkExpired = (): JSX.Element => {
    return (
        <div className="w-full h-screen relative flex flex-col justify-center items-center">
            <div className="relative w-full h-[250px] bg-[#E4F1FF]" />
            <div className="flex flex-1 bg-white"></div>
            <div className="absolute flex flex-col items-center h-screen w-screen md:w-fit bg-transparent px-[25px] md:px-[0px]">
                <div
                    className="mt-[150px] w-full md:w-[720px] px-[70px] py-[60px] rounded-lg"
                    style={{
                        background:
                            'var(--card, linear-gradient(313deg, rgba(107, 178, 255, 0.19) 7.36%, rgba(0, 122, 255, 0.13) 33.85%, rgba(107, 178, 255, 0.13) 68.75%, rgba(0, 122, 255, 0.19) 93.75%), #0D0D0D)',
                    }}
                >
                    <p className="text-[#BFDEFF] font-lato text-[24px]">This link has expired</p>
                </div>
            </div>
        </div>
    )
}
export default LinkExpired
