import { StoryboardApi } from '../api/storyboardApi'
import Spinner from '../components/Spinner'
import StoryboardSingleMVPItemBears from '../components/StoryboardSingleMVPItemBears'
import { useUserStore } from '../context/user/store'
import { Nodeio } from '../types/Nodeio'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'

const ThankYouFansPage = (): JSX.Element => {
    const [mvps, setMvps] = useState<[]>([])
    const colCount = 3

    useEffect(() => {
        document.body.style.overflowY = 'scroll'
    })

    const {
        state: { loading },
        setLoading,
    } = useUserStore()

    const fetchMvps = async () => {
        setLoading(true)
        try {
            const response = await StoryboardApi.getMvpsForCustomStoryboard()
            setMvps(response.data)
            setLoading(false)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        void fetchMvps()
    }, [])

    const matrix = useMemo(() => {
        let arr: Nodeio[][] = []
        let rowCount = Math.ceil(mvps.length / colCount)

        for (let i = 0; i < colCount; i++) {
            arr[i] = []
            for (let k = 0; k < rowCount; k++) {
                i + colCount * k < mvps.length && arr[i].push(mvps[i + colCount * k])
            }
        }

        return arr
    }, [colCount, mvps])
    return (
        <div className="flex flex-col min-h-screen">
            <div className="relative h-[200px] sm:h-[200px]">
                <img
                    className="absolute top-0 left-0 w-full h-full object-cover hidden sm:block"
                    src="https://s3.us-east-2.amazonaws.com/nodeio.storyboards/cea2c53d-bc50-4632-aee4-d71155a53273-2cd53353-10a6-4acd-8db3-5531606e8cb3"
                    alt="background"
                />
                <img
                    className="absolute top-0 left-0 w-full h-full object-cover block sm:hidden"
                    src="/asset/thank_you_fans_mobile_view.png"
                    alt="background"
                />
            </div>
            <div className="flex-1 flex justify-center bg-[#0C1B2C] pb-16">
                <div className="w-[1024px] pt-8">
                    <p className="text-[#BFDEFF] text-2xl text-center font-bold font-lato mb-8">
                        HERE ARE YOUR VOICES
                    </p>
                    {!_.isEmpty(mvps) ? (
                        <div className="flex gap-4 grid px-5  grid-cols-6">
                            {matrix.map((row, index) => (
                                <div
                                    key={index}
                                    className="flex flex-col flex-1 gap-4 col-span-6 sm:col-span-3 md:col-span-2"
                                >
                                    {row.map((mvp, index) => (
                                        <div key={index}>
                                            <StoryboardSingleMVPItemBears nodeio={mvp} />
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p className="mt-10 text-center text-sm text-white">No MVPs at this time</p>
                    )}
                </div>
            </div>
            {loading && <Spinner />}
        </div>
    )
}

export default ThankYouFansPage
