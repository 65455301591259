import { EmotionsByThemeResponse } from '../types/EmotionsByThemeResponse'
import { InterviewResponse } from '../types/InterviewResponse'
import { ListOfInterviewResponses } from '../types/ListOfInterviewResponses'
import InterviewLandingPageSingleSoundbite from './InterviewLandingPageSingleSoundbite'
import React, { useRef, useState } from 'react'

export interface InterviewLandingPageSoundbitesProps {
    interviewResponsesData: ListOfInterviewResponses | null
    emotionsData: EmotionsByThemeResponse[]
    selectedTopic: string | null
    selectedTone: string | undefined
    noInterviewResponses: boolean
    onS3KeyExpired: () => void
}

const InterviewLandingPageSoundbites = ({
    interviewResponsesData,
    emotionsData,
    selectedTopic,
    selectedTone,
    onS3KeyExpired,
    noInterviewResponses = false,
}: InterviewLandingPageSoundbitesProps): JSX.Element => {
    const [showAll, setShowAll] = useState(false)
    const audioRef = useRef<any>(null)

    const soundbites = interviewResponsesData?.interview_responses.filter(
        (interviewResponsesData: InterviewResponse) => {
            if (selectedTopic) {
                return interviewResponsesData.themes
                    .map((theme) => theme.theme_name)
                    .includes(selectedTopic)
            }
        },
    )

    return (
        <div
            className="bg-[#f9f9f9] px-[15px] sm:px-[45px] pb-[18px] sm:pb-[38px] pt-7 py-8 rounded-[30px]"
            style={{
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
            }}
        >
            <div className="flex flex-row items-center justify-between mb-4">
                <div className="flex flex-row items-center">
                    <p className="text-[#007AFF] text-sm sm:text-base font-bold font-lato pr-4 border-r-2 border-[#b6d6fa] uppercase">
                        Soundbites
                    </p>
                    <p className="text-[#9E9E9E] text-sm sm:text-base font-lato pl-4">
                        {noInterviewResponses
                            ? 'Record a conversation to get soundbites'
                            : 'Click on a soundbite to view transcript'}
                    </p>
                </div>
            </div>
            <div className="flex flex-col gap-4 sm:gap-6">
                {soundbites
                    ?.slice(0, showAll ? interviewResponsesData?.interview_responses.length : 3)
                    .map((response: InterviewResponse, index: number) => {
                        return (
                            <InterviewLandingPageSingleSoundbite
                                key={index}
                                response={response}
                                emotionsData={emotionsData}
                                audioRef={audioRef}
                                selectedTopic={selectedTopic}
                                selectedTone={selectedTone}
                                onS3KeyExpired={onS3KeyExpired}
                            />
                        )
                    })}
            </div>
            <div className="flex flex-row justify-end items-center mt-[30px]">
                {soundbites && soundbites.length > 3 && (
                    <button onClick={() => setShowAll(!showAll)}>
                        <p className="text-[#007AFF] font-bold font-lato text-base">
                            {showAll ? 'View less' : 'View all'}
                        </p>
                    </button>
                )}
            </div>
        </div>
    )
}

export default InterviewLandingPageSoundbites
