// @ts-ignore
import { useQuestionStore } from '../context/question/store'
import { Mandatory } from '../enums/Mandatory'
import { Response, ResponseNote } from '../pages/QuestionResponder'
import { Language } from '../pages/QuestionResponder'
import { Question } from '../types/Question'
import { upperCaseLeading } from '../utils/upperCaseLeading'
import NextQuestionButton from './NextQuestionButton'
import PreviousQuestionButton from './PreviousQuestionButton'
import QuestionAssets from './QuestionAssets'
import SubmitButton from './SubmitButton'
import TextWithLinks from './TextWithLinks'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface QuestionResponderQuestionCardProps {
    questions: Question[]
    questionIndex: number
    responseForSelectedQuestion: Response | undefined
    responseNoteForSelectedQuestion: ResponseNote | undefined
    onQuestionIndexChange: (questionIndex: number) => void
    setResponseNotes: (
        responseNotes: ResponseNote[] | ((prevVar: ResponseNote[]) => ResponseNote[]),
    ) => void
    selectedLanguage: Language
    isRecording: boolean
    handleSubmit: () => void
    isSubmitted: boolean
    requiredToAllResponds: boolean
}

const QuestionResponderQuestionCard = ({
    questions,
    questionIndex,
    responseForSelectedQuestion,
    onQuestionIndexChange,
    setResponseNotes,
    responseNoteForSelectedQuestion,
    selectedLanguage,
    isRecording,
    handleSubmit,
    //requiredToAllResponds,
    isSubmitted,
}: QuestionResponderQuestionCardProps): JSX.Element => {
    const selectedQuestion: Question | null =
        questions && questions.length ? questions[questionIndex] ?? null : null
    const isMultiQuestionLink: boolean = questions.length > 1
    //const [isRecording, setIsRecording] = useState(false)

    let translatedQuestion: string = selectedQuestion?.question ?? ''
    if (selectedQuestion?.question_settings.translations) {
        const translation = selectedQuestion?.question_settings.translations.find(
            (translation) => translation.language === selectedLanguage,
        )
        translatedQuestion = translation?.question ?? selectedQuestion?.question ?? ''
    }

    let translatedHelpText: string = selectedQuestion?.help_text ?? ''
    if (selectedQuestion?.question_settings.translations) {
        const translation = selectedQuestion?.question_settings.translations.find(
            (translation) => translation.language === selectedLanguage,
        )
        translatedHelpText = translation?.help_text ?? selectedQuestion?.help_text ?? ''
    }

    const {
        state: { accepted },
    } = useQuestionStore()

    const { t } = useTranslation()

    return (
        <div
            className={`${
                isRecording ? 'hidden' : 'block'
            } w-full md:w-[720px] px-[25px] sm:px-[45px] pt-10 pb-4 rounded-lg min-h-[244px]`}
            style={{
                background:
                    'var(--card, linear-gradient(313deg, rgba(107, 178, 255, 0.19) 7.36%, rgba(0, 122, 255, 0.13) 33.85%, rgba(107, 178, 255, 0.13) 68.75%, rgba(0, 122, 255, 0.19) 93.75%), #0D0D0D)',
            }}
        >
            <React.Fragment>
                <div className="flex flex-row items-center gap-4">
                    <img
                        src={
                            selectedQuestion?.author.profile_photo?.url ??
                            'https://thetrustapp.s3.us-east-2.amazonaws.com/profile-icon-96x96.png'
                        }
                        width={40}
                        height={40}
                        className="z-10 w-10 h-10 rounded-full object-cover"
                        alt="profile_photo"
                    />
                    <div className="flex flex-col justify-center">
                        <p className="text-base text-[#BFDEFF]">
                            {selectedQuestion?.author?.first_name}{' '}
                            {selectedQuestion?.author?.last_name}
                        </p>
                        <p className="text-[13px] text-[#BFDEFF] font-lato">
                            {selectedQuestion?.author?.bio}
                        </p>
                    </div>
                </div>
                <div
                    className={`flex flex-col gap-2 mt-[26px] ${
                        isMultiQuestionLink ? 'mb-0' : 'mb-4'
                    }`}
                >
                    <TextWithLinks
                        className="text-2xl text-white font-lato whitespace-pre-wrap overflow-x-auto"
                        linkClassName="text-[#007AFF]"
                        text={translatedQuestion}
                    ></TextWithLinks>
                    <TextWithLinks
                        className="text-base text-[#BFDEFF] font-lato whitespace-pre-wrap overflow-x-auto"
                        linkClassName="text-[#007AFF]"
                        text={translatedHelpText}
                    ></TextWithLinks>
                </div>
                <div
                    className={`flex flex-col sm:flex-row gap-2 justify-between items-center sm:items-start mt-[22px]`}
                >
                    <div className="flex flex-col sm:flex-row gap-2 justify-start items-center">
                        <div className="flex flex-row gap-2 sm:mr-2">
                            {selectedQuestion?.question_settings.require_question_response ===
                                Mandatory.REQUIRED && (
                                <p className="text-[11px] sm:text-[13px] text-[#BFDEFF] font-bold mr-1">
                                    {upperCaseLeading(t('common.required'))}
                                </p>
                            )}
                            <p
                                className={`${
                                    questions.length > 1 ? 'block' : 'hidden'
                                } text-[#DBDDE0] text-xs sm:text-sm`}
                            >
                                {/* convert to 1 based index */}
                                {questionIndex + 1} / {questions.length}
                            </p>
                        </div>
                        <div
                            className={`${
                                questions.length > 1 ? 'block' : 'hidden'
                            } flex flex-row gap-3 mb-4 sm:mb-0`}
                        >
                            <PreviousQuestionButton
                                onClick={() => {
                                    if (
                                        !responseForSelectedQuestion &&
                                        !responseNoteForSelectedQuestion?.title
                                    ) {
                                        setResponseNotes((responseNotes) => {
                                            let resNotes = [...responseNotes]
                                            resNotes.splice(
                                                responseNotes.findIndex(
                                                    (responseNote) =>
                                                        responseNote.questionId ===
                                                        selectedQuestion?.id,
                                                ),
                                                1,
                                                {
                                                    ...(responseNoteForSelectedQuestion as ResponseNote),
                                                    selectedQuestionType: undefined,
                                                    title: undefined,
                                                    note: '',
                                                    open: false,
                                                },
                                            )
                                            return resNotes
                                        })
                                    }
                                    onQuestionIndexChange(questionIndex - 1)
                                }}
                                disabled={isRecording || questionIndex === 0}
                            />
                            <NextQuestionButton
                                onClick={() => {
                                    if (
                                        !responseForSelectedQuestion &&
                                        !responseNoteForSelectedQuestion?.title
                                    ) {
                                        setResponseNotes((responseNotes) => {
                                            let resNotes = [...responseNotes]
                                            resNotes.splice(
                                                responseNotes.findIndex(
                                                    (responseNote) =>
                                                        responseNote.questionId ===
                                                        selectedQuestion?.id,
                                                ),
                                                1,
                                                {
                                                    ...(responseNoteForSelectedQuestion as ResponseNote),
                                                    selectedQuestionType: undefined,
                                                    title: undefined,
                                                    note: '',
                                                    open: false,
                                                },
                                            )
                                            return resNotes
                                        })
                                    }
                                    onQuestionIndexChange(questionIndex + 1)
                                }}
                                disabled={isRecording || questionIndex === questions!.length - 1}
                            />
                        </div>
                    </div>
                    <div className="flex flex-row gap-2 justify-end items-center">
                        <SubmitButton onClick={handleSubmit} disabled={!accepted || isSubmitted} />
                    </div>
                </div>
                <div className="mt-4">
                    {selectedQuestion?.assets && selectedQuestion?.assets.length > 0 && (
                        <QuestionAssets
                            key={selectedQuestion?.id}
                            assets={selectedQuestion?.assets}
                            className="max-h-[240px]"
                        />
                    )}
                </div>
            </React.Fragment>
        </div>
    )
}

export default QuestionResponderQuestionCard
