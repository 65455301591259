import { useUserStore } from '../context/user/store'
import { CookieKey } from '../enums/CookieKey'
import { Page } from '../enums/Page'
import { getCookie } from '../utils/useCookies'
import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

interface RouterGuardProps {
    children: JSX.Element | JSX.Element[]
}

const RouterGuard = ({ children }: RouterGuardProps): JSX.Element => {
    const {
        state: { user },
    } = useUserStore()

    const location = useLocation()
    const refreshToken = getCookie(CookieKey.REFRESH_TOKEN)

    if (!user) {
        if (refreshToken) {
            if ([Page.SELECT_PROFILE as string].includes(location.pathname))
                return <React.Fragment>{children}</React.Fragment>
            return <Navigate to={Page.SELECT_PROFILE} />
        }
        return <Navigate to={Page.SIGN_IN} />
    }
    // prevent the authenticated users from viewing /signin page and redirect them to the home page

    if (user && [Page.SIGN_IN as string].includes(location.pathname)) {
        return <Navigate to={Page.HOME} />
    }

    return <React.Fragment>{children}</React.Fragment>
}

export default RouterGuard
