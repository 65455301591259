import { useUserStore } from '../../context/user/store'
import { ChatCompletionRequestMessageRole } from '../../enums/ChatCompletionRequestMessageRole'
import { Message } from '../../types/Message'
import ChatToneAiImage from './ChatToneAiImage'
import React from 'react'
import Markdown from 'react-markdown'

const ChatMessage = ({ message }: { message: Message }): JSX.Element => {
    const {
        state: { user },
    } = useUserStore()

    let bgColour = ''
    if (message.content === 'Unexpected error occurred. Please try again.') {
        bgColour = 'linear-gradient(180deg, #3C9AFF 2.59%, #097FFF 103.45%)'
    } else if (message.role === ChatCompletionRequestMessageRole.USER) {
        bgColour = 'linear-gradient(180deg, #3C9AFF 2.59%, #097FFF 103.45%)'
    } else {
        bgColour = '#F4F4F4'
    }

    return (
        <div
            className={`flex flex-row gap-4 w-full ${
                message.role === ChatCompletionRequestMessageRole.USER
                    ? 'justify-end'
                    : 'justify-start'
            } `}
        >
            <div
                className={`text-sm font-lato px-3 rounded-lg w-[70%] ${
                    message.role === ChatCompletionRequestMessageRole.USER ? 'order-1' : 'order-2'
                }`}
                style={{
                    background: bgColour,
                    color:
                        message.role === ChatCompletionRequestMessageRole.USER ? 'white' : 'black',
                }}
            >
                <div className="markdown-content">
                    <Markdown>{message.content}</Markdown>
                </div>
            </div>
            <div
                className={`${
                    message.role === ChatCompletionRequestMessageRole.USER ? 'order-2' : 'order-1'
                }`}
            >
                {message.role === ChatCompletionRequestMessageRole.USER ? (
                    <img
                        src={
                            user?.profile_photo?.url ??
                            'https://thetrustapp.s3.us-east-2.amazonaws.com/profile-icon-96x96.png'
                        }
                        width={40}
                        height={40}
                        className="z-10 rounded-full object-cover h-10 w-10"
                        alt="profile_photo"
                    />
                ) : (
                    <ChatToneAiImage />
                )}
            </div>
        </div>
    )
}

export default ChatMessage
