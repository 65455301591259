import { Transcription } from '../types/Transcription'

export const getFormattedTimestamp = (in_seconds: number): string => {
    const hours = Math.floor(in_seconds / 3600)
    const minutes = Math.floor((in_seconds - hours * 3600) / 60)
    let minutesString = minutes.toString()
    if (minutes <= 9) minutesString = `0${minutes}`
    const seconds = Math.trunc(in_seconds - hours * 3600 - minutes * 60)
    let secondsString = seconds.toString()
    if (seconds <= 9) secondsString = `0${seconds}`

    return `${hours > 0 ? hours + ':' : ''}${
        minutes > 0 ? minutesString + ':' : '00:'
    }${secondsString}`
}

export const getFormattedTranscriptionText = (transcriptonJSON: Transcription): string | null => {
    let formattedText = ''
    transcriptonJSON?.translated_formatted_text?.forEach((textObj) => {
        const startTime = getFormattedTimestamp(Number(textObj.start))
        formattedText += `[${startTime}] - Speaker ${textObj.speaker + 1}:\n  ${textObj.text}\n\n`
    })
    if (formattedText === '') {
        transcriptonJSON?.formatted_text?.forEach((textObj) => {
            const startTime = getFormattedTimestamp(Number(textObj.start))
            formattedText += `[${startTime}] - Speaker ${textObj.speaker + 1}:\n  ${
                textObj.text
            }\n\n`
        })
    }

    if (formattedText === '') return null
    if (!formattedText.includes('Speaker 2')) {
        formattedText = formattedText.replace(' - Speaker 1', '')
    }
    return formattedText
}

export const getNoTranscriptionText = (created_at: string): string => {
    //if date is less than 45 minutes ago, return "Transcription loading..."
    //else return "No transcription found in audio"
    const date = new Date(created_at)
    const now = new Date()
    const diff = Math.abs(now.getTime() - date.getTime())
    const minutes = Math.floor(diff / 1000 / 60)
    if (minutes <= 45) return 'Transcription loading...'
    return 'No transcription found in audio'
}
