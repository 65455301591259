export interface ErrorMessageProps {
    error: string
    className?: string
}

const ErrorMessage = ({ error, className = '' }: ErrorMessageProps) => {
    return <p className={`text-xs text-red-500 ${className}`}>{error}</p>
}

export default ErrorMessage
