/* eslint-disable indent */
import { QuestionApi } from '../api/question'
import { StoryboardApi } from '../api/storyboardApi'
import MVPCardGrid from '../components/MVPCard/MVPCardGrid'
import MVPCardListModal from '../components/MVPCard/Modals/MVPCardListModal'
import MVPShareModal from '../components/MVPCard/Modals/MVPShareModal'
import ShareStoryboardQrModal from '../components/ShareStoryboardQrModal'
import Spinner from '../components/Spinner'
import { useRagStore } from '../context/rag/store'
import { useUserStore } from '../context/user/store'
import { Page } from '../enums/Page'
import { useWebSocket } from '../hooks/useWebSocket'
import { MVPResponse } from '../types/MVPResponse'
import { Nodeio } from '../types/Nodeio'
import { Storyboard } from '../types/Storyboard'
import { saveAs } from 'file-saver'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const QuestionResponsesMore = (): JSX.Element => {
    const location = useLocation()
    const { id, slug, username } = useParams<{ id: string; slug: string; username: string }>()
    const campaignId = location.state?.campaignId as string
    const [mvpResponse, setMVPResponse] = useState<MVPResponse | null>(null)
    const [showQrCodeModal, setShowQrCodeModal] = useState<boolean>(false)
    const [editingTitle, setEditingTitle] = useState<boolean>(false)
    const [editedTitle, setEditedTitle] = useState<string>('')
    const [originalStoryboardTitle, setOriginalStoryboardTitle] = useState<string>('')
    const {
        state: { loading },
        setLoading,
    } = useUserStore()

    const [colorPickerShow, setColorPickerShow] = useState<boolean>(false)

    const bgRef = useRef<HTMLInputElement>(null)
    const bgRefMobile = useRef<HTMLInputElement>(null)

    const [pickColor, setPickColor] = useState<string>('#000000')
    const [colorValue, setColorValue] = useState<string>(pickColor?.replace('#', ''))
    const [colorError, setColorError] = useState<string>('')
    const [storyboard, setStoryboard] = useState<Storyboard | null>(null)
    const [showLoaderForMvpId, setShowLoaderForMvpId] = useState<string | undefined>(
        'a7b3f4f9-2a22-4d82-9706-da9a9da08bfb',
    )

    const navigate = useNavigate()
    const inputRef = useRef<HTMLInputElement | null>(null)

    const [shareModalOpen, setShareModalOpen] = useState<boolean>(false)
    const toggleShareModal = () => setShareModalOpen(!shareModalOpen)

    const [addMVPModalOpen, setAddMVPModalOpen] = useState<boolean>(false)
    const toggleMVPModal = () => setAddMVPModalOpen(!addMVPModalOpen)

    const { setInterviewIds, setSurveyIds, setRagSourcePage } = useRagStore()

    const socket = useWebSocket()

    const fetchStoryBoard = async () => {
        setLoading(true)
        try {
            if (id) {
                const [storyboardResponse] = await Promise.all([StoryboardApi.getByQuestionId(id)])
                setStoryboard(storyboardResponse.data)
                setEditedTitle(
                    storyboardResponse?.data?.storyboard_title ||
                        storyboardResponse?.data?.question.question ||
                        '',
                )
            }
        } catch (e) {
            setStoryboard(null)
            setLoading(false)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!storyboard && mvpResponse?.question_text) {
            setEditedTitle(mvpResponse.question_text)
        }
    }, [mvpResponse])

    useEffect(() => {
        if (shareModalOpen || addMVPModalOpen) {
            document.body.style.overflowY = 'hidden'
        } else {
            document.body.style.overflowY = 'auto'
        }

        return () => {
            document.body.style.overflowY = 'hidden'
        }
    }, [shareModalOpen, addMVPModalOpen])

    useEffect(() => {
        setSurveyIds([])
        setInterviewIds([])
        setRagSourcePage('MORE_RESPONSES_PAGE')
        if (id) {
            void fetchStoryBoard()
        }
    }, [id])

    const saveProfile = async (data: any) => {
        if (!id) return
        setLoading(true)
        try {
            const color = data.finalColor
            let image: string | null | undefined
            let imageMobile: string | null | undefined
            if (data.finalColor) {
                image = null
            } else if (data.bgFile) {
                image = data.bgFile
            }
            if (data.finalColor) {
                imageMobile = null
            } else if (data.bgFileMobile) {
                imageMobile = data.bgFileMobile
            }
            let response
            if (!storyboard) {
                response = await StoryboardApi.save(id, color, image, imageMobile)
            } else {
                response = await StoryboardApi.patch(storyboard?.id, color, image, imageMobile)
            }
            setLoading(false)

            if (response?.status === 200) {
                setStoryboard(response?.data)
            }
        } catch (e) {
            setLoading(false)
        }
    }

    const confirmColor = async () => {
        if (colorValue.length === 3 || colorValue.length === 6) {
            await saveProfile({ finalColor: pickColor })
            setColorPickerShow(false)
        } else {
            setColorError('Hm, I can’t find that HEX code...')
        }
    }

    const cancelColor = () => {
        setColorPickerShow(false)
        setPickColor('#000000')
        setColorValue('000000')
        setColorError('')
    }

    const colorKeyClicked = (e: any) => {
        if (
            (((e.keyCode >= 71 && e.keyCode <= 90) || (e.keyCode >= 103 && e.keyCode <= 122)) &&
                !e.ctrlKey) ||
            e.key === ' '
        ) {
            e.preventDefault()
            return false
        }
    }

    const inputColorValue = (e: any) => {
        let initValue = e.target.value
        if (initValue[0] === '#') initValue = initValue.substring(1)
        let val = initValue.substring(0, 6)
        if (initValue.length === 7 && initValue[0] === '#') val = initValue.substring(1, 7)
        setColorValue(val)
        if (val.length === 3 || val.length === 6) setPickColor('#' + val)
    }

    const changeBgImg = async (e: any) => {
        if (e.target.files.length > 0) {
            await saveProfile({ bgFile: e.target.files[0] })
        }
    }

    const changeBgImageMobile = async (e: any) => {
        if (e.target.files.length > 0) {
            await saveProfile({ bgFileMobile: e.target.files[0] })
        }
    }

    const fetchMVPs = async () => {
        try {
            setLoading(true)
            const response = await QuestionApi.getMVPsByQuestionId(id as string)
            //if (!storyboard) setEditedTitle(response?.data?.question_text)
            setMVPResponse(response.data)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        void fetchMVPs()
    }, [])

    useEffect(() => {
        setShowLoaderForMvpId(location?.state?.updated_nodeio_id)
    }, [location])

    useEffect(() => {
        if (socket) {
            socket.on('audio_file_updated', () => {
                fetchMVPs()
                window.history.replaceState({}, document.title)
                setShowLoaderForMvpId(undefined)
            })
        }

        return () => {
            if (socket) {
                socket.off('audio_file_updated')
            }
        }
    }, [socket])

    const addMVPs = async (mvps: string[]) => {
        try {
            setLoading(true)
            await QuestionApi.updateMVPsByQuestionId(id as string, { user_selected_mvps: mvps })
            await fetchMVPs()

            toast.success(
                <p className="text-[#027A48] text-sm font-semibold">
                    MVPs have beed updated successfully!
                </p>,
                {
                    icon: <img src="/asset/green_check.svg" />,
                },
            )
            toggleMVPModal()
        } catch (e) {
            toast.error('MVP update failed!')
        } finally {
            setLoading(false)
        }
    }

    const deleteMVP = async (mvpId: string) => {
        try {
            setLoading(true)
            const mvps = !_.isEmpty(mvpResponse?.user_selected_mvp_nodeios)
                ? (mvpResponse?.user_selected_mvp_nodeios as Nodeio[])
                : (mvpResponse?.nodeios as Nodeio[])
            await QuestionApi.updateMVPsByQuestionId(id as string, {
                user_selected_mvps: mvps.filter((mvp) => mvp.id !== mvpId).map((mvp) => mvp.id),
            })
            await fetchMVPs()

            toast.success(
                <p className="text-[#027A48] text-sm font-semibold">1 MVP deleted successfully!</p>,
                {
                    icon: <img src="/asset/green_check.svg" />,
                },
            )
        } catch (e) {
            toast.error('MVP delete failed!')
        } finally {
            setLoading(false)
        }
    }

    const onShareClicked = async (): Promise<void> => {
        if (!id) return
        if (!storyboard) {
            const response = await StoryboardApi.save(id)
            if (response?.status === 200) {
                setStoryboard(response?.data)
            }
        }
        toggleShareModal()
    }

    const editStoryboardTitle = () => {
        setOriginalStoryboardTitle(editedTitle)
        setEditingTitle(true)
        if (inputRef && inputRef.current) {
            inputRef.current.focus()
        }
    }

    const updateStoryboardTitle = async () => {
        if (storyboard && storyboard.id && editedTitle !== originalStoryboardTitle) {
            try {
                await StoryboardApi.updateStoryboardTitle(storyboard.id, editedTitle)
            } catch (e) {
                toast.error('Storyboard title update failed!')
            }
        }
        setEditingTitle(false)
    }

    const revertStoryboardTitle = async () => {
        setEditedTitle(originalStoryboardTitle)
        setEditingTitle(false)
    }

    const downloadStoryboardData = async () => {
        try {
            setLoading(true)
            if (!id) return
            const res = await QuestionApi.downloadAudioFiles(id)
            if (res.status === 200) {
                const blob = new Blob([res.data])
                saveAs(blob, `${id}.zip`)
            }
        } catch (e) {
            toast.error('Soundbites download failed!')
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="flex flex-col min-h-screen">
            <div
                className="relative flex flex-col gap-2.5 pt-[30px] w-full px-[25px] sm:px-[30px] pb-[120px] h-[250px] sm:h-[200px]"
                style={{ background: storyboard?.background_color }}
            >
                <div className="flex flex-col sm:flex-row gap-2 sm:gap-5 z-[7]">
                    <div
                        className="flex w-fit h-[34px] bg-[#FFFFFF] rounded-md items-center cursor-pointer select-none hover:bg-[#F0F8FF]"
                        onClick={() => setColorPickerShow(true)}
                    >
                        <img
                            src="/asset/brush_alt.svg"
                            alt="brush_alt"
                            className="p-[8px] h-full w-[34px] bg-[#6BB2FF52] rounded-md"
                        />
                        <a className="text-base font-bold text-[#007AFF] px-2.5">Select color</a>
                    </div>
                    {colorPickerShow && (
                        <div className="flex p-[3px] justify-between h-fit xs:h-[34px] bg-[#D9D9D9] rounded-md  cursor-pointer select-none flex-col xs:flex-row">
                            <div className="flex items-center">
                                <input
                                    className="bg-[#FFFFFF7D] text-[#001F40]  placeholder:text-[#001F407D] max-w-[100px] flex-1 h-full rounded-md px-2 focus:outline-none pl-[19px]"
                                    placeholder="HEX #"
                                    value={colorValue}
                                    onChange={inputColorValue}
                                    onKeyDown={colorKeyClicked}
                                />
                                <div
                                    className={`w-[30px] h-[30px] ml-2 rounded-md`}
                                    style={{ background: pickColor }}
                                />
                                <img
                                    src="/asset/check_circle.svg"
                                    alt="check_circle"
                                    className="w-[20px] h-full ml-2"
                                    onClick={confirmColor}
                                />
                                <img
                                    src="/asset/highlight_off.svg"
                                    alt="highlight_off"
                                    className="w-[20px] h-full ml-[2px] mr-1"
                                    onClick={cancelColor}
                                />
                            </div>
                            {colorError && (
                                <p
                                    className="mt-1 xs:mt-0 ml-1 mr-2 text-[#0D0D0D] text-base overflow-hidden text-ellipsis whitespace-pre flex-1"
                                    style={{ lineHeight: 1.8 }}
                                >
                                    {colorError}
                                </p>
                            )}
                        </div>
                    )}
                </div>
                <div
                    className="flex w-fit h-[34px] bg-[#FFFFFF] rounded-md items-center cursor-pointer select-none hover:bg-[#F0F8FF] z-[7]"
                    onClick={() => bgRef.current?.click()}
                >
                    <img
                        src="/asset/camera_alt.svg"
                        alt="camera_alt"
                        className="p-[8px] h-full w-[34px] bg-[#6BB2FF52] rounded-md"
                    />
                    <a className="text-base font-bold text-[#007AFF] px-2.5">Upload photo</a>
                    <input
                        accept="image/*"
                        type="file"
                        className="hidden"
                        ref={bgRef}
                        onChange={changeBgImg}
                    />
                </div>
                <div
                    className="flex w-fit h-[34px] bg-[#FFFFFF] rounded-md items-center cursor-pointer select-none hover:bg-[#F0F8FF] z-[7]"
                    onClick={() => bgRefMobile.current?.click()}
                >
                    <img
                        src="/asset/camera_alt.svg"
                        alt="camera_alt"
                        className="p-[8px] h-full w-[34px] bg-[#6BB2FF52] rounded-md"
                    />
                    <a className="text-base font-bold text-[#007AFF] px-2.5">
                        Upload photo (Mobile)
                    </a>
                    <input
                        accept="image/*"
                        type="file"
                        className="hidden"
                        ref={bgRefMobile}
                        onChange={changeBgImageMobile}
                    />
                </div>
                <p className="text-xs italic text-[#007AFF] bg-gray-300 w-fit z-10">
                    Note: If image, we recommend an
                    <br />
                    aspect ratio of 4:1 for web and 3:2 for mobile
                </p>
                {storyboard?.background_image_url && (
                    <img
                        src={storyboard?.background_image_url}
                        className="w-full h-full absolute object-cover mt-[-30px] mx-[-25px] sm:mx-[-50px] sm:ml-[-30px] mb-[-120px] z-[5]"
                    />
                )}
            </div>
            <div className="flex-1 flex justify-center bg-[#0C1B2C] pb-4">
                <div className="mx-2 lg:mx-12 xl:mx-32 flex flex-col flex-1">
                    <div className="flex flex-col xs:flex-row gap-3 my-6 mx-4 items-center">
                        <button
                            className="text-[#007AFF] text-base self-start self-center mr-3"
                            onClick={() =>
                                navigate(
                                    generatePath(Page.QUESTION_LANDING_PAGE, {
                                        username: username as string,
                                        slug: slug as string,
                                    }),
                                    {
                                        state: {
                                            campaignId: campaignId,
                                            questionIndex: location?.state?.questionIndex ?? 0,
                                        },
                                    },
                                )
                            }
                        >
                            Back
                        </button>
                        <div className="flex flex-row flex-1 justify-center">
                            {editingTitle ? (
                                <input
                                    ref={inputRef}
                                    className="w-fit sm:w-full text-[16px] sm:text-[20px] font-bold text-[#BFDEFF] outline-none focus-within:outline-none bg-transparent"
                                    value={editedTitle}
                                    onChange={(e) => setEditedTitle(e.target.value)}
                                    onFocus={(e) =>
                                        e.currentTarget.setSelectionRange(
                                            e.currentTarget.value.length,
                                            e.currentTarget.value.length,
                                        )
                                    }
                                />
                            ) : (
                                <p className="text-center text-[#BFDEFF] text-2xl">{editedTitle}</p>
                            )}
                            {editingTitle ? (
                                <div className="flex flex-row items-center gap-2 sm:gap-4">
                                    <button
                                        className="text-[#007AFF] font-lato font-bold text-sm sm:text-base p-2"
                                        onClick={() => revertStoryboardTitle()}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="text-[#007AFF] font-lato font-bold text-sm sm:text-base p-2"
                                        onClick={() => updateStoryboardTitle()}
                                    >
                                        Save
                                    </button>
                                </div>
                            ) : (
                                <button onClick={editStoryboardTitle}>
                                    <img
                                        src="/asset/pencil.svg"
                                        className="w-[20px] h-[20px] ml-2"
                                    />
                                </button>
                            )}
                        </div>
                    </div>

                    <div className="flex justify-between flex-col md:flex-row gap-3 my-3 mx-4">
                        <div className="flex items-center gap-3">
                            <p className="text-white text-lg">Soundbites</p>
                            <p className="p-0.5 px-2 text-sm my-auto text-white border-white border rounded-full">
                                {mvpResponse?.user_selected_mvp_nodeios?.length ?? '0'}
                            </p>
                        </div>
                        <div className="flex items-center gap-3 mt-2 md:mt-0 self-end">
                            <button
                                className="text-[#007AFF] text-sm flex items-center p-2 px-4 rounded-2xl border-[#007AFF] border-2"
                                onClick={downloadStoryboardData}
                            >
                                <img src="/asset/download-icon.svg" alt="download_button" />
                                Download soundbites
                            </button>
                            <button
                                className="text-[#007AFF] text-sm flex items-center p-2 px-4 rounded-2xl border-[#007AFF] border-2"
                                onClick={toggleMVPModal}
                            >
                                <img src="/asset/pencil.svg" className="mr-1.5" />
                                Add Soundbite
                            </button>
                            <button
                                className="text-white text-sm flex items-center p-2 px-4 rounded-2xl bg-[#007AFF]"
                                onClick={onShareClicked}
                            >
                                <img src="/asset/back-white.svg" className="mr-1" />
                                Share
                            </button>
                        </div>
                    </div>

                    {!_.isEmpty(mvpResponse?.user_selected_mvp_nodeios) ||
                    !_.isEmpty(mvpResponse?.nodeios) ? (
                        <MVPCardGrid
                            mvps={mvpResponse?.user_selected_mvp_nodeios as Nodeio[]}
                            type="storybook"
                            onDelete={deleteMVP}
                            mvpsByAI={mvpResponse?.mvps}
                            slug={slug as string}
                            showLoadingForId={showLoaderForMvpId}
                        />
                    ) : (
                        <p className="mt-10 text-center text-sm text-white">No MVPs at this time</p>
                    )}
                </div>
            </div>

            <MVPShareModal
                open={shareModalOpen}
                onToggle={toggleShareModal}
                url={`${window.location.host}/mvps/${id}`}
                onShowQrCode={() => {
                    setShareModalOpen(false)
                    setShowQrCodeModal(true)
                }}
            />

            {addMVPModalOpen && (
                <MVPCardListModal
                    open={true}
                    onToggle={toggleMVPModal}
                    onSuccess={addMVPs}
                    questionId={id}
                    mvps={[...(mvpResponse?.user_selected_mvps ?? [])]}
                />
            )}

            {loading && <Spinner />}
            <div>
                {showQrCodeModal && (
                    <ShareStoryboardQrModal
                        url={`${window.location.origin}/mvps/${id}`}
                        onClose={() => setShowQrCodeModal(false)}
                    />
                )}
            </div>
        </div>
    )
}
export default QuestionResponsesMore
