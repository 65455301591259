import { RecallAiMeetingsApi } from '../api/recallaiMeetings'
import { RecallaiMeetingType } from '../types/RecallaiMeeting'
import { Label, Select, TextInput, Button } from 'flowbite-react'
import { ChangeEvent, useState } from 'react'
import { toast } from 'react-toastify'

interface MeetingLinkModalProps {
    authorId: string
    campaignId: string | null
    onClose: () => void
}

const MeetingLinkModal = ({
    authorId,
    campaignId,
    onClose,
}: MeetingLinkModalProps): JSX.Element => {
    const [meetingType, setMeetingType] = useState<string>('meet')
    const [meetingTitle, setMeetingTitle] = useState<string>('')
    const [meetingUrl, setMeetingUrl] = useState<string>('')

    const parseMeetingLinkText = (meetingType: string, meetingUrl: string) => {
        // Parse this URL to check if it's a valid URL from input below:
        // Output should be like this: https://meet.google.com/abc-defg-hij for Google Meet
        // Output should be like this: https://teams.microsoft.com/l/meetup-join/abc-defg-hij for Microsoft Teams

        let matchedUrl = undefined
        if (meetingType === RecallaiMeetingType.MEET) {
            matchedUrl = meetingUrl.split(' ').find((word) => word.startsWith('https://meet'))
            if (!matchedUrl) {
                matchedUrl = meetingUrl
                    .split(' ')
                    .find((word) => word.startsWith('meet.google.com'))
            }
        } else if (meetingType === RecallaiMeetingType.TEAMS) {
            matchedUrl = meetingUrl.split(' ').find((word) => word.startsWith('https://teams'))
            if (!matchedUrl) {
                matchedUrl = meetingUrl
                    .split(' ')
                    .find((word) => word.startsWith('teams.microsoft.com'))
            }
        }

        //if url doesn't start with https://, add it
        if (matchedUrl && !matchedUrl.startsWith('https://')) {
            matchedUrl = 'https://' + matchedUrl
        }

        return matchedUrl
    }

    const addToneAiToMeetingClicked = () => {
        // console.log('Add Tone AI to Meeting clicked')
        // console.log('meetingType:', meetingType)
        // console.log('meetingTitle:', meetingTitle)
        // console.log('meetingUrl:', meetingUrl)
        // console.log('authorId:', authorId)
        // console.log('campaignId:', campaignId)

        try {
            const matchedUrl = parseMeetingLinkText(meetingType, meetingUrl)
            //console.log('addToneAiToMeetingClicked - original Url text: ', meetingUrl)
            //console.log('addToneAiToMeetingClicked - matched Url: ', matchedUrl)
            if (!matchedUrl) {
                toast.error('Invalid meeting URL!  Please enter a valid meeting URL.')
                return
            }

            if (campaignId && authorId) {
                RecallAiMeetingsApi.saveMeetingLink({
                    meeting_type: meetingType as RecallaiMeetingType,
                    meeting_url: matchedUrl,
                    meeting_status: 'scheduled',
                    meeting_title: meetingTitle || 'Meeting at ' + new Date().toLocaleString(),
                    recallai_bot_id: '',
                    campaign_id: campaignId,
                    author_id: authorId,
                })

                toast.success('Tone AI meeting assistant added to the meeting!')
            } else {
                // eslint-disable-next-line no-console
                console.log(
                    'Unable to add Tone AI meeting assistant to the meeting!  Please specify a valid campaign ID and author ID.',
                    campaignId,
                    authorId,
                )
                toast.error('Unable to add Tone AI meeting assistant to the meeting!')
            }
        } catch (e) {
            toast.error('Unable to add Tone AI meeting assistant to the meeting!')
        } finally {
            onClose()
        }
    }

    return (
        <div className="fixed top-0 left-0 w-full h-full bg-[#00000080] flex flex-col justify-center items-center z-[99999]">
            <div className="bg-white w-full max-w-[90%] sm:max-w-[80%] md:max-w-[620px] rounded-[30px] items-center">
                <div className="mt-[75px] items-center justify-start">
                    <div className="mx-20">
                        <div className="mb-2 block">
                            <Label htmlFor="countries" value="Select your meeting type" />
                        </div>
                        <Select
                            id="meetingTypes"
                            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                setMeetingType(e.target.value)
                            }}
                            required
                        >
                            <option value="meet">Google Meet</option>
                            <option value="teams">Microsoft Teams</option>
                            {/* <option value="zoom">Zoom</option>
                            <option value="slack">Slack</option> */}
                        </Select>
                    </div>
                    <div className="mx-20 my-10">
                        <div className="mb-2 block">
                            <Label htmlFor="title" value="Specify a meeting title (optional)" />
                        </div>
                        <TextInput
                            id="title"
                            type="text"
                            placeholder="Meeting Title"
                            value={meetingTitle}
                            onChange={(e) => setMeetingTitle(e.target.value)}
                        />
                    </div>
                    <div className="mx-20 mb-10">
                        <div className="mb-2 block">
                            <Label htmlFor="url" value="Specify a meeting url" />
                        </div>
                        <TextInput
                            id="url"
                            type="text"
                            placeholder="Meeting URL (https://...)"
                            value={meetingUrl}
                            onChange={(e) => setMeetingUrl(e.target.value)}
                            required
                        />
                    </div>
                </div>
                <div className="flex flex-row gap-4 mx-20 mb-[75px]">
                    <Button
                        outline
                        color="primary"
                        className="text-[#007AFF] text-base mt-3 text-center bg-[#F2F2F2] w-[200px]"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        outline
                        color="primary"
                        className="text-base mt-3 text-center bg-[#007AFF] text-white w-[200px]"
                        onClick={addToneAiToMeetingClicked}
                    >
                        Add To Meeting
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default MeetingLinkModal
