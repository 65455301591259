import SelectLanguage from '../components/SelectLanguage'
import { useTranslation } from 'react-i18next'

const SuccessfullySavedResponses = (): JSX.Element => {
    const { t } = useTranslation()

    return (
        <div className="w-full h-screen relative flex flex-col justify-center items-center">
            <SelectLanguage />
            <div className="relative w-full h-[250px] bg-[#E4F1FF]" />
            <div className="flex flex-1 bg-white"></div>
            <div className="absolute flex flex-col items-center h-screen w-screen md:w-fit bg-transparent px-[25px] md:px-[0px]">
                <div
                    className="mt-[150px] w-full md:w-[720px] px-[70px] py-[60px] rounded-lg"
                    style={{
                        background:
                            'var(--card, linear-gradient(313deg, rgba(107, 178, 255, 0.19) 7.36%, rgba(0, 122, 255, 0.13) 33.85%, rgba(107, 178, 255, 0.13) 68.75%, rgba(0, 122, 255, 0.19) 93.75%), #0D0D0D)',
                    }}
                >
                    <div className="flex items-center gap-2">
                        <img src="/asset/check.svg" />
                        <p className="text-[25px] text-white">
                            {t('successfully_saved_responses.title')}
                        </p>
                    </div>
                    {/* <p className="text-[#BFDEFF] text-[13px] mt-4">
                        {t('successfully_saved_responses.message')}
                    </p> */}
                </div>
            </div>
        </div>
    )
}
export default SuccessfullySavedResponses
