import { User } from '../types/User'
import ApiService from './apiService'
import { AxiosResponse } from 'axios'

export interface UserApiInterface {
    getAuthenticatedUser(): Promise<AxiosResponse<User>>
}

export const UserApi: UserApiInterface = Object.freeze({
    getAuthenticatedUser(): Promise<AxiosResponse<User>> {
        return ApiService.get('/users/web')
    },
})
