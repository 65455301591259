import { useQuestionStore } from '../context/question/store'
import { Response, ResponseNote } from '../pages/QuestionResponder'
import { Question } from '../types/Question'
import { QuestionTypeSettings } from '../types/QuestionType'
import RecorderButton from './RecorderButton'
import { useTranslation } from 'react-i18next'
import TextareaAutosize from 'react-textarea-autosize'

interface VoiceOnlyResponderProps {
    setResponses?: (value: Response[] | ((prevVar: Response[]) => Response[])) => void
    setRequiredToAllResponds?: (v: boolean | ((prevVar: boolean) => boolean)) => void
    setQuestionIndex?: (index: number | ((prevVar: number) => number)) => void
    setIsRecordingSupported?: (v: boolean | ((prevVar: boolean) => boolean)) => void
    setResponseNotes?: (
        responseNotes: ResponseNote[] | ((prevVar: ResponseNote[]) => ResponseNote[]),
    ) => void
    setIsRecording?: (v: boolean | ((prevVar: boolean) => boolean)) => void
    selectedQuestion?: Question | null
    questionIndex?: number
    responseNoteForSelectedQuestion?: ResponseNote | undefined
    questions?: Question[]
    disabled?: boolean
    isQuestionResponder?: boolean
    showNotes?: boolean
    isDarkModeEnabled?: boolean
}

const VoiceOnlyResponder = ({
    setResponses,
    setRequiredToAllResponds,
    setQuestionIndex,
    setIsRecordingSupported,
    setResponseNotes,
    setIsRecording,
    selectedQuestion,
    questionIndex,
    responseNoteForSelectedQuestion,
    questions,
    disabled = false,
    isQuestionResponder = false,
    showNotes = false,
    isDarkModeEnabled = false,
}: VoiceOnlyResponderProps) => {
    const {
        state: { showTip },
    } = useQuestionStore()
    const { t } = useTranslation()

    return (
        <div>
            <div className="flex flex-col md:flex-row gap-6 mt-8 px-2 items-center md:items-start w-full justify-center">
                <div className="xl:w-[320px] flex flex-col items-center mx-2 justify-center w-full">
                    <RecorderButton
                        onStop={(formData, audioLength) => {
                            setResponses &&
                                setResponses((responses) => [
                                    ...responses,
                                    {
                                        questionId: selectedQuestion?.id!,
                                        length: audioLength,
                                        formData,
                                        title: '',
                                    },
                                ])
                            setRequiredToAllResponds && setRequiredToAllResponds(false)
                            if (
                                questions &&
                                typeof questionIndex === 'number' &&
                                questionIndex < questions.length - 1
                            )
                                setQuestionIndex && setQuestionIndex((v) => v + 1)
                        }}
                        onRecorderNotSupported={() =>
                            setIsRecordingSupported && setIsRecordingSupported(false)
                        }
                        isQuestionResponder={true}
                        disabled={disabled}
                        setIsRecording={setIsRecording}
                        isDarkModeEnabled={isDarkModeEnabled}
                    />
                </div>
                {showNotes && (
                    <div className="h-[220px] w-full flex flex-col mb-3">
                        <TextareaAutosize
                            className="flex-1 rounded-lg bg-white text-[#001F40] disabled:bg-white focus:outline-none focus:border-[var(--gray-300,_#D0D5DD)] placeholder-[#8E8D93] text-md bg-transparent resize-none mb-2 leading-[1.2] w-full border-[var(--gray-300,_#D0D5DD)] border-[1px] shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] p-3"
                            placeholder={t('recorder_button.write_your_notes_here')}
                            value={responseNoteForSelectedQuestion?.note}
                            onChange={(e) => {
                                setResponseNotes &&
                                    setResponseNotes((responseNotes) => {
                                        let resNotes = [...responseNotes]
                                        resNotes.splice(
                                            responseNotes.findIndex(
                                                (responseNote) =>
                                                    responseNote.questionId ===
                                                    selectedQuestion?.id,
                                            ),
                                            1,
                                            {
                                                ...(responseNoteForSelectedQuestion as ResponseNote),
                                                note: e.target.value,
                                            },
                                        )
                                        return resNotes
                                    })
                            }}
                            disabled={disabled}
                        />
                    </div>
                )}
            </div>
            {selectedQuestion?.question_settings.type ===
                QuestionTypeSettings.VIDEO_OR_AUDIO_OR_TEXT && (
                <button
                    className="flex items-center gap-2 mt-1.5"
                    onClick={() =>
                        setResponseNotes &&
                        setResponseNotes((responseNotes) => {
                            let resNotes = [...responseNotes]
                            resNotes.splice(
                                responseNotes.findIndex(
                                    (responseNote) =>
                                        responseNote.questionId === selectedQuestion?.id,
                                ),
                                1,
                                {
                                    ...(responseNoteForSelectedQuestion as ResponseNote),
                                    selectedQuestionType: undefined,
                                    title: '',
                                },
                            )
                            return resNotes
                        })
                    }
                >
                    <div className="flex flex-row ml-4 mt-4 items-center gap-2">
                        <div className="p-[9px] bg-[#D1E9FF] rounded-full">
                            <div className="p-[3px] bg-[#007AFF] rounded-full">
                                <img
                                    src="/asset/message-text-circle.svg"
                                    className="w-[20px] h-[20px]"
                                />
                            </div>
                        </div>
                        <p
                            className={`${
                                isDarkModeEnabled ? 'text-[#FFFFFF]' : 'text-[#001F40]'
                            } text-xs`}
                        >
                            {t('question_responder_page.select_how_you_want_to_answer')}
                        </p>
                    </div>
                </button>
            )}
            {selectedQuestion?.question_settings.type === QuestionTypeSettings.VOICE_OR_TEXT && (
                <button
                    className="flex items-center gap-2 mt-1.5"
                    onClick={() =>
                        setResponseNotes &&
                        setResponseNotes((responseNotes) => {
                            let resNotes = [...responseNotes]
                            resNotes.splice(
                                responseNotes.findIndex(
                                    (responseNote) =>
                                        responseNote.questionId === selectedQuestion?.id,
                                ),
                                1,
                                {
                                    ...(responseNoteForSelectedQuestion as ResponseNote),
                                    selectedQuestionType: QuestionTypeSettings.FREE_TEXT,
                                    title: '',
                                },
                            )
                            return resNotes
                        })
                    }
                >
                    <div className="">
                        <div className="p-[9px] bg-[#D1E9FF] rounded-full">
                            <div className="p-[3px] bg-[#007AFF] rounded-full">
                                <img
                                    src="/asset/message-text-circle.svg"
                                    className="w-[20px] h-[20px]"
                                />
                            </div>
                        </div>
                    </div>
                    <p
                        className={`${
                            isDarkModeEnabled ? 'text-[#FFFFFF]' : 'text-[#001F40]'
                        } text-xs`}
                    >
                        {t('question_responder_page.type_instead')}
                    </p>
                </button>
            )}
            {isQuestionResponder && showTip && (
                <div className="flex items-center justify-center gap-8 mt-3">
                    <div className="h-[46px] mb-6">
                        <p
                            className={`text-[15px] ${
                                isDarkModeEnabled ? 'text-[#FFFFFF]' : 'text-[#007AFF]'
                            } bg-[rgba(182,_214,_250,_0.37)] rounded-lg px-6 py-3 w-fit flex items-center mx-auto`}
                        >
                            🌍 {t('recorder_button.speak_in_any_major_language')}
                        </p>
                    </div>
                </div>
            )}
        </div>
    )
}

export default VoiceOnlyResponder
