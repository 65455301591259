import React from 'react'

export interface AutoSuggestedSingleOptionProps {
    text: string
    onClick: () => void
}

const AutoSuggestedSingleOption = ({
    text,
    onClick,
}: AutoSuggestedSingleOptionProps): JSX.Element => {
    return (
        <button
            className=" p-2 px-2 border border-[#D0D5DD] rounded-lg text-left"
            onClick={onClick}
        >
            {text}
        </button>
    )
}

export default AutoSuggestedSingleOption
