import { routes } from '../components/RouterWrapper'
import { CookieKey } from '../enums/CookieKey'
import { Page } from '../enums/Page'
import { deleteCookie, getCookie, setCookie } from '../utils/useCookies'
import { AuthenticationApi } from './authentication'
import axios, { AxiosInstance, AxiosResponse, RawAxiosRequestHeaders } from 'axios'
import moment from 'moment'

export const CreateApiClient = async (timeout: number = 30000): Promise<AxiosInstance> => {
    const token = getCookie(CookieKey.JWT_TOKEN)
    const refreshToken = getCookie(CookieKey.REFRESH_TOKEN)

    const headers: RawAxiosRequestHeaders = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
    }

    const apiClient = axios.create({
        baseURL:
            process.env.REACT_APP_ENV === 'production'
                ? process.env.REACT_APP_PRODUCTION_SERVER_URL
                : process.env.REACT_APP_TEST_SERVER_URL,
        withCredentials: true,
        headers,
        timeout,
    })

    apiClient.interceptors.response.use(
        (response: AxiosResponse) => {
            return response
        },
        async function (error: Error) {
            return Promise.reject(error)
        },
    )

    apiClient.interceptors.response.use(
        (response) => {
            return response
        },
        async function (error) {
            const originalRequest = error.config
            if (error?.response?.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true
                if (refreshToken.length) {
                    try {
                        const response = await AuthenticationApi.refreshToken(refreshToken)
                        setCookie(CookieKey.JWT_TOKEN, response?.data.token, {
                            expires: moment().add('1', 'day').toDate(),
                        })
                        originalRequest.headers.Authorization = `Bearer ${response?.data.token}`
                        return axios(originalRequest)
                    } catch (err) {
                        deleteCookie(CookieKey.JWT_TOKEN)
                        deleteCookie(CookieKey.REFRESH_TOKEN)
                        window.location.href = Page.SIGN_IN
                    }
                } else {
                    const route = routes.find((route) => route.path === window.location.pathname)
                    if (route && route.guard) {
                        window.location.href = Page.SIGN_IN
                    }
                }
            }
            return Promise.reject(error)
        },
    )

    // Redirect inactivated user to the login page
    apiClient.interceptors.response.use(
        (response) => {
            return response
        },
        async function (error) {
            if (
                error?.response?.status === 403 &&
                error?.response?.data.message === 'inactive user' &&
                !window.location.href.includes('/signin')
            ) {
                deleteCookie(CookieKey.JWT_TOKEN)
                deleteCookie(CookieKey.REFRESH_TOKEN)
                window.location.href = '/signin'
                return
            } else if (
                error?.response?.status === 403 &&
                error?.response?.data.message === 'Permission denied according to assigned roles.'
            ) {
                window.location.href = '/'
                return
            }
            return Promise.reject(error)
        },
    )

    return apiClient
}
