import { EmotionsByThemeResponse } from '../types/EmotionsByThemeResponse'
import { InterviewResponse } from '../types/InterviewResponse'
import TextWithViewMore from './TextWithViewMore'
import React, { useEffect, useState } from 'react'
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'

export interface InterviewLandingPageSingleSoundbiteProps {
    response: InterviewResponse
    emotionsData: EmotionsByThemeResponse[]
    audioRef: any
    selectedTopic: string | null
    selectedTone: string | undefined
    onS3KeyExpired: () => void
}

const InterviewLandingPageSingleSoundbite = ({
    response,
    emotionsData,
    audioRef,
    selectedTopic,
    selectedTone,
    onS3KeyExpired,
}: InterviewLandingPageSingleSoundbiteProps): JSX.Element => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false)

    useEffect(() => {
        setIsExpanded(false)
    }, [selectedTopic])

    const theme = response.themes.find((theme) => theme.theme_name === selectedTopic)

    const themeEmotionsData = emotionsData?.find(
        (emotions) => emotions.interview_id === response.id,
    )

    const filteredEmotions = themeEmotionsData?.hume_data?.filter((section: any) => {
        return section.emotions.filter((emotion: any) => emotion.name === selectedTone).length > 0
    })

    const onCardClick = () => {
        setIsExpanded(!isExpanded)
    }

    return (
        <div
            className="flex flex-col bg-[#BFDEFF] rounded-2xl px-2 sm:px-6 py-[12px] sm:py-[18px]"
            onClick={onCardClick}
        >
            <div className="flex flex-row w-full justify-between items-center">
                <div className="flex">
                    <p className="font-bold">{response?.title}</p>
                </div>
                <div className="flex flex-col sm:flex-row items-center gap-4 w-full sm:w-auto">
                    <div
                        className={`h-10 w-full sm:w-[500px] ${
                            selectedTone ? 'tone-selected' : ''
                        } relative`} // Add 'relative' here to make the position of child absolute elements relative to this
                        id="interview-landing-page-player"
                        style={{
                            display: 'grid', // Use grid layout for positioning
                        }}
                    >
                        {/* Overlay Emotion Bars */}
                        <div
                            className="absolute inset-0 w-full" // Make this div overlay the audio player
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'start',
                                alignItems: 'center',
                                paddingLeft: '47px', // Adjust this as needed to align with your player's progress bar
                                paddingRight: '110px',
                            }}
                        >
                            {filteredEmotions?.map((emotion: any, index: any) => {
                                const totalDuration =
                                    audioRef.current?.audio?.current?.duration ?? 0

                                const isLast = index === filteredEmotions.length - 1
                                const isFirst = index === 0

                                const humeEmotion = emotion.emotions.find(
                                    (e: any) => e.name == selectedTone,
                                )
                                return (
                                    <div
                                        key={index}
                                        style={{
                                            width: `${
                                                ((emotion?.time?.end - emotion?.time?.begin) /
                                                    totalDuration) *
                                                100
                                            }%`,
                                            left: `${
                                                (emotion?.time?.begin / totalDuration) * 100
                                            }%`,
                                            height: '14px',
                                            backgroundColor:
                                                humeEmotion?.score && humeEmotion.score > 0.05
                                                    ? '#007AFF'
                                                    : 'white',
                                            borderTopLeftRadius: isFirst ? '35px' : '0px',
                                            borderBottomLeftRadius: isFirst ? '35px' : '0px',
                                            borderTopRightRadius: isLast ? '35px' : '0px',
                                            borderBottomRightRadius: isLast ? '35px' : '0px',
                                        }}
                                    />
                                )
                            })}
                        </div>

                        {/* Audio Player */}
                        <AudioPlayer
                            className="bg-transparent p-0 shadow-none"
                            ref={audioRef}
                            src={theme?.s3_key}
                            customProgressBarSection={[
                                RHAP_UI.MAIN_CONTROLS,
                                RHAP_UI.PROGRESS_BAR,
                                <div className="ml-4" />,
                                RHAP_UI.CURRENT_TIME,
                                <div>/</div>,
                                // <div className="ovde relative">
                                // </div>,
                                RHAP_UI.DURATION,
                            ]}
                            showJumpControls={false}
                            customVolumeControls={[]}
                            customAdditionalControls={[]}
                            customControlsSection={[]}
                            customIcons={{
                                play: (
                                    <img
                                        src="/asset/play-button-icon.svg"
                                        width={30}
                                        height={30}
                                        alt="play_button"
                                    />
                                ),
                                pause: (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={2}
                                        stroke="#6BB2FF"
                                        className="w-[30px] h-[30px]"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M14.25 9v6m-4.5 0V9M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                        />
                                    </svg>
                                ),
                            }}
                            timeFormat="mm:ss"
                            loop={false}
                            autoPlay={false}
                            autoPlayAfterSrcChange={false}
                            onError={() => {
                                // check if the s3 url has expired
                                fetch(theme?.s3_key ?? '').then((response) => {
                                    if (response.status === 403) {
                                        onS3KeyExpired()
                                    }
                                })
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-row">
                <TextWithViewMore
                    text={
                        theme?.formatted_text?.map((formattedText) => formattedText.text).join() ??
                        ''
                    }
                    title={''}
                />
            </div>
        </div>
    )
}

export default InterviewLandingPageSingleSoundbite
