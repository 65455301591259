import React, { useEffect, useRef, useState } from 'react'

export interface InterviewLandingPageTopicsProps {
    topics: string[]
    selectedTopic: string | null
    noInterviewResponses: boolean
    onSelectTopic: (topic: string) => void
}

const InterviewLandingPageTopics = ({
    topics,
    selectedTopic,
    onSelectTopic,
    noInterviewResponses = false,
}: InterviewLandingPageTopicsProps): JSX.Element => {
    const scrollContainerRef = useRef<HTMLDivElement>(null)
    const [disableScrollLeft, setDisableScrollLeft] = useState<boolean>(true)
    const [disableScrollRight, setDisableScrollRight] = useState<boolean>(false)

    useEffect(() => {
        if (scrollContainerRef.current) {
            const container = scrollContainerRef.current
            if (container.scrollWidth <= container.clientWidth) {
                setDisableScrollRight(true)
            }
        }
    }, [])

    const handleScrollRight = () => {
        if (scrollContainerRef.current) {
            const container = scrollContainerRef.current
            container.scrollLeft += 200
            if (container.scrollLeft >= container.scrollWidth - container.clientWidth) {
                setDisableScrollRight(true)
            }
            setDisableScrollLeft(false)
        }
    }

    const handleScrollLeft = () => {
        if (scrollContainerRef.current) {
            const container = scrollContainerRef.current
            container.scrollLeft -= 200
            if (container.scrollLeft <= 0) {
                setDisableScrollLeft(true)
            }
            setDisableScrollRight(false)
        }
    }

    return (
        <div className="flex items-center gap-8 mt-[10px] w-full">
            <div className="flex flex-row gap-2 sm:gap-4 items-center">
                <p className="uppercase text-[#007AFF] text-sm sm:text-base font-lato font-bold">
                    Topic
                </p>
                <span className="text-sm text-[#007AFF] sm:text-base px-2 py-.5 bg-white border-[1.5px] border-[#007AFF] rounded-2xl text-center flex items-center justify-center">
                    {topics.length}
                </span>
                <button className="sm:p-2" onClick={handleScrollLeft} disabled={disableScrollLeft}>
                    <svg
                        className="rotate-90"
                        width="24"
                        height="13"
                        viewBox="0 0 24 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M10.9393 12.2286C11.5251 12.8144 12.4749 12.8144 13.0607 12.2286L22.6066 2.68269C23.1924 2.0969 23.1924 1.14715 22.6066 0.561367C22.0208 -0.0244198 21.0711 -0.0244198 20.4853 0.561367L12 9.04665L3.51472 0.561367C2.92893 -0.0244198 1.97919 -0.0244198 1.3934 0.561367C0.807611 1.14715 0.807611 2.0969 1.3934 2.68269L10.9393 12.2286ZM13.5 11.168V11.0013L10.5 11.0013V11.168L13.5 11.168Z"
                            fill={disableScrollLeft ? '#007AFF30' : '#007AFF'}
                        />
                    </svg>
                </button>
            </div>
            <div
                className="flex w-full overflow-scroll custom-scroll-container"
                ref={scrollContainerRef}
            >
                <div className="flex flex-row gap-2 sm:gap-4">
                    {!noInterviewResponses &&
                        topics.map((topic, index) => {
                            return (
                                <button
                                    onClick={() => onSelectTopic(topic)}
                                    className={`${
                                        topic === selectedTopic ? 'bg-[#007AFF]' : 'bg-[#B6D6FA]'
                                    } inline-block pl-3 sm:pl-5 pr-4 sm:pr-7 py-3 rounded-[35px] w-[auto] sm:w-[auto]`}
                                    key={index}
                                    style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }} // Added inline styles for no wrap
                                >
                                    <p
                                        className={`${
                                            topic === selectedTopic ? 'text-white' : 'text-black'
                                        } text-sm sm:text-lg leading-[15px] sm:leading-[30px] font-lato font-bold`}
                                    >
                                        {topic}
                                    </p>
                                </button>
                            )
                        })}
                    {noInterviewResponses && (
                        <p className="text-gray-500 text-sm sm:text-lg leading-[15px] sm:leading-[30px] font-lato font-bold">
                            Waiting for voices...
                        </p>
                    )}
                </div>
            </div>
            <div className="flex flex-row gap-2 sm:gap-4">
                {!noInterviewResponses && (
                    <button
                        className="sm:p-2"
                        onClick={handleScrollRight}
                        disabled={disableScrollRight}
                    >
                        <svg
                            className="rotate-[270deg]"
                            width="24"
                            height="13"
                            viewBox="0 0 24 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M10.9393 12.2286C11.5251 12.8144 12.4749 12.8144 13.0607 12.2286L22.6066 2.68269C23.1924 2.0969 23.1924 1.14715 22.6066 0.561367C22.0208 -0.0244198 21.0711 -0.0244198 20.4853 0.561367L12 9.04665L3.51472 0.561367C2.92893 -0.0244198 1.97919 -0.0244198 1.3934 0.561367C0.807611 1.14715 0.807611 2.0969 1.3934 2.68269L10.9393 12.2286ZM13.5 11.168V11.0013L10.5 11.0013V11.168L13.5 11.168Z"
                                fill={disableScrollRight ? '#007AFF30' : '#007AFF'}
                            />
                        </svg>
                    </button>
                )}
                {/*<button className="p-2">*/}
                {/*    <svg*/}
                {/*        width="31"*/}
                {/*        height="31"*/}
                {/*        viewBox="0 0 31 31"*/}
                {/*        fill="none"*/}
                {/*        xmlns="http://www.w3.org/2000/svg"*/}
                {/*    >*/}
                {/*        <path*/}
                {/*            d="M15.4973 2.90717C8.55319 2.90717 2.90356 8.5574 2.90356 15.5009C2.90356 22.4553 8.5429 28.0947 15.4973 28.0947C22.4414 28.0947 28.0911 22.445 28.0911 15.5009C28.0911 8.5459 22.4523 2.90717 15.4973 2.90717ZM15.4973 21.8583C13.9879 21.8583 12.5402 21.2587 11.4729 20.1914C10.4055 19.124 9.80591 17.6764 9.80591 16.1669C9.80591 14.6575 10.4055 13.2098 11.4729 12.1425C12.5402 11.0752 13.9879 10.4755 15.4973 10.4755H15.7522L14.8979 9.62182C14.7389 9.46284 14.6496 9.24723 14.6496 9.0224C14.6496 8.79758 14.7389 8.58196 14.8979 8.42299C15.0569 8.26401 15.2725 8.1747 15.4973 8.1747C15.7221 8.1747 15.9378 8.26401 16.0967 8.42299L18.5186 10.8449C18.5973 10.9236 18.6598 11.017 18.7024 11.1199C18.745 11.2227 18.7669 11.333 18.7669 11.4443C18.7669 11.5556 18.745 11.6658 18.7024 11.7687C18.6598 11.8715 18.5973 11.965 18.5186 12.0437L16.0967 14.4656C15.9378 14.6245 15.7221 14.7139 15.4973 14.7139C15.2725 14.7139 15.0569 14.6245 14.8979 14.4656C14.7389 14.3066 14.6496 14.091 14.6496 13.8662C14.6496 13.6413 14.7389 13.4257 14.8979 13.2667L15.9877 12.1769C15.8436 12.1708 15.679 12.1708 15.4973 12.1708C14.707 12.1708 13.9344 12.4052 13.2772 12.8443C12.6201 13.2834 12.1079 13.9075 11.8054 14.6377C11.503 15.3679 11.4238 16.1714 11.578 16.9465C11.7322 17.7217 12.1128 18.4337 12.6717 18.9926C13.2305 19.5515 13.9426 19.9321 14.7177 20.0862C15.4929 20.2404 16.2964 20.1613 17.0266 19.8588C17.7567 19.5564 18.3809 19.0442 18.8199 18.387C19.259 17.7299 19.4934 16.9573 19.4934 16.1669C19.4934 15.9421 19.5827 15.7265 19.7417 15.5675C19.9007 15.4086 20.1163 15.3193 20.3411 15.3193C20.5659 15.3193 20.7815 15.4086 20.9405 15.5675C21.0994 15.7265 21.1887 15.9421 21.1887 16.1669C21.187 17.6758 20.5868 19.1225 19.5198 20.1894C18.4528 21.2564 17.0062 21.8566 15.4973 21.8583Z"*/}
                {/*            fill="#007AFF"*/}
                {/*        />*/}
                {/*    </svg>*/}
                {/*</button>*/}
            </div>
        </div>
    )
}

export default InterviewLandingPageTopics
