import { ClipsApi } from '../api/clips'
import { ReelsApi } from '../api/reels'
import CreateReelPageSingleClipItem from '../components/CreateReelPageSingleClipItem'
import Spinner from '../components/Spinner'
import ToggleSwitch from '../components/ToggleSwitch'
import TrimClipModal from '../components/TrimClipModal'
import { useRagStore } from '../context/rag/store'
import { useUserStore } from '../context/user/store'
import { Page } from '../enums/Page'
import { useWebSocket } from '../hooks/useWebSocket'
import { Clip } from '../types/Clip'
import { ClipsResponse } from '../types/ClipsResponse'
import { WordDto } from '../types/Transcription'
import React, { useEffect, useState } from 'react'
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'
import { generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const CreateReelPage = (): JSX.Element => {
    const [searchParams, setSearchParams] = useSearchParams({
        clip_ids: [],
    })
    const {
        state: { user },
    } = useUserStore()
    const [showSpinner, setShowSpinner] = useState<boolean>(false)
    const [textBackgroundColor, setTextBackgroundColor] = useState<string>('#808080')
    const [backgroundColor, setBackgroundColor] = useState<string>('#001f40')
    const [qrCodeUrl, setQrCodeUrl] = useState<string | undefined>()
    const [textColor, setTextColor] = useState<string>('#FFFFFF')
    const backgroundImageRef = React.useRef<any>(null)
    const [backgroundImage, setBackgroundImage] = useState<string | undefined>()
    const [backgroundImageUrl, setBackgroundImageUrl] = useState<string | undefined>()
    const backgroundVideoRef = React.useRef<any>(null)
    const [backgroundVideo, setBackgroundVideo] = useState<string | undefined>()
    const [backgroundVideoUrl, setBackgroundVideoUrl] = useState<string | undefined>()
    const [showVideoLoadError, setShowVideoLoadError] = useState<boolean>(false)
    const [clipsResponse, setClipsResponse] = useState<ClipsResponse | null>(null)
    const [showEditModalForClip, setShowEditModalForClip] = useState<Clip | null>(null)
    const [mergedAudioUrl, setMergedAudioUrl] = useState<string | null>(null)
    const [currentTimestamp, setCurrentTimestamp] = useState<number>(0)
    const [isBroadcastingModeEnabled, setIsBroadcastingModeEnabled] = useState<boolean>(false)
    const [videoUrl, setVideoUrl] = useState<string | null>(null)
    const audioPlayerRef = React.useRef<any>(null)
    const videoPlayerRef = React.useRef<any>(null)
    const { campaignId } = useParams<{ campaignId: string; topic: string }>()
    const navigate = useNavigate()
    const isAudioPlaying = audioPlayerRef.current?.audio.current?.paused === false
    const { setInterviewIds, setSurveyIds, setRagSourcePage } = useRagStore()
    const [draggingItemIndex, setDraggingItemIndex] = useState<number | null>(null)

    const selectedClipIds = ((searchParams.get('clip_ids') ?? '').split(',') as string[]).filter(
        (id) => id !== '',
    )

    const socket = useWebSocket()

    const fetchClips = async (page: number = 1) => {
        try {
            setSurveyIds([])
            setInterviewIds([])
            setRagSourcePage('CREATE_REEL_PAGE')
            if (campaignId) {
                setShowSpinner(true)
                const response = await ClipsApi.getSelectedClipsByCampaignId(
                    campaignId,
                    page,
                    150,
                    selectedClipIds ?? [],
                )

                if (page === 1) {
                    if (campaignId) {
                        setClipsResponse(response.data)
                    }
                } else {
                    setClipsResponse({
                        pagination: response.data.pagination,
                        clips: [...(clipsResponse?.clips || []), ...response.data.clips],
                    })
                }
            }
        } finally {
            setShowSpinner(false)
        }
    }

    useEffect(() => {
        const loadBackgroundVideo = async () => {
            const response = await fetch('/NY Liberty Video.mov')
            const videoBlob = await response.blob()
            const videoUrl = URL.createObjectURL(videoBlob)
            setBackgroundVideo(videoBlob as any)
            setBackgroundVideoUrl(videoUrl)
        }
        if (campaignId && campaignId === '5ff6f859-d324-415d-bad2-19edf35060d8') {
            void loadBackgroundVideo()
        }
    }, [])

    useEffect(() => {
        if (isAudioPlaying) {
            videoPlayerRef.current?.play()
        } else {
            videoPlayerRef.current?.pause()
        }
    }, [isAudioPlaying])

    const generateMergedAudio = async (): Promise<void> => {
        const clipIds = (
            (new URLSearchParams(window.location.search).get('clip_ids') ?? '').split(
                ',',
            ) as string[]
        ).filter((id) => id !== '')
        if (!clipIds || clipIds.length === 0) {
            return
        }
        try {
            setShowSpinner(true)
            const response = await ReelsApi.exportAudio(clipIds)
            setMergedAudioUrl(response.data?.url)
        } finally {
            setShowSpinner(false)
        }
    }

    useEffect(() => {
        if (socket) {
            socket.on('video_file_created', async (data: { url?: string }) => {
                // download the file
                setVideoUrl(data?.url ?? null)
                const url = data?.url
                if (url) {
                    // Fetch the file from the signed URL
                    const fileResponse = await fetch(url)
                    const blob = await fileResponse.blob()
                    // Create a download link and trigger it
                    const a = document.createElement('a')
                    a.href = window.URL.createObjectURL(blob)
                    a.download = 'video.mp4' // Optional: specify the file name
                    document.body.appendChild(a)
                    a.click()
                    document.body.removeChild(a)
                }
                setShowSpinner(false)
            })
        }

        return () => {
            if (socket) {
                socket.off('audio_file_updated')
            }
        }
    }, [socket])

    useEffect(() => {
        if (socket) {
            socket.on('clip_edited', async () => {
                toast.success('Clip edited successfully!')
                setShowSpinner(false)
                await fetchClips()
                await generateMergedAudio()
            })
        }

        return () => {
            if (socket) {
                socket.off('audio_file_updated')
            }
        }
    }, [socket])

    const selectedClips = (clipsResponse?.clips ?? [])
        .filter((clip: Clip) => selectedClipIds.includes(clip.id))
        .sort((a: Clip, b: Clip) => {
            const aIndex = selectedClipIds.indexOf(a.id)
            const bIndex = selectedClipIds.indexOf(b.id)
            return aIndex - bIndex
        })

    const areVideoClipsSelected = selectedClips.some((clip) => !!clip.video_url)
    const areAudioClipsSelected = selectedClips.some((clip) => !!clip.url)

    const onAudioPlayerSeeked = (event: Event) => {
        const timestampInMs = event.timeStamp
        const timestampInSeconds = timestampInMs / 1000
        if (videoPlayerRef?.current) {
            videoPlayerRef.current.currentTime = timestampInSeconds
        }
    }

    useEffect(() => {
        const audioElement = audioPlayerRef.current?.audio.current

        const logCurrentTime = () => {
            if (audioElement) {
                setCurrentTimestamp(audioElement.currentTime)
            }
        }

        if (audioElement) {
            audioElement.addEventListener('timeupdate', logCurrentTime)
        }

        return () => {
            if (audioElement) {
                audioElement.removeEventListener('timeupdate', logCurrentTime)
            }
        }
    }, [mergedAudioUrl])

    const mergeAndNormalizeWords = (arrays: WordDto[][]) => {
        let totalDuration = 0
        const normalizedWords = arrays.flatMap((array) => {
            const minStartTime = array.length > 0 ? array[0].start : 0
            const normalizedArray = array.map((word) => ({
                ...word,
                start: Number(word.start) - Number(minStartTime) + totalDuration,
                end: Number(word.end) - Number(minStartTime) + totalDuration,
            }))
            if (normalizedArray.length > 0) {
                totalDuration = normalizedArray[normalizedArray.length - 1].end
            }
            return normalizedArray
        })
        return normalizedWords
    }

    const mergedWords = mergeAndNormalizeWords(
        selectedClips.map((clip) => clip.transcription_json?.words ?? []),
    )

    useEffect(() => {
        void generateMergedAudio()
    }, [searchParams])

    useEffect(() => {
        void fetchClips()
    }, [])

    const downloadAudio = async (): Promise<void> => {
        try {
            setShowSpinner(true)
            if (campaignId && selectedClipIds.length > 0) {
                const response = await ReelsApi.exportAudio(selectedClipIds)
                const url = response.data?.url
                if (url) {
                    const fileResponse = await fetch(url)
                    const blob = await fileResponse.blob()
                    const a = document.createElement('a')
                    a.href = window.URL.createObjectURL(blob)
                    a.download = 'file.mp3'
                    document.body.appendChild(a)
                    a.click()
                    document.body.removeChild(a)
                }
            }
        } finally {
            setShowSpinner(false)
        }
    }

    const downloadVideo = async (): Promise<void> => {
        try {
            setShowSpinner(true)
            if (campaignId && selectedClipIds.length > 0) {
                if (!isBroadcastingModeEnabled) {
                    await ReelsApi.exportVideo({
                        clipIds: selectedClipIds,
                        textColor,
                        textBackgroundColor,
                        backgroundImage,
                        backgroundVideo,
                    })
                } else {
                    await ReelsApi.exportVideoBroadcastingMode({
                        clipIds: selectedClipIds,
                        textColor,
                        backgroundColor,
                        qrCodeUrl,
                    })
                }
            }
        } catch (error) {
            setShowSpinner(false)
        }
    }

    const handleDragStart = (e: React.DragEvent<HTMLDivElement>, index: number) => {
        setDraggingItemIndex(index)
        e.dataTransfer.effectAllowed = 'move'
        const img = new Image()
        img.src = ''
        e.dataTransfer.setDragImage(img, 0, 0)
    }

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.dataTransfer.dropEffect = 'move'
    }

    const handleDrop = (e: React.DragEvent<HTMLDivElement>, index: number) => {
        e.preventDefault()
        if (draggingItemIndex === null || draggingItemIndex === index) return

        const items = Array.from(clipsResponse?.clips ?? [])
        const [draggedItem] = items.splice(draggingItemIndex, 1)
        items.splice(index, 0, draggedItem)

        if (clipsResponse) {
            setClipsResponse({
                pagination: clipsResponse.pagination,
                clips: items,
            })
        }

        const reorderedSelectedClipIds = Array.from(selectedClipIds)
        const [draggedClipId] = reorderedSelectedClipIds.splice(draggingItemIndex, 1)
        reorderedSelectedClipIds.splice(index, 0, draggedClipId)
        const arrayString = reorderedSelectedClipIds.join(',')
        searchParams.set('clip_ids', arrayString)
        setSearchParams(searchParams)

        setDraggingItemIndex(null)
    }

    const handleDragEnd = () => {
        setDraggingItemIndex(null)
    }

    const handleChangeTextBackgroundColour = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextBackgroundColor(event.target.value)
    }

    const handleChangeBackgroundColour = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBackgroundColor(event.target.value)
    }

    const handleChangeTextColor = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextColor(event.target.value)
    }

    const handleEditClip = async (id: string, from: number, to: number) => {
        try {
            setShowSpinner(true)
            setShowEditModalForClip(null)
            await ClipsApi.editClip(id, from, to)
        } catch (error) {
            toast.error('Failed editing Clip! Please try again.')
        }
    }

    const getSubtitles = (words: any[], currentTime: number): WordDto[] => {
        const currentWordIndex = words.findIndex((word) => {
            if (!word) {
                return
            }
            return Number(word.start) <= currentTime && Number(word.end) >= currentTime
        })

        if (!words[currentWordIndex]) return []
        const currentWord = words[currentWordIndex]
        const nextWord = words[currentWordIndex + 1]
        const prevWord = words[currentWordIndex - 1]
        return [prevWord, currentWord, nextWord].filter((word) => word)
    }

    const getAuthorName = (currentTime: number): string => {
        let totalDuration = 0
        let currentClip = null
        for (const clip of selectedClips) {
            totalDuration = totalDuration + Number(clip.duration ?? 0)
            if (totalDuration >= currentTime) {
                currentClip = clip
                break
            }
        }

        if (currentClip?.source?.author === 'an anonymous respondent') {
            return ''
        }
        return currentClip?.source?.author ?? ''
    }

    const handleImageUpload = (e: any) => {
        if (e.target.files?.length) {
            setBackgroundVideo(undefined)
            setBackgroundVideoUrl(undefined)
            setBackgroundImage(e.target.files[0])
            setBackgroundImageUrl(URL.createObjectURL(e.target.files[0]))
            setShowVideoLoadError(false)
        }
    }

    const handleVideoUpload = (e: any) => {
        if (e.target.files?.length) {
            setBackgroundImage(undefined)
            setBackgroundImageUrl(undefined)
            setBackgroundVideo(e.target.files[0])
            setBackgroundVideoUrl(URL.createObjectURL(e.target.files[0]))
            setShowVideoLoadError(false)
        }
    }

    function sortClips(clips: Clip[], selectedClipIds: string[]) {
        const selectedOrder = new Map()
        selectedClipIds.forEach((id, index) => {
            selectedOrder.set(id, index)
        })

        clips.sort((a, b) => {
            const orderA = selectedOrder.has(a.id) ? selectedOrder.get(a.id) : Infinity
            const orderB = selectedOrder.has(b.id) ? selectedOrder.get(b.id) : Infinity
            return orderA - orderB
        })

        return clips
    }

    const sortedClips = sortClips(clipsResponse?.clips ?? [], selectedClipIds)

    const currentSubtitles =
        getSubtitles(mergedWords, currentTimestamp)
            .map((word) => word.word)
            .join(' ') || ''

    const isClipSelectable = (clip: Clip): boolean => {
        if (areVideoClipsSelected) {
            return !!clip.video_url
        } else if (areAudioClipsSelected) {
            return !!clip.url
        }
        return true
    }

    return (
        <div className="flex-1 px-[20px] sm:px-[40px] lg:pr-[120px] pb-10 bg-[#f7f7f7]">
            <div>{showSpinner && <Spinner />}</div>
            <div className="flex flex-col sm:flex-row gap-4 w-full">
                <div className="flex flex-row items-center justify-start">
                    <button
                        className="text-[#007AFF] font-lato font-bold text-base pr-3"
                        onClick={() => {
                            if (campaignId && user?.username) {
                                navigate(
                                    generatePath(Page.CAMPAIGN_LANDING_PAGE, {
                                        username: user?.username,
                                        id: campaignId,
                                    }),
                                )
                            } else {
                                navigate(Page.HOME)
                            }
                        }}
                    >
                        <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8.93934 13.9393C8.35355 14.5251 8.35355 15.4749 8.93934 16.0607L18.4853 25.6066C19.0711 26.1924 20.0208 26.1924 20.6066 25.6066C21.1924 25.0208 21.1924 24.0711 20.6066 23.4853L12.1213 15L20.6066 6.51472C21.1924 5.92893 21.1924 4.97919 20.6066 4.3934C20.0208 3.80761 19.0711 3.80761 18.4853 4.3934L8.93934 13.9393ZM12 13.5H10V16.5H12V13.5Z"
                                fill="#8092A5"
                            />
                        </svg>
                    </button>
                </div>
                <div className="flex flex-row gap-4 w-full justify-end items-center">
                    <button
                        className="font-lato text-white text-lg font-bold flex w-[140px] h-[50px] px-6 py-2 flex-row justify-center items-center gap-2 bg-[#3897ff] rounded-[40px] disabled:opacity-50"
                        onClick={downloadVideo}
                        disabled={selectedClipIds.length === 0}
                    >
                        <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M16.2581 0.00354774H5.74208H5.73284H5.72129C2.56382 0.0150965 0 2.58466 0 5.74198V16.258C0 19.4247 2.57532 22 5.74198 22H16.258C19.4247 22 22 19.4247 22 16.258V5.74198C22 2.57535 19.4247 0 16.258 0L16.2581 0.00354774ZM20.4536 5.00642H16.2729L13.813 1.48176H16.2579C18.3574 1.48176 20.1015 3.00734 20.4536 5.00642ZM9.61885 5.00642L7.16247 1.48176H12.0175L14.4774 5.00642H9.61885ZM5.37016 1.50246L7.81498 5.00642H1.54764C1.87908 3.12862 3.43913 1.67127 5.37016 1.50246ZM16.2581 20.5241H5.74208C3.39195 20.5241 1.47831 18.6117 1.47831 16.2604V6.48346H20.5207V16.2604C20.5207 18.6105 18.6082 20.5241 16.2569 20.5241H16.2581Z"
                                fill="white"
                            />
                            <path
                                d="M14.7597 11.7235L9.49695 8.68397C9.10085 8.453 8.62504 8.453 8.22891 8.68397C7.83278 8.91494 7.59375 9.32608 7.59375 9.78341V15.8615C7.59375 16.32 7.8305 16.7311 8.22891 16.961C8.42755 17.0765 8.64583 17.1319 8.86407 17.1319C9.08234 17.1319 9.29831 17.0753 9.49923 16.961L14.762 13.9215C15.1581 13.6905 15.3948 13.2794 15.3948 12.822C15.3948 12.3635 15.1581 11.9524 14.762 11.7226L14.7597 11.7235ZM9.07211 15.4988V10.145L13.7078 12.8208L9.07211 15.4967V15.4988Z"
                                fill="white"
                            />
                        </svg>
                        <span>Video</span>
                    </button>
                    <button
                        className="font-lato text-white text-lg font-bold flex w-[140px] h-[50px] px-6 py-2 flex-row justify-center items-center gap-2 bg-[#3897ff] rounded-[40px] disabled:opacity-50"
                        onClick={downloadAudio}
                        disabled={selectedClipIds.length === 0}
                    >
                        <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g filter="url(#filter0_d_256_1772)">
                                <path
                                    d="M8 13.5662C8 13.2149 8.1843 12.8894 8.4855 12.7087L11.4855 10.9087C12.152 10.5088 13 10.9889 13 11.7662V17.2338C13 18.0111 12.152 18.4912 11.4855 18.0913L8.4855 16.2913C8.1843 16.1106 8 15.7851 8 15.4338V13.5662Z"
                                    fill="white"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M14.724 10.659C14.9005 10.3156 15.322 10.1803 15.6654 10.3568C16.994 11.0395 17.9096 12.4153 17.9096 14.0085C17.9096 15.6017 16.994 16.9775 15.6654 17.6603C15.322 17.8368 14.9005 17.7015 14.724 17.358C14.5474 17.0146 14.6828 16.593 15.0262 16.4165C15.9137 15.9604 16.5112 15.05 16.5112 14.0085C16.5112 12.9671 15.9137 12.0566 15.0262 11.6005C14.6828 11.424 14.5474 11.0025 14.724 10.659Z"
                                    fill="white"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M16.5892 7.86216C16.7657 7.51871 17.1872 7.38338 17.5307 7.55989C19.9672 8.81212 21.6394 11.331 21.6394 14.2415C21.6394 17.1521 19.9672 19.6709 17.5307 20.9232C17.1872 21.0997 16.7657 20.9643 16.5892 20.6209C16.4127 20.2774 16.548 19.8559 16.8915 19.6794C18.887 18.6538 20.2409 16.6003 20.2409 14.2415C20.2409 11.8827 18.887 9.82922 16.8915 8.80365C16.548 8.62714 16.4127 8.20562 16.5892 7.86216Z"
                                    fill="white"
                                />
                                <rect
                                    x="4.75"
                                    y="3.75"
                                    width="20.5"
                                    height="20.5"
                                    rx="4.25"
                                    stroke="white"
                                    strokeWidth="1.5"
                                />
                            </g>
                            <defs>
                                <filter
                                    id="filter0_d_256_1772"
                                    x="0"
                                    y="0"
                                    width="30"
                                    height="30"
                                    filterUnits="userSpaceOnUse"
                                    colorInterpolationFilters="sRGB"
                                >
                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix
                                        in="SourceAlpha"
                                        type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        result="hardAlpha"
                                    />
                                    <feOffset dy="1" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix
                                        type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                                    />
                                    <feBlend
                                        mode="normal"
                                        in2="BackgroundImageFix"
                                        result="effect1_dropShadow_256_1772"
                                    />
                                    <feBlend
                                        mode="normal"
                                        in="SourceGraphic"
                                        in2="effect1_dropShadow_256_1772"
                                        result="shape"
                                    />
                                </filter>
                            </defs>
                        </svg>
                        <span>Audio</span>
                    </button>
                </div>
            </div>
            <div className="h-screen flex flex-col md:flex-row justify-between">
                <div
                    className="w-full md:w-3/5 xl:w-[70%] pt-5 flex flex-col gap-0.5"
                    id="scrollableDiv"
                >
                    {sortedClips?.map((clip, index) => (
                        <div
                            key={clip.id}
                            draggable
                            onDragStart={(e) => handleDragStart(e, index)}
                            onDragOver={(e) => handleDragOver(e)}
                            onDrop={(e) => handleDrop(e, index)}
                            onDragEnd={handleDragEnd}
                            className={`${
                                index === draggingItemIndex ? 'opacity-50 border border-dashed' : ''
                            }`}
                        >
                            <CreateReelPageSingleClipItem
                                clip={clip}
                                isSelectable={isClipSelectable(clip)}
                                isSelected={selectedClipIds.includes(clip.id)}
                                onToggleSelect={() => {
                                    const isSelected = selectedClipIds.includes(clip.id)
                                    let updatedClipIds: string[] = []
                                    if (isSelected) {
                                        updatedClipIds = selectedClipIds.filter(
                                            (id) => id !== clip.id,
                                        )
                                    } else {
                                        updatedClipIds = [...selectedClipIds, clip.id]
                                    }
                                    const arrayString = updatedClipIds.join(',')
                                    searchParams.set('clip_ids', arrayString)
                                    setSearchParams(searchParams)
                                }}
                                index={index + 1}
                                onEdit={() => {
                                    if (isAudioPlaying) {
                                        audioPlayerRef.current.audio.current.pause()
                                    }
                                    setShowEditModalForClip(clip)
                                }}
                            />
                        </div>
                    ))}
                </div>
                <div className="flex flex-col items-end w-full md:w-2/5 xl:w-[30%] h-full">
                    <div className="w-full pt-10 flex flex-col 2xl:flex-row justify-end items-end 2xl:items-start gap-2 md:gap-4 mt-1.5">
                        <div className="w-full 2xl:w-auto flex flex-row 2xl:flex-col">
                            <input
                                title="Select Text Background Color"
                                type="color"
                                value={textBackgroundColor}
                                onChange={handleChangeTextBackgroundColour}
                                className="rounded-full w-[30px] h-[30px] mb-4 ml-2.5 cursor-pointer"
                            />
                            <input
                                title="Select Text Color"
                                type="color"
                                value={textColor}
                                onChange={handleChangeTextColor}
                                className="rounded-full w-[30px] h-[30px] ml-2.5 mb-2.5 cursor-pointer"
                            />

                            <div
                                onClick={() => backgroundImageRef.current?.click()}
                                className="mt-[-6px] 2xl:mt-0 cursor-pointer"
                            >
                                <svg
                                    width="52"
                                    height="52"
                                    viewBox="0 0 52 52"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <mask
                                        id="mask0_450_1209"
                                        style={{
                                            maskType: 'alpha',
                                        }}
                                        maskUnits="userSpaceOnUse"
                                        x="11"
                                        y="6"
                                        width="30"
                                        height="30"
                                    >
                                        <circle cx="26" cy="21" r="15" fill="#D9D9D9" />
                                        <circle
                                            cx="26"
                                            cy="21"
                                            r="14.5"
                                            stroke="black"
                                            strokeOpacity="0.1"
                                        />
                                    </mask>
                                    <g mask="url(#mask0_450_1209)">
                                        <rect
                                            x="-104"
                                            y="-43"
                                            width="260"
                                            height="128"
                                            fill="url(#paint0_linear_450_1209)"
                                        />
                                    </g>
                                    <rect
                                        x="18.5"
                                        y="14.5"
                                        width="14"
                                        height="14"
                                        rx="1.5"
                                        stroke="white"
                                    />
                                    <g clipPath="url(#clip0_450_1209)">
                                        <rect
                                            x="23"
                                            y="18.7071"
                                            width="10.3139"
                                            height="10.3139"
                                            rx="1.5"
                                            transform="rotate(45 23 18.7071)"
                                            stroke="white"
                                        />
                                    </g>
                                    <circle cx="28.5" cy="18.5" r="2" stroke="white" />
                                    <defs>
                                        <linearGradient
                                            id="paint0_linear_450_1209"
                                            x1="1.16018"
                                            y1="21"
                                            x2="56.0458"
                                            y2="21"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#082842" />
                                            <stop offset="0.453466" stopColor="#1566A8" />
                                            <stop offset="0.964543" stopColor="#082842" />
                                        </linearGradient>
                                        <clipPath id="clip0_450_1209">
                                            <rect
                                                x="18"
                                                y="18"
                                                width="13"
                                                height="11"
                                                rx="5.5"
                                                fill="white"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <input
                                    accept="image/*"
                                    type="file"
                                    className="hidden"
                                    ref={backgroundImageRef}
                                    onChange={handleImageUpload}
                                />
                            </div>

                            <div
                                onClick={() => backgroundVideoRef.current?.click()}
                                className="flex items-center justify-center rounded-full w-[30px] h-[30px] ml-2.5 mb-2.5 bg-blue-200 cursor-pointer"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 22 22"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z"
                                    />
                                </svg>
                                <input
                                    accept="video/*"
                                    type="file"
                                    className="hidden"
                                    ref={backgroundVideoRef}
                                    onChange={handleVideoUpload}
                                />
                            </div>

                            <input
                                accept="video/*"
                                type="file"
                                className="hidden"
                                ref={backgroundVideoRef}
                                onChange={handleVideoUpload}
                            />
                        </div>
                        {backgroundVideoUrl && (
                            <div
                                className={`w-full xl:w-[312px] h-[400px] sm:h-[554px] flex justify-center items-end relative overflow-hidden`}
                                style={{
                                    color: textColor,
                                    backgroundColor: textBackgroundColor,
                                    padding: '10px',
                                }}
                            >
                                <video
                                    key={backgroundVideoUrl} // Added key property here
                                    ref={videoPlayerRef}
                                    controls={false}
                                    loop={true}
                                    muted={true}
                                    className="w-full h-full object-cover absolute top-0 left-0"
                                    onError={() => {
                                        setShowVideoLoadError(true)
                                    }}
                                >
                                    <source src={backgroundVideoUrl} />
                                </video>
                                <div className="flex flex-col">
                                    <h3
                                        className="text-center text-3xl font-bold font-lato mb-2 px-4 z-10"
                                        style={{
                                            backgroundColor: currentSubtitles
                                                ? textBackgroundColor
                                                : '',
                                            opacity: currentSubtitles ? 0.8 : 1,
                                            borderStyle: currentSubtitles ? 'solid' : 'none',
                                            borderColor: currentSubtitles
                                                ? textBackgroundColor
                                                : 'transparent',
                                            borderWidth: currentSubtitles ? '10px' : '0',
                                        }}
                                    >
                                        {currentSubtitles}
                                    </h3>
                                    <h3 className="text-center text-sm font-bold font-lato mb-[70px] px-20 z-10">
                                        {getAuthorName(currentTimestamp)}
                                    </h3>
                                </div>
                            </div>
                        )}
                        {!backgroundVideoUrl && (
                            <div
                                className="w-full xl:w-[312px] h-[400px] sm:h-[554px] flex justify-center items-end relative"
                                style={{
                                    backgroundColor: '#000000',
                                    color: textColor,
                                    backgroundImage: `url(${backgroundImageUrl})`,
                                }}
                            >
                                <p className="absolute top-10">Preview is not available</p>
                                <div className="flex flex-col">
                                    <h3
                                        className="text-center text-3xl font-bold font-lato mb-2 mx-4 z-10"
                                        style={{
                                            backgroundColor: currentSubtitles
                                                ? textBackgroundColor
                                                : '',
                                            opacity: currentSubtitles ? 0.8 : 1,
                                            borderStyle: currentSubtitles ? 'solid' : 'none',
                                            borderColor: currentSubtitles
                                                ? textBackgroundColor
                                                : 'transparent',
                                            borderWidth: currentSubtitles ? '10px' : '0',
                                        }}
                                    >
                                        {currentSubtitles}
                                    </h3>
                                    <h3 className="text-center text-sm font-bold font-lato mb-[70px] px-20 z-10">
                                        {getAuthorName(currentTimestamp)}
                                    </h3>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="w-full md:max-w-[312px] bg-[#f7f7f7] mt-2 mb-10 sm:mb-0">
                        {showVideoLoadError && (
                            <p className="text-red-500 text-xs text-center">
                                Unable to load this video file type. Try exporting the video as this
                                may only be a preview issue. If the problem persists, please use a
                                .mp4 file type.
                            </p>
                        )}
                    </div>
                    {mergedAudioUrl && (
                        <AudioPlayer
                            ref={audioPlayerRef}
                            src={mergedAudioUrl}
                            onSeeked={onAudioPlayerSeeked}
                            customProgressBarSection={[
                                RHAP_UI.MAIN_CONTROLS,
                                RHAP_UI.PROGRESS_BAR,
                                RHAP_UI.CURRENT_TIME,
                                <div>/</div>,
                                RHAP_UI.DURATION,
                            ]}
                            showJumpControls={false}
                            customVolumeControls={[]}
                            customAdditionalControls={[]}
                            customControlsSection={[]}
                            timeFormat="mm:ss"
                            className="w-full md:max-w-[312px] bg-[#f7f7f7] mt-2 mb-10 sm:mb-0"
                            loop={false}
                            autoPlay={false}
                            autoPlayAfterSrcChange={false}
                        />
                    )}
                    <div className="w-full md:max-w-[312px] mt-20">
                        <ToggleSwitch
                            checked={isBroadcastingModeEnabled}
                            label="Enable Broadcasting Mode"
                            onChange={() =>
                                setIsBroadcastingModeEnabled(!isBroadcastingModeEnabled)
                            }
                        />
                        {isBroadcastingModeEnabled && (
                            <div className="mt-4">
                                <div className="flex flex-row gap-4 items-center">
                                    <input
                                        title="Select Background Color"
                                        type="color"
                                        value={backgroundColor}
                                        onChange={handleChangeBackgroundColour}
                                        className="rounded-full w-[30px] h-[30px] cursor-pointer"
                                    />
                                    <p>Background Color</p>
                                </div>
                                <div>
                                    <p className="text-xs text-gray-500 mt-2">QR code URL:</p>
                                    <input
                                        className="mt-2 w-full"
                                        value={qrCodeUrl}
                                        onChange={(e) => setQrCodeUrl(e.target.value)}
                                    />
                                </div>
                                {videoUrl && (
                                    <div>
                                        <p>{videoUrl}</p>
                                        <button
                                            className="p-1 w-[32px] flex justify-end"
                                            title="Copy Insights"
                                            onClick={() => navigator.clipboard.writeText(videoUrl)}
                                            disabled={!videoUrl}
                                        >
                                            <img src="/asset/copy.svg" alt="copy" />
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {showEditModalForClip && (
                <TrimClipModal
                    clip={showEditModalForClip}
                    onClose={() => setShowEditModalForClip(null)}
                    onSave={(start, end) => {
                        handleEditClip(showEditModalForClip.id, start, end)
                    }}
                />
            )}
        </div>
    )
}

export default CreateReelPage
