import RouterWrapper from './components/RouterWrapper'
import { QuestionStoreProvider } from './context/question/store'
import { RagStoreProvider } from './context/rag/store'
import { UserStoreProvider } from './context/user/store'
import React from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

interface StoreProviderProps {
    providers: Array<React.JSXElementConstructor<React.PropsWithChildren<any>>>
    children: React.ReactNode
}

const StoreProvider = ({ providers, children }: StoreProviderProps): JSX.Element => {
    return (
        <div>
            <React.Fragment>
                {providers.reduceRight((acc, Comp) => {
                    return <Comp>{acc}</Comp>
                }, children)}
            </React.Fragment>
        </div>
    )
}

function App() {
    return (
        <div>
            <StoreProvider providers={[UserStoreProvider, QuestionStoreProvider, RagStoreProvider]}>
                <RouterWrapper />
            </StoreProvider>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                className="w-[calc(100vw_-_15px)] sm:w-[calc(100vw_-_70px)] lg:w-[calc(100vw_-_230px)] pl-[5px] lg:pl-[75px] top-[2em] mt-1"
                bodyClassName="m-0"
                toastClassName="min-h-[52px]"
                closeButton={false}
            />
        </div>
    )
}

export default App
