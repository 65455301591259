import './css/LoadingSpinner.css'
import React from 'react'

const LoadingSpinner = (): JSX.Element => {
    return (
        <div className="spinner-container">
            <div className="loading-spinner"></div>
        </div>
    )
}

export default LoadingSpinner
