import SelectLanguage from '../components/SelectLanguage'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const Privacy = (): JSX.Element => {
    useEffect(() => {
        document.body.style.overflowY = 'scroll'
        return () => {
            document.body.style.overflowY = 'hidden'
        }
    })

    const { t } = useTranslation()

    return (
        <div className="container max-w-4xl overflow-scroll mx-auto mb-[100px] px-4 pt-[100px] font-lato">
            <SelectLanguage
                style={{
                    marginBottom: 16,
                    background: 'white',
                }}
            />
            <h1 className="text-base font-semibold text-black bg-transparent font-calibri">
                {t('terms_page.title')}
            </h1>
            <p className="leading-5 text-justify mt-[12pt] mb-[12pt] text-sm text-black bg-transparent font-normal">
                {t('terms_page.subtitle')}
            </p>
            <p className="leading-5 text-justify mt-[12pt] mb-[12pt] text-sm text-black bg-transparent font-normal">
                {t('terms_page.last_updated')}
            </p>
            <p className="text-justify mt-[12pt] mb-[12pt] text-base text-black bg-transparent font-normal">
                {t('terms_page.first_section')}
                <a href="mailto:hello@nodeio.xyz"> hello@nodeio.xyz</a>
            </p>
            <h2 className="text-justify mt-[12pt] mb-[12pt] text-base text-black bg-transparent font-normal">
                1. {t('terms_page.general')}
            </h2>
            <p className="text-justify mt-[12pt] mb-[12pt] text-base text-black bg-transparent font-normal">
                {t('terms_page.general_info')}
                <a href="mailto:hello@nodeio.xyz"> hello@nodeio.xyz</a>
            </p>
            <h2 className="text-justify mt-[12pt] mb-[12pt] text-base text-black bg-transparent font-normal">
                2. {t('terms_page.purpose')}
            </h2>
            <p className="text-justify mt-[12pt] mb-[12pt] text-base text-black bg-transparent font-normal">
                {t('terms_page.purpose_info')}
            </p>
            <p className="text-justify mt-[12pt] mb-[12pt] text-base text-black bg-transparent font-normal">
                {t('terms_page.purpose_info_2')}
            </p>
            <h2 className="text-justify mt-[12pt] mb-[12pt] text-base text-black bg-transparent font-normal">
                3. {t('terms_page.information_available_on_the_platform')}
            </h2>
            <p className="text-justify mt-[12pt] mb-[12pt] text-base text-black bg-transparent font-normal">
                {t('terms_page.information_available_on_the_platform_info')}
            </p>
            <h2 className="text-justify mt-[12pt] mb-[12pt] text-base text-black bg-transparent font-normal">
                4. {t('terms_page.you_use_of_the_platform')}
            </h2>
            <p className="text-justify mt-[12pt] mb-[12pt] text-base text-black bg-transparent font-normal">
                {t('terms_page.you_use_of_the_platform_info')}
            </p>
            <p className="text-justify mt-[12pt] mb-[12pt] text-base text-black bg-transparent font-normal">
                5. {t('terms_page.indemnity')}
            </p>
            <p className="text-justify mt-[12pt] mb-[12pt] text-base text-black bg-transparent font-normal">
                {t('terms_page.indemnity_info')}
            </p>
            <p className="text-justify mt-[12pt] mb-[12pt] text-base text-black bg-transparent font-normal">
                6. {t('terms_page.limitation_of_liability')}
            </p>
            <p className="text-justify mt-[12pt] mb-[12pt] text-base text-black bg-transparent font-normal">
                {t('terms_page.limitation_of_liability_info')}
            </p>
            <p className="text-justify mt-[12pt] mb-[12pt] text-base text-black bg-transparent font-normal">
                - {t('terms_page.limitation_of_liability_item_1')}
            </p>
            <p className="text-justify mt-[12pt] mb-[12pt] text-base text-black bg-transparent font-normal">
                - {t('terms_page.limitation_of_liability_item_2')}
            </p>
            <p className="text-justify mt-[12pt] mb-[12pt] text-base text-black bg-transparent font-normal">
                - {t('terms_page.limitation_of_liability_item_3')}
            </p>
            <p className="text-justify mt-[12pt] mb-[12pt] text-base text-black bg-transparent font-normal">
                - {t('terms_page.limitation_of_liability_item_4')}
            </p>
            <p className="text-justify mt-[12pt] mb-[12pt] text-base text-black bg-transparent font-normal">
                - {t('terms_page.limitation_of_liability_item_5')}
            </p>
            <p className="text-justify mt-[12pt] mb-[12pt] text-base text-black bg-transparent font-normal">
                - {t('terms_page.limitation_of_liability_item_6')}
            </p>
            <p className="text-justify mt-[12pt] mb-[12pt] text-base text-black bg-transparent font-normal">
                - {t('terms_page.limitation_of_liability_item_7')}
            </p>
            <p className="text-justify mt-[12pt] mb-[12pt] text-base text-black bg-transparent font-normal">
                7. {t('terms_page.intellectual_property')}
            </p>
            <p className="text-justify mt-[12pt] mb-[12pt] text-base text-black bg-transparent font-normal">
                {t('terms_page.intellectual_property_info')}
            </p>
            <p className="text-justify mt-[12pt] mb-[12pt] text-base text-black bg-transparent font-normal">
                8. {t('terms_page.privacy_and_cookies_policy')}
            </p>
            <p className="text-justify mt-[12pt] mb-[12pt] text-base text-black bg-transparent font-normal">
                {t('terms_page.privacy_and_cookies_policy_info')}
            </p>
            <p className="text-justify mt-[12pt] mb-[12pt] text-base text-black bg-transparent font-normal">
                9. {t('terms_page.links_to_third_party_websites')}
            </p>
            <p className="text-justify mt-[12pt] mb-[12pt] text-base text-black bg-transparent font-normal">
                {t('terms_page.links_to_third_party_websites_info')}
            </p>
            <p className="text-justify mt-[12pt] mb-[12pt] text-base text-black bg-transparent font-normal">
                10. {t('terms_page.amendments_to_the_platform')}
            </p>
            <p className="text-justify mt-[12pt] mb-[12pt] text-base text-black bg-transparent font-normal">
                {t('terms_page.amendments_to_the_platform_info')}
            </p>
            <p className="text-justify mt-[12pt] mb-[12pt] text-base text-black bg-transparent font-normal">
                11. {t('terms_page.governing_law')}
            </p>
            <p className="text-justify mt-[12pt] mb-[12pt] text-base text-black bg-transparent font-normal">
                {t('terms_page.governing_law_info')}
            </p>
        </div>
    )
}
export default Privacy
