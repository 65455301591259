import { NodeioApi } from '../api/nodeio'
import LeaveConfirmModal from '../components/MVPCard/Modals/LeaveConfirmModal'
import RevertConfirmModal from '../components/MVPCard/Modals/RevertConfirmModal'
import Spinner from '../components/Spinner'
import { useRagStore } from '../context/rag/store'
import { useUserStore } from '../context/user/store'
import { Page } from '../enums/Page'
import { useWebSocket } from '../hooks/useWebSocket'
import { Nodeio } from '../types/Nodeio'
import { toMMHH } from '../utils/toMMHH'
import { useCallback, useEffect, useRef, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import Regions from 'wavesurfer.js/dist/plugins/regions'
import Timeline from 'wavesurfer.js/dist/plugins/timeline'
import WaveSurfer from 'wavesurfer.js/dist/wavesurfer'

const EditAudio = (): JSX.Element => {
    const { id } = useParams<{ id: string }>()
    const {
        state: { loading },
        setLoading,
    } = useUserStore()

    const socket = useWebSocket()

    const [openRevertModal, setOpenRevertModal] = useState<boolean>(false)
    const toggleRevertModal = () => setOpenRevertModal(!openRevertModal)

    const [openLeaveModal, setOpenLeaveModal] = useState<boolean>(false)
    const toggleLeaveModal = () => setOpenLeaveModal(!openLeaveModal)

    const { setInterviewIds, setSurveyIds, setRagSourcePage } = useRagStore()

    useEffect(() => {
        document.body.style.overflowY = 'auto'

        return () => {
            document.body.style.overflowY = 'hidden'
        }
    }, [])

    const containerRef = useRef<HTMLDivElement>(null)
    const [isPlaying, setIsPlaying] = useState(false)
    const [currentTime, setCurrentTime] = useState(0)
    const [wavesurfer, setWavesurfer] = useState<any>(null)
    const [trimmed, setTrimmed] = useState<any>({ start: 0, end: 0 })
    const [time, setTime] = useState<number>(0)
    const [nodeio, setNodeio] = useState<Nodeio | null>(null)
    const [regions, setRegions] = useState<any[]>([])

    const navigate = useNavigate()

    const fetchNodeio = async () => {
        setLoading(true)
        const response = await NodeioApi.getById(id as string)
        setNodeio(response.data)
    }

    const handleOnSaveClicked = async () => {
        const intervals = regions
            .sort((a, b) => a.start - b.start)
            .map((region) => {
                return {
                    start_time: region.start,
                    end_time: region.end,
                }
            })
        try {
            if (nodeio?.media?.id && socket) {
                setLoading(true)
                socket.emit('edit_audio_file', {
                    media_id: nodeio.media.id,
                    time_intervals: intervals,
                })
                if (nodeio.responding_to_question) {
                    navigate(
                        generatePath(Page.QUESTION_LANDING_PAGE_MORE, {
                            username: nodeio?.responding_to_question?.author?.username,
                            slug: nodeio.responding_to_question?.slug,
                            id: nodeio.responding_to_question.id,
                        }),
                        {
                            state: { updated_nodeio_id: nodeio.id },
                        },
                    )
                } else {
                    navigate(Page.HOME)
                }
            }
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        setSurveyIds([])
        setInterviewIds([])
        setRagSourcePage('EDIT_AUDIO_PAGE')
        fetchNodeio()
    }, [])

    useEffect(() => {
        if (!containerRef.current) return

        const ws = WaveSurfer.create({
            height: 200,
            waveColor: '#007AFF',
            progressColor: 'rgb(100, 0, 100)',
            barWidth: 2,
            barGap: 2,
            barRadius: 2,
            barHeight: 1.2,
            cursorWidth: 2,
            url: nodeio?.media?.url,
            container: containerRef.current,
            backend: 'WebAudio',
        })

        ws.registerPlugin(Timeline.create())
        const wsRegions = ws.registerPlugin(Regions.create())

        ws.on('ready', () => {
            nodeio?.media?.trimmed_file?.intervals?.forEach((interval) => {
                wsRegions.addRegion({
                    start: interval.start_time,
                    end: interval?.end_time,
                    color: 'rgba(0, 0, 0, 0.1)',
                    channelIdx: -1,
                })
            })
        })

        ws.on('decode', () => {
            wsRegions.enableDragSelection({})
            setTrimmed((trimmed: any) => ({ ...trimmed, end: ws.getDuration() }))
        })

        wsRegions.on('region-clicked', (region, e) => {
            e.stopPropagation()
            region.play()
        })

        wsRegions.on('region-created', (updatedRegion) => {
            let regions = wsRegions.getRegions()
            regions.forEach((region: any) => {
                if (region.id !== updatedRegion.id) {
                    if (updatedRegion.start < region.end && updatedRegion.end > region.start) {
                        updatedRegion.setOptions({
                            start: Math.min(region.start, updatedRegion.start),
                            end: Math.max(region.end, updatedRegion.end),
                        })
                        region.remove()
                    }
                }
                setRegions(wsRegions.getRegions())
            })
        })
        wsRegions.on('region-updated', (updatedRegion) => {
            let regions = wsRegions.getRegions()
            regions.forEach((region: any) => {
                if (region.id !== updatedRegion.id) {
                    if (updatedRegion.start < region.end && updatedRegion.end > region.start) {
                        updatedRegion.setOptions({
                            start: Math.min(region.start, updatedRegion.start),
                            end: Math.max(region.end, updatedRegion.end),
                        })
                        region.remove()
                    }
                }
                setRegions(wsRegions.getRegions())
            })
        })

        wsRegions.on('region-updated', (region) => {
            setTrimmed({ start: region.start, end: region.end })
        })

        setWavesurfer(ws)

        return () => {
            ws.destroy()
        }
    }, [containerRef, nodeio])

    useEffect(() => {
        if (!wavesurfer) return

        setCurrentTime(0)
        setIsPlaying(false)

        const subscriptions = [
            wavesurfer.on('play', () => setIsPlaying(true)),
            wavesurfer.on('pause', () => setIsPlaying(false)),
            wavesurfer.on('timeupdate', (currentTime: any) => setCurrentTime(currentTime)),
            wavesurfer.on('ready', () => {
                setLoading(false)
            }),
        ]

        return () => {
            subscriptions.forEach((unsub) => unsub())
        }
    }, [wavesurfer])

    const onPlayClick = useCallback(() => {
        wavesurfer.setTime(time)
        wavesurfer.isPlaying() ? wavesurfer.pause() : wavesurfer.play()
    }, [wavesurfer, time])

    useEffect(() => {
        setTime(currentTime)
    }, [currentTime])

    const updateTime = (e: any) => {
        setTime(e.target.value)
    }

    return (
        <div className="flex flex-col">
            <div className="flex justify-center bg-[#1F2C3B] py-10">
                <p className="text-center text-white text-3xl font-semibold">Edit Audio</p>
            </div>
            <div className="flex-1 mx-3 xs:mx-6 lg:mx-12 xl:mx-32 pt-20 pb-8">
                <div className="flex flex-col sm:flex-row justify-between sm:items-center px-2 gap-5">
                    <div className="flex flex-col xs:flex-row xs:items-center gap-3 text-base text-[#0C1B2C]">
                        <p>Audio Length: {toMMHH(wavesurfer?.getDuration() || 0)}</p>
                        <div className="w-[210px] xs:w-[1px] h-[1px] xs:h-[32px] bg-[#0C1B2C]" />
                        <p className="font-semibold">
                            Trimmed Audio Length: {toMMHH(trimmed.end - trimmed.start || 0)}
                        </p>
                    </div>
                    <button
                        className="text-[#007AFF] text-sm flex items-center gap-1 font-semibold self-end"
                        onClick={toggleRevertModal}
                    >
                        <img src="/asset/revert.svg" className="w-[22px] h-[22px]" />
                        <span>Revert original</span>
                    </button>
                </div>

                <div
                    ref={containerRef}
                    className="min-h-[200px] mb-12 mt-6 sm:mt-10"
                    id="waveform"
                ></div>

                <div className="flex flex-col xs:flex-row xs:items-center justify-between gap-5">
                    <div className="flex items-center gap-3">
                        <button
                            className={`rounded-full w-[35px] h-[35px] flex items-center justify-center ${
                                isPlaying ? 'bg-[#D92D20]' : 'bg-[#1570EF]'
                            }`}
                            onClick={onPlayClick}
                        >
                            <img
                                className={`mt-0.5 ${isPlaying ? '' : 'ml-0.5'}`}
                                src={
                                    isPlaying ? '/asset/player_end.svg' : '/asset/player_start.svg'
                                }
                            />
                        </button>
                        <input
                            onChange={updateTime}
                            onKeyDown={(e) => e.code === 'Enter' && onPlayClick()}
                            type="number"
                            value={time}
                            className="py-2 px-3 max-w-[135px] text-[#667085] border-[#D0D5DD] border rounded-lg shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] outline-none"
                        />
                    </div>
                    <div className="flex items-center gap-3 self-end">
                        <button
                            className="text-[#007AFF] text-sm font-semibold flex items-center p-1.5 px-4 rounded-3xl border-[#007AFF] border-2"
                            onClick={toggleLeaveModal}
                        >
                            Cancel
                        </button>
                        <button
                            className="text-white text-sm flex items-center p-1.5 px-4 rounded-2xl border-2 border-[#007AFF] bg-[#007AFF]"
                            disabled={loading}
                            onClick={handleOnSaveClicked}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>

            <RevertConfirmModal
                open={openRevertModal}
                onToggle={toggleRevertModal}
                onRevert={async () => {
                    if (nodeio?.media && socket) {
                        try {
                            setLoading(true)
                            socket.emit('edit_audio_file', {
                                media_id: nodeio.media.id,
                                time_intervals: [
                                    {
                                        start_time: 0,
                                        end_time: wavesurfer?.getDuration(),
                                    },
                                ],
                                revert_original: true,
                            })
                            setOpenRevertModal(false)
                        } finally {
                            setLoading(false)
                            if (nodeio.responding_to_question) {
                                navigate(
                                    generatePath(Page.QUESTION_LANDING_PAGE_MORE, {
                                        username: nodeio?.responding_to_question?.author?.username,
                                        slug: nodeio.responding_to_question?.slug,
                                        id: nodeio.responding_to_question.id,
                                    }),
                                    {
                                        state: { updated_nodeio_id: nodeio.id },
                                    },
                                )
                            } else {
                                navigate(Page.HOME)
                            }
                        }
                    }
                }}
            />

            <LeaveConfirmModal
                open={openLeaveModal}
                onKeepEditing={() => setOpenLeaveModal(false)}
            />
            {loading && <Spinner />}
        </div>
    )
}
export default EditAudio
