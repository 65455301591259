/* eslint-disable indent */
import { useQuestionStore } from '../context/question/store'
import { Response, ResponseNote } from '../pages/QuestionResponder'
import { Question } from '../types/Question'
import ToggleSwitch from './ToggleSwitch'
import { Tooltip } from 'flowbite-react'
import { KeyboardEvent } from 'react'
import { useTranslation } from 'react-i18next'
import TextareaAutosize from 'react-textarea-autosize'

interface MultipleSelectResponderProps {
    setResponses?: (value: Response[] | ((prevVar: Response[]) => Response[])) => void
    setQuestions?: (value: Question[]) => void
    setRequiredToAllResponds?: (v: boolean | ((prevVar: boolean) => boolean)) => void
    setQuestionIndex?: (index: number | ((prevVar: number) => number)) => void
    setIsRecordingSupported?: (v: boolean | ((prevVar: boolean) => boolean)) => void
    setResponseNotes?: (
        responseNotes: ResponseNote[] | ((prevVar: ResponseNote[]) => ResponseNote[]),
    ) => void
    selectedQuestion?: Question | null
    questionIndex?: number
    responseNoteForSelectedQuestion?: ResponseNote | undefined
    questions?: Question[] | Partial<Question>[]
    disabled?: boolean
    isQuestionResponder?: boolean
    isDarkModeEnabled?: boolean
}

const MultipleSelectResponder = ({
    setQuestions,
    setResponseNotes,
    selectedQuestion,
    questionIndex,
    responseNoteForSelectedQuestion,
    questions,
    disabled,
    isQuestionResponder,
    isDarkModeEnabled = false,
}: MultipleSelectResponderProps) => {
    const { i18n, t } = useTranslation()
    const {
        state: { accepted },
        setOpenFooter,
        setQuestionResponderLanded,
    } = useQuestionStore()

    const onQuestionAdded = () => {
        if (setQuestions) {
            setQuestions(
                (questions as Question[]).map((question: Question, qIndex: number) =>
                    qIndex !== questionIndex
                        ? question
                        : {
                              ...question,
                              question_settings: {
                                  ...question.question_settings,
                                  options: [
                                      ...(question.question_settings.options as string[]),
                                      '',
                                  ],
                              },
                          },
                ),
            )
        }
    }

    const onShowOtherChange = () => {
        if (setQuestions) {
            setQuestions(
                (questions as Question[]).map((question: Question, qIndex: number) =>
                    qIndex !== questionIndex
                        ? question
                        : {
                              ...question,
                              question_settings: {
                                  ...question.question_settings,
                                  show_other: !question.question_settings.show_other,
                              },
                          },
                ),
            )
        }
    }

    const handleKeyPress = (event: KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter') {
            onQuestionAdded()
            event.stopPropagation()
            event.preventDefault()
        }
    }

    const isOtherSelected =
        responseNoteForSelectedQuestion?.title.filter((item: string) => item.startsWith('Other'))
            .length > 0

    let textAreaValue: string = ''
    if (isOtherSelected) {
        textAreaValue = responseNoteForSelectedQuestion?.title
            .find((item: string) => item.startsWith('Other') || item.startsWith('Other: '))
            .split('Other: ')[1]
    }

    let options = selectedQuestion?.question_settings?.options ?? []
    if (selectedQuestion?.question_settings?.show_other && isQuestionResponder) {
        options = [...options, 'Other']
    }

    return (
        <div className="mt-6 mb-3 flex-1">
            <p
                className={`${
                    isDarkModeEnabled ? 'text-[#FFFFFF]' : 'text-[#0C1B2C]'
                } text-[13px] mb-5`}
            >
                {t('question_responder_page.select_as_many_as_you_like')}
            </p>
            {options.map((option: string, index: number) => {
                let isCheckBoxBtnSelected: boolean = false
                if (isOtherSelected && option.startsWith('Other')) {
                    isCheckBoxBtnSelected = true
                } else {
                    isCheckBoxBtnSelected = responseNoteForSelectedQuestion?.title.includes(option)
                }
                let translatedOption = ''
                if (option === 'Other') {
                    translatedOption = t('common.other')
                } else {
                    switch (i18n.language) {
                        case 'es':
                            translatedOption =
                                (
                                    selectedQuestion as Question
                                )?.question_settings?.translations?.find((t) => t.language === 'es')
                                    ?.options[index] ?? option
                            break
                        case 'fr':
                            translatedOption =
                                (
                                    selectedQuestion as Question
                                )?.question_settings?.translations?.find((t) => t.language === 'fr')
                                    ?.options[index] ?? option
                            break
                        case 'zh':
                            translatedOption =
                                (
                                    selectedQuestion as Question
                                )?.question_settings?.translations?.find((t) => t.language === 'zh')
                                    ?.options[index] ?? option
                            break
                        case 'de':
                            translatedOption =
                                (
                                    selectedQuestion as Question
                                )?.question_settings?.translations?.find((t) => t.language === 'de')
                                    ?.options[index] ?? option
                            break
                        case 'hi':
                            translatedOption =
                                (
                                    selectedQuestion as Question
                                )?.question_settings?.translations?.find((t) => t.language === 'hi')
                                    ?.options[index] ?? option
                            break
                        case 'ht':
                            translatedOption =
                                (
                                    selectedQuestion as Question
                                )?.question_settings?.translations?.find((t) => t.language === 'ht')
                                    ?.options[index] ?? option
                            break
                        case 'it':
                            translatedOption =
                                (
                                    selectedQuestion as Question
                                )?.question_settings?.translations?.find((t) => t.language === 'it')
                                    ?.options[index] ?? option
                            break
                        case 'ja':
                            translatedOption =
                                (
                                    selectedQuestion as Question
                                )?.question_settings?.translations?.find((t) => t.language === 'ja')
                                    ?.options[index] ?? option
                            break
                        case 'ko':
                            translatedOption =
                                (
                                    selectedQuestion as Question
                                )?.question_settings?.translations?.find((t) => t.language === 'ko')
                                    ?.options[index] ?? option
                            break
                        default:
                            translatedOption = option
                    }
                }
                return (
                    <div className="flex items-center mb-2" key={index}>
                        {!isQuestionResponder && (
                            <button className="mr-2">
                                <img src="/asset/drag-pointer.svg" />
                            </button>
                        )}
                        <div
                            className={`${
                                !disabled && isQuestionResponder && 'flex-1'
                            } flex items-center p-3 border-[1px] border-[var(--gray-300,_#D0D5DD)] bg-white shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] rounded-xl w-full xl:w-[450px]`}
                            onClick={() => {
                                if (!accepted) {
                                    setQuestionResponderLanded(false)
                                    setOpenFooter(true)
                                    return
                                }
                                if (!setResponseNotes) return
                                setResponseNotes((responseNotes) => {
                                    return responseNotes.map((responseNote, index) => {
                                        if (questionIndex === index) {
                                            if (option.startsWith('Other')) {
                                                if (
                                                    responseNote.title?.filter((item: string) =>
                                                        item.startsWith('Other'),
                                                    ).length > 0
                                                ) {
                                                    return {
                                                        ...responseNote,
                                                        title: responseNote.title.filter(
                                                            (item: string) =>
                                                                !item.startsWith('Other'),
                                                        ),
                                                    }
                                                } else {
                                                    return {
                                                        ...responseNote,
                                                        title: [
                                                            ...responseNote.title.filter(
                                                                (item: string) =>
                                                                    !item.startsWith('Other'),
                                                            ),
                                                            `Other`,
                                                        ],
                                                    }
                                                }
                                            }
                                            const isOptionSelected =
                                                responseNote.title.includes(option)
                                            if (isOptionSelected) {
                                                return {
                                                    ...responseNote,
                                                    title: responseNote.title.filter(
                                                        (item: string) => item !== option,
                                                    ),
                                                }
                                            } else {
                                                return {
                                                    ...responseNote,
                                                    title: [...responseNote.title, option],
                                                }
                                            }
                                        }
                                        return responseNote
                                    })
                                })
                            }}
                        >
                            <input
                                disabled={disabled}
                                type="checkbox"
                                readOnly
                                checked={isCheckBoxBtnSelected}
                                className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            {isQuestionResponder ? (
                                <label className="ml-2 text-wrap">{translatedOption}</label>
                            ) : (
                                <TextareaAutosize
                                    disabled={isQuestionResponder}
                                    value={option}
                                    autoFocus
                                    onKeyDown={handleKeyPress}
                                    onChange={(e: any) => {
                                        setQuestions &&
                                            setQuestions(
                                                (questions as Question[]).map(
                                                    (question: Question, qIndex: number) =>
                                                        qIndex !== questionIndex
                                                            ? question
                                                            : {
                                                                  ...question,
                                                                  question_settings: {
                                                                      ...question.question_settings,
                                                                      options:
                                                                          question.question_settings?.options?.map(
                                                                              (
                                                                                  o: string,
                                                                                  idx: number,
                                                                              ) =>
                                                                                  idx === index
                                                                                      ? e.target
                                                                                            .value
                                                                                      : o,
                                                                          ),
                                                                  },
                                                              },
                                                ),
                                            )
                                    }}
                                    placeholder="Choice..."
                                    className="resize-none outline-none focus:outline-none border-none ml-2 placeholder:text-[#8E8D93] text-base w-[inherit] p-0"
                                />
                            )}
                        </div>
                        {!isQuestionResponder && (
                            <button
                                className="text-[#007AFF] text-base font-bold rounded-2xl ml-5"
                                onClick={() =>
                                    (selectedQuestion as Question)?.question_settings?.options
                                        ?.length === 1
                                        ? setQuestions &&
                                          setQuestions(
                                              (questions as Question[]).map(
                                                  (question: Question, qIndex: number) =>
                                                      qIndex !== questionIndex
                                                          ? question
                                                          : {
                                                                ...question,
                                                                question_settings: {
                                                                    ...question.question_settings,
                                                                    options: [''],
                                                                },
                                                            },
                                              ),
                                          )
                                        : setQuestions &&
                                          setQuestions(
                                              (questions as Question[]).map(
                                                  (question: Question, qIndex: number) =>
                                                      qIndex !== questionIndex
                                                          ? question
                                                          : {
                                                                ...question,
                                                                question_settings: {
                                                                    ...question.question_settings,
                                                                    options:
                                                                        question.question_settings.options?.filter(
                                                                            (
                                                                                o: string,
                                                                                idx: number,
                                                                            ) => index !== idx,
                                                                        ),
                                                                },
                                                            },
                                              ),
                                          )
                                }
                            >
                                <div className="flex flex-row gap-1.5">
                                    <img src="/asset/trash_bin.svg" alt="trash_icon" />
                                </div>
                            </button>
                        )}
                    </div>
                )
            })}
            {isOtherSelected && (
                <div
                    className={`${
                        !disabled && isQuestionResponder && 'flex-1'
                    } flex items-center p-3 border-[1px] border-[var(--gray-300,_#D0D5DD)] bg-white shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] rounded-xl w-full`}
                >
                    <textarea
                        name="default-radio"
                        value={textAreaValue}
                        className="w-full rounded-xl bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                        onChange={(e: any) => {
                            setResponseNotes &&
                                setResponseNotes((responseNotes) => {
                                    let resNotes = [...responseNotes]
                                    resNotes.splice(
                                        responseNotes.findIndex(
                                            (responseNote) =>
                                                responseNote.questionId ===
                                                (selectedQuestion as Question)?.id,
                                        ),
                                        1,
                                        {
                                            ...(responseNoteForSelectedQuestion as ResponseNote),
                                            title: [
                                                ...responseNoteForSelectedQuestion?.title.filter(
                                                    (item: string) => !item.startsWith('Other'),
                                                ),
                                                `Other: ${e.target.value}`,
                                            ],
                                        },
                                    )
                                    return resNotes
                                })
                        }}
                    />
                </div>
            )}
            {!isQuestionResponder && (
                <div className="flex flex-row gap-8 items-center mt-4">
                    <button className="flex items-center gap-2" onClick={onQuestionAdded}>
                        <img src="/asset/plus_blue_noncircle.svg" />
                        <p className="text-sm text-[#007AFF]">Add choice</p>
                    </button>
                    <Tooltip
                        content={
                            <p>
                                Add other allows the respondent to provide an answer that is not
                                included in the list of choices.
                            </p>
                        }
                    >
                        <ToggleSwitch
                            checked={selectedQuestion?.question_settings?.show_other || false}
                            label="Add other"
                            onChange={onShowOtherChange}
                        />
                    </Tooltip>
                </div>
            )}
        </div>
    )
}

export default MultipleSelectResponder
