import React from 'react'
import AudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'

interface PlayerProps {
    url: string
    autoPlay?: boolean
    [key: string]: any
}

const Player = React.forwardRef<HTMLElement, PlayerProps>(
    ({ url, autoPlay = false, ...rest }, ref: any): JSX.Element => {
        return (
            <AudioPlayer
                autoPlay={autoPlay}
                src={url}
                loop={false}
                autoPlayAfterSrcChange={false}
                style={{
                    background: 'transparent',
                    color: '#ffffff',
                }}
                ref={ref}
                {...rest}
            />
        )
    },
)

export default Player
