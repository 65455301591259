import { CookieKey } from '../enums/CookieKey'
import { getCookie } from '../utils/useCookies'
import { useEffect, useRef } from 'react'
import { io, Socket } from 'socket.io-client'

export const useWebSocket = () => {
    const socketRef = useRef<Socket | null>(null)

    useEffect(() => {
        const serverUrl =
            process.env.REACT_APP_ENV === 'production'
                ? process.env.REACT_APP_PRODUCTION_SERVER_URL
                : process.env.REACT_APP_TEST_SERVER_URL

        const newSocket = io(`${serverUrl}?jwt=${getCookie(CookieKey.JWT_TOKEN)}`)

        socketRef.current = newSocket

        return () => {
            newSocket.disconnect()
        }
    }, [])

    return socketRef.current
}
