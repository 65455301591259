import { CreateNodeioPayload } from '../types/CreateNodeioPayload'
import { GeneratePreSignedUrlRequest } from '../types/GeneratePreSignedUrlRequest'
import { GeneratePreSignedUrlResponse } from '../types/GeneratePreSignedUrlResponse'
import { MVPEditFieldType } from '../types/MVPResponse'
import { Nodeio } from '../types/Nodeio'
import ApiService from './apiService'
import { AxiosResponse } from 'axios'

export interface NodeioApi {
    getById(id: string): Promise<AxiosResponse<Nodeio>>
    create(payload: CreateNodeioPayload): Promise<AxiosResponse<Nodeio>>
    downloadAudio(id: string, downloadTrimmedAudio?: boolean): Promise<AxiosResponse<any>>
    downloadAudioStoryboard(id: string): Promise<AxiosResponse<any>>
    updateMVP(id: string, payload: MVPEditFieldType): Promise<AxiosResponse<Nodeio>>
    generatePreSignedUrl(
        body: GeneratePreSignedUrlRequest,
    ): Promise<AxiosResponse<GeneratePreSignedUrlResponse>>
}

export const NodeioApi: NodeioApi = Object.freeze({
    getById(id: string): Promise<AxiosResponse<Nodeio>> {
        return ApiService.get(`/nodeios/web-app/${id}`)
    },
    create(payload: CreateNodeioPayload): Promise<AxiosResponse<Nodeio>> {
        return ApiService.post(`/nodeios`, payload)
    },
    downloadAudio(id: string, downloadTrimmedAudio?: boolean): Promise<AxiosResponse<any>> {
        if (downloadTrimmedAudio) {
            return ApiService.download(`nodeios/${id}/download-media?download_trimmed_audio=true`)
        }
        return ApiService.download(`nodeios/${id}/download-media`)
    },
    downloadAudioStoryboard(id: string): Promise<AxiosResponse<any>> {
        return ApiService.download(`nodeios/${id}/download-media-storyboard`)
    },
    updateMVP(id: string, payload: MVPEditFieldType): Promise<AxiosResponse<Nodeio>> {
        return ApiService.patch(`/nodeios/edit-mvp/${id}`, payload)
    },
    generatePreSignedUrl(
        body: GeneratePreSignedUrlRequest,
    ): Promise<AxiosResponse<GeneratePreSignedUrlResponse>> {
        return ApiService.post(`/uploads/pre-signed-url`, body)
    },
})
