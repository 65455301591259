export const upperCaseLeading = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export const removeQuoteString = (str: any): any => {
    let result = str
    if (
        str != null &&
        str.length >= 2 &&
        str.charAt(0) == '"' &&
        str.charAt(str.length - 1) == '"'
    ) {
        result = str.substring(1, str.length - 1)
    }

    return result
}
