import React, { useState, useEffect, useRef } from 'react'

interface TextWithViewMoreProps {
    text: string
    title: string
}

const TextWithViewMore = ({ text, title }: TextWithViewMoreProps) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const [visibleText, setVisibleText] = useState('')
    const containerRef = useRef<any>(null)

    const getCharsPerLine = (width: number, font: number) => {
        const canvas = document.createElement('canvas')
        const context: any = canvas.getContext('2d')
        context.font = font
        const avgCharWidth = context.measureText('abcdefghijklmnopqrstuvwxyz').width / 26
        return Math.floor(width / avgCharWidth)
    }

    useEffect(() => {
        const containerWidth = containerRef.current.clientWidth
        const font: any = window.getComputedStyle(containerRef.current).font
        const charsPerLine = getCharsPerLine(containerWidth, font)
        setVisibleText(text.slice(0, charsPerLine * 2))
    }, [text])

    const toggleExpand = () => {
        setIsExpanded(!isExpanded)
    }

    return (
        <div
            ref={containerRef}
            style={{
                width: '100%',
                whiteSpace: 'pre-wrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }}
        >
            <p>
                {title.length > 0 && <span className="font-bold">{title} | </span>}
                {isExpanded ? text : visibleText + '...'}
                <span
                    style={{ color: '#007AFF', cursor: 'pointer', marginLeft: 20 }}
                    onClick={toggleExpand}
                >
                    {isExpanded ? ' View Less' : ' View More'}
                </span>
            </p>
        </div>
    )
}

export default TextWithViewMore
