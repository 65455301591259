import { CampaignData } from '../types/Campaign'
import moment from 'moment'
import { useEffect, useState } from 'react'

interface FolderCardProps {
    folderData: CampaignData
}

const FolderCard = ({ folderData }: FolderCardProps): JSX.Element => {
    const [isCopied, setIsCopied] = useState<boolean>(false)
    useEffect(() => {
        if (isCopied) {
            const timeout = setTimeout(() => {
                setIsCopied(false)
            }, 2000)
            return () => clearTimeout(timeout)
        }
    }, [isCopied])

    // eslint-disable-next-line no-console
    //console.log('Folder Data: ', folderData)

    return (
        <div className="p-2 rounded-lg flex flex-row justify-between items-center hover:bg-[#D9D9D930] gap-10">
            <div className="flex w-[80%] justify-start">
                <p className="text-[#001F40] text-base text-black ellipsis-1 break-all">
                    {folderData?.title}
                </p>
            </div>
            <div className="flex flex-row w-[20%] gap-10 justify-end">
                <p className="text-[#8092A5] text-sm">
                    {moment(new Date()).diff(folderData.created_at, 'hours') > 72
                        ? moment(folderData.created_at).format('LL')
                        : moment(folderData.created_at).fromNow()}
                </p>
                {/* <p className="text-[#8092A5] text-sm">32 min</p> */}
            </div>
        </div>
    )
}

export default FolderCard
