import React from 'react'
import { useTranslation } from 'react-i18next'

interface NextQuestionButtonProps {
    onClick: () => void
    disabled?: boolean
}

const NextQuestionButton = ({ onClick, disabled }: NextQuestionButtonProps): JSX.Element => {
    const { t } = useTranslation()

    return (
        <button
            onClick={onClick}
            className="bg-[#ffffff] rounded-[10px] flex justify-center items-center w-[90px] sm:w-[100px] px-[4px] sm:px-[6px] py-[4px] gap-3 disabled:opacity-60"
            disabled={disabled}
        >
            <p className="text-[#001F40] text-sm sm:text-base leading-5 font-lato font-bold">
                {t('common.next')}
            </p>
            <svg
                width="14"
                height="24"
                viewBox="0 0 14 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    opacity="0.8"
                    d="M13.0607 13.0607C13.6464 12.4749 13.6464 11.5251 13.0607 10.9393L3.51472 1.3934C2.92893 0.807611 1.97919 0.807611 1.3934 1.3934C0.807611 1.97919 0.807611 2.92893 1.3934 3.51472L9.87868 12L1.3934 20.4853C0.807611 21.0711 0.807611 22.0208 1.3934 22.6066C1.97919 23.1924 2.92893 23.1924 3.51472 22.6066L13.0607 13.0607ZM11 13.5H12V10.5H11V13.5Z"
                    fill="#001F40"
                />
            </svg>
        </button>
    )
}

export default NextQuestionButton
